let cookieCampaign;
let m_screening = {};
let m_screen = {};
let currentPage;
let olsOrCcQuestion;
let siteSelectionLocations = [];
let esignInitiated = false;
let debugQuestionContent = {};
let esignResponse;
let chartfillResponse;
let getNextQuestionEventFired = false;
let userConsentedEmailRecoverProgress = true;
let userEmail;
let userPhone;
let recoverProgressEmailSent = false;
let displayExitWarning = true;
let answerCount = 0;
let disclaimerText;
let selectedSite;
let translationMap; //this gets set after getFirstQuestion and is referred to on every question load.
let userTimeout;
let synexusFlag = false;
let ppdFlag = false;
let theme = null;
let category = null;
const mobileBreakPoint = 576; // This is based off the sass variable in themes/blue.scss, both should always be equal
let localAnswerMapObjGlobal = {};
const md = new MobileDetect(navigator.userAgent);
let osanoInterval;
let osanoIntervalExpire;
let deviceDetails;
let curLocale = 'en_US';
let curUserZip;
let questionsTrack = []
let REACHED_FIRST_QUESTION = 'REACHED_FIRST_QUESTION';
let LEFT_FIRST_QUESTION = 'LEFT_FIRST_QUESTION';
let REACHED_SECOND_QUESTION = 'REACHED_SECOND_QUESTION';
let LEFT_SECOND_QUESTION = 'LEFT_SECOND_QUESTION';
let REACHED_SITESEL_QUESTION = 'REACHED_SITESEL_QUESTION';
let PICKED_SITE_ON_SITESEL_QUESTION = 'PICKED_SITE_ON_SITESEL_QUESTION';
let SITE_SELECTION = 'SITE_SELECTION';
let PICKED_APPT_ON_SCHEDULING_QUESTION = 'PICKED_APPT_ON_SCHEDULING_QUESTION';
let REACHED_SCHEDULING_QUESTION = 'REACHED_SCHEDULING_QUESTION';
const trackingPixels = []
let osanoScriptUrlLocal = `${osanoScriptUrl}?variant=one&language=${curLocale.split('_')[0]}`
let screenerType = ''
let signUpAllowed = false
let currentPatientPortalUser = JSON.parse(sessionStorage.getItem("currentPatientPortalUser"))
let currentPatientPortalPIIData = JSON.parse(sessionStorage.getItem("currentPatientPortalPIIData"))
let currentPatientPortalGenericData = JSON.parse(sessionStorage.getItem("currentPatientPortalGenericData"))
let patientProfileId = sessionStorage.getItem("patientProfileId")
let isCurrentQuestionPII = false
let currentPatientId = ''
let isParentCoreModuleFound = null
let hideBackButtonForCntrl = false
let nextButtonClicked = true
let backButtonClicked = 0
let glossaryModalTooltipInterval = null

let ssmsPhoneNumber = null
let ssmsDisplay = null
let ssmsDefaultPhoneNumber = ""
let ssmsHidePhoneNumber = null

let screeningGLatitude;
let screeningGLongitude;

const ppPIIMapping = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
  DAY_PHONE: "dayPhone",
  ADDRESS1: "address1",
  ADDRESS2: "address2",
  COUNTRY: "country",
  ZIP: "zip",
  CITY: "city",
  STATE: "state",
  DOB: "dob",
  GENDER: "gender",
  WEIGHT: "weight",
  HEIGHT: "height"
}
// drop off
// const dropOffLocales = ['en_GB', 'en_US', 'de_AT', 'fr_BG', 'bg_BG', 'hr_HR', 'cs_CZ', 'da_DK', 'fi_FI', 'fr_FR', 'de_DE', 'el_GR', 'hu_HU', 'en_IE', 'it_IT', 'lv_LV', 'lt_LT', 'lb_LU',
//  'mt_MT', 'nl_NL', 'pl_PL', 'pt_PT', 'ro_RO', 'sk_SK', 'sl_SI', 'es_ES', 'sv_SE', 'fr_CA', 'en_CA', 'nl_NL', 'fr_BE', 'nl_BE', 'es_US', 'pt_BR', 'es_CL', 'es_MX']; // 'en_BG', 'en _CY', 'et_EE',]; theres 3 dont have google translations and none provided by aes

const dropOffLocales = ['en_US', 'es_US', 'en_CA', 'fr_CA'];

const synexusCountries = ['AT', 'BE', 'BG', 'CZ', 'DK', 'FR', 'DE', 'HU', 'IL', 'IT', 'JP', 'LV',
  'LT', 'NL', 'PL', 'RO', 'SK', 'ES', 'SE', 'CH', 'ZA', 'GB', 'UA', 'FI', 'GR', 'IE', 'PT',
  'HR', 'HK', 'EE'];


let isEmailQuestion = false
let isSignInAllowed = false
const getUserData = (userEmail) => {
  const endpoint = apiUrl + "/patient/isPatientRegistered/";
  const token = sessionStorage.getItem("token");
  return new Promise((resolve, reject) => {
    if (currentPatientPortalUser) {
      resolve(currentPatientPortalUser)
    }
    $.ajax({
      url: endpoint,
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "POST",
      data: { emailId: userEmail },
      timeout: 200000,
      success: (data) => {
        resolve(data.apiResponse.patientProfile)
      },
      error: (error) => {
        reject(error)
      }
    })
  })
}
const getUserGenericData = (patientProfileId) => {
  const endpoint = apiUrl + "/patient/getScreenerAllGenericHealthQuestionAnswers/";
  const token = sessionStorage.getItem("token");
  return $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    data: { patientProfileId },
    timeout: 200000,
  })
}
const updateUserGenericDataApi = ({ data }) => {
  const endpoint = apiUrl + "/patient/updateScreenerGenericHealthQuestionAnswers/";
  const token = sessionStorage.getItem("token");
  return $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    data: { genericHealthQuestionAnswers: data },
    timeout: 200000,
  })
}
const updateGenericQuestion = (questionData) => {
  const { m_subs, ...restQuestion } = m_screen.m_question
  const allQuesArray = m_subs && m_subs.length ? m_subs : [restQuestion];
  for (let x = 0; x < questionData.questions.length; x++) {
    const questionToUpdate = questionData.questions[x];
    const questionToUpdateModule = allQuesArray.find(mapping => mapping.m_sUniqueCode == questionToUpdate.questionUniqueId)
    if (questionToUpdateModule) {
      const currentPatientPortalUser = JSON.parse(sessionStorage.getItem('currentPatientPortalUser'))
      const PiiBasises = ["ZIP", "DOB", "GENDER"]
      if (PiiBasises.find(piiBasis => piiBasis === questionToUpdateModule.m_basis)) {
        let updatePartialProfileDataPII = {}
        updatePartialProfileDataPII.patientProfileId = patientProfileId
        const tt = ppPIIMapping[questionToUpdateModule.m_basis]
        // if (questionToUpdateModule.m_basis === 'HEIGHT') {
        //   updatePartialProfileDataPII[tt] = questionToUpdate.answers[0] * 12 + parseInt(questionToUpdate.answers[1])
        // } else {
        updatePartialProfileDataPII[tt] = questionToUpdate.answers[0]
        // }
        updateUserPIIDataAPI(updatePartialProfileDataPII)
      }

      if (questionToUpdate && currentPatientPortalUser && (restQuestion.m_sModuleName === "Core")) {
        if (questionToUpdateModule.m_answerType === 'INFO') {
          const updateData = {
            patientProfileId,
            masterPatientId: currentPatientPortalUser.masterPatientId,
            questionCode: questionToUpdateModule.m_sCode,
            questionTxt: questionToUpdateModule.m_sText,
            answerCd: null,
            qstnrGroupAttributeCd: questionToUpdateModule.m_sGroupAttribute,
            qstnrElementUniqCd: m_screen.m_sUniqueQuestionCode,
          }
          updateUserGenericDataApi({ data: [updateData] })
        } else {

          if (questionToUpdateModule.m_basis === 'HEIGHT') {
            console.log('###########height', questionToUpdate.m_bMetric)
            const heightAnswer = questionToUpdate.m_bMetric ?
              parseInt(questionToUpdate.answers[0] / 2.54)
              : questionToUpdate.answers[0] * 12 + parseInt(questionToUpdate.answers[1])
            const updateData = {
              patientProfileId,
              masterPatientId: currentPatientPortalUser.masterPatientId,
              questionCode: questionToUpdateModule.m_sCode,
              questionTxt: questionToUpdateModule.m_sText,
              answerCd: heightAnswer,
              qstnrGroupAttributeCd: questionToUpdateModule.m_sGroupAttribute,
              qstnrElementUniqCd: m_screen.m_sUniqueQuestionCode,
              answerText: heightAnswer,
              patientChoiceTxt: heightAnswer,
            }
            updateUserGenericDataApi({ data: [updateData] })
          } else {
            if (questionToUpdateModule.m_basis === 'WEIGHT' && questionToUpdate.m_bMetric) {
              console.log('###########weight', questionToUpdate.m_bMetric)
              const weightAnswer = parseInt(questionToUpdate.answers[0] * 2.205)
              const updateData = {
                patientProfileId,
                masterPatientId: currentPatientPortalUser.masterPatientId,
                questionCode: questionToUpdateModule.m_sCode,
                questionTxt: questionToUpdateModule.m_sText,
                answerCd: weightAnswer,
                qstnrGroupAttributeCd: questionToUpdateModule.m_sGroupAttribute,
                qstnrElementUniqCd: m_screen.m_sUniqueQuestionCode,
                answerText: weightAnswer,
                patientChoiceTxt: weightAnswer,
              }
              updateUserGenericDataApi({ data: [updateData] })
            } else {
              let updateDataArr = []
              for (let x = 0; x < questionToUpdate.answers.length; x++) {
                const selctedAnswer = questionToUpdate.answers[x];
                let selectedChoice = null;
                if (questionToUpdateModule.m_choiceSource) {
                  selectedChoice = questionToUpdateModule.m_choiceSource.m_choices
                    .find((choice) => choice.m_sUniqueChoiceCode === selctedAnswer).m_sRawText
                }
                const updateData = {
                  patientProfileId,
                  masterPatientId: currentPatientPortalUser.masterPatientId,
                  questionCode: questionToUpdateModule.m_sCode,
                  questionTxt: questionToUpdateModule.m_sText,
                  answerCd: selctedAnswer || null,
                  qstnrGroupAttributeCd: questionToUpdateModule.m_sGroupAttribute,
                  qstnrElementUniqCd: m_screen.m_sUniqueQuestionCode,
                  answerText: selectedChoice || selctedAnswer,
                  patientChoiceTxt: selectedChoice || selctedAnswer,
                }
                updateDataArr.push(updateData)
              }
              updateUserGenericDataApi({ data: updateDataArr })
            }
          }
        }
      }
    }
  }
}



const getUserPIIData = (patientProfileId) => {
  const endpoint = apiUrl + "/patient/getPatientProfile/";
  const token = sessionStorage.getItem("token");
  return $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    data: { patientProfileId },
    timeout: 200000,
  })
}
const updateUserPIIData = (stringJson) => {
  if (!patientProfileId) { return }
  let questionMapping = {}
  Object.values(m_screen.m_basis_questions).forEach(mappingQues => {
    questionMapping[mappingQues.m_basis] = mappingQues.m_sUniqueCode
  })
  let updateProfileDataPII = {}
  stringJson.questions.forEach(ques => {
    if (ques.answers && ques.answers.length) {
      const mBasis = Object.keys(questionMapping)
        .find(key => questionMapping[key] === ques.questionUniqueId);
      updateProfileDataPII[ppPIIMapping[mBasis]] = ques.answers[0]
    }
  })
  updateProfileDataPII.patientProfileId = patientProfileId
  updateUserPIIDataAPI(updateProfileDataPII)
  sessionStorage.removeItem('currentPatientPortalPIIData')
  getUserDataGenericPII()
}
const updateUserPIIDataAPI = (patientProfileData) => {
  const endpoint = apiUrl + "/patient/updatePatientProfile/";
  const token = sessionStorage.getItem("token");
  return $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    data: patientProfileData,
    timeout: 200000,
  })
}

const getUserDataGenericPII = (ppId) => {
  if (!currentPatientPortalPIIData) {
    getUserPIIData(ppId).then(piiResponse => {
      currentPatientPortalPIIData = piiResponse.apiResponse.patientProfile
      sessionStorage.setItem('currentPatientPortalPIIData', JSON.stringify(currentPatientPortalPIIData))
    })
    autoPopulateIndependentPIIQuestions(m_screen.m_question)
  }
  if (!currentPatientPortalGenericData) {
    getUserGenericData(ppId).then(genericAnswers => {
      currentPatientPortalGenericData = genericAnswers.apiResponse.genericHealthQuestionAnswers
      sessionStorage.setItem('currentPatientPortalGenericData', JSON.stringify(currentPatientPortalGenericData))
    })
  }
}
let editEmail = false
const updateEmail = () => {
  const updatedEmailValue = $('#editEmailInput').val()
  const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  const isEmailValid = emailPattern.test(updatedEmailValue)
  if (isEmailValid) {
    sessionStorage.setItem('userEmail', updatedEmailValue)
    toggleEmail(false)
  }
}
const toggleEmail = (value) => {
  editEmail = value
  renderAuthBanner()
}


const autoPopulatePatientPortalGenericData = (questionData) => {
  const genericAnswers = JSON.parse(sessionStorage.getItem('currentPatientPortalGenericData'))
  if (genericAnswers && genericAnswers.length) {

    let selectedGenericAnswers = null;
    if (questionData.m_basis === "HEIGHT") {
      selectedGenericAnswers = genericAnswers
        .filter(genericAnswer => genericAnswer.questionCode === questionData.m_sCode)
        .map(genericAnswer => +genericAnswer.patientChoiceTxt)
      if (!selectedGenericAnswers) { return }
      const heightInFeet = Math.floor(selectedGenericAnswers / 12)
      const heightinInches = selectedGenericAnswers - (heightInFeet * 12);
      const autoPopulationPayload = {
        questionUniqueId: questionData.m_sUniqueCode,
        answers: [heightInFeet, heightinInches],
        m_bMetric: String(questionData.m_bMetric)
      }
      return autoPopulateAnswers([autoPopulationPayload])
    }
    if (questionData.m_basis === "WEIGHT") {
      selectedGenericAnswers = genericAnswers
        .filter(genericAnswer => genericAnswer.questionCode === questionData.m_sCode)
        .map(genericAnswer => +genericAnswer.patientChoiceTxt)
      if (!selectedGenericAnswers) { return }
      const autoPopulationPayload = {
        questionUniqueId: questionData.m_sUniqueCode,
        answers: selectedGenericAnswers,
        m_bMetric: String(questionData.m_bMetric)
      }
      return autoPopulateAnswers([autoPopulationPayload])
    }
    selectedGenericAnswers = genericAnswers
      .filter(genericAnswer => genericAnswer.questionCode === questionData.m_sCode)
      .map(genericAnswer => genericAnswer.answerCd)
    if (selectedGenericAnswers) {
      const autoPopulationPayload = {
        questionUniqueId: questionData.m_sUniqueCode,
        answers: selectedGenericAnswers
      }
      autoPopulateAnswers([autoPopulationPayload])
    }
  }
}
const autoPopulatePatientPortalPIIData = (currentJsonPayload) => {
  const questions = currentJsonPayload.m_screen.m_question.m_subs
  const formattedAnswersppPII = questions.map(que => {
    const isPPAnswerAvailable = currentPatientPortalPIIData[ppPIIMapping[que.m_basis]] ? [currentPatientPortalPIIData[ppPIIMapping[que.m_basis]]] : null
    let formattedAnswer = {
      questionUniqueId: que.m_sUniqueCode,
    }
    if (isPPAnswerAvailable) {
      formattedAnswer.answers = isPPAnswerAvailable
    }
    return formattedAnswer
  })
  autoPopulateAnswers(formattedAnswersppPII)
}
const openPatientPortal = () => window.open(patientPortalUrl, '_blank');
const authConditions = () => {

  if (m_screening) {
    const isUS = curLocale.split('_')[1] === 'US'
    if (isUS && m_screening.m_type === "OLS") {
      return true
    }
    return false
  }
  return false
}

const renderAuthBanner = () => {
  let userEmailAuth = sessionStorage.getItem('userEmail') || userEmail
  const account = myMSALObj.getAllAccounts()[0]
  if (account) {
    userEmailAuth = account.username
    sessionStorage.setItem('userEmail', account.username)
  } else if (sessionStorage.getItem('userType') !== "cc") {
    $('#profileDisplay').html(`
      <a id="navbarHome" class="text-center js-acurianLogoLink translation" target="_blank"
      data-translate-link="link.header" data-translate-link-default="http://www.acurianhealth.com">
      <img id="ah-logo-screener" src="/images/company/ah-logo-screener.png" alt="AES Solutions" />
    </a>`)
  }
  if ((!authConditions() || !userEmailAuth)) {
    if (signUpAllowed) {
      $('#authBanner').addClass('registerBanner').html(`
          <p class="sign-up-title">This question is not supported on this locale. Please click Next.</p>
        `)
      return
    }
    return
  }
  if (signUpAllowed || isSignInAllowed) {
    // Is User Registered check API call
    getUserData(userEmailAuth).then(user => {
      if (user) {
        currentPatientPortalUser = user
        patientProfileId = user.patientProfileId
        if (account) {
          getUserDataGenericPII(patientProfileId)
          sessionStorage.setItem("currentPatientPortalUser", JSON.stringify(user))
          sessionStorage.setItem("patientProfileId", user.patientProfileId)
        }
      }
      return reRenderChecks()
    })
  }
  $('#authBanner').removeClass('authBanner').html(``)
}

const reRenderChecks = () => {
  const user = currentPatientPortalUser
  const account = myMSALObj.getAllAccounts()[0]
  let userEmailAuth = sessionStorage.getItem('userEmail') || userEmail


  // i.e If a registered
  if (user && !account && (isSignInAllowed)) {
    $('#authBanner').addClass('authBanner').html(`
    Based on the email you have provided, you may have an existing My AcurianHealth Portal account. <span id="SignIn" class="inline-button" onclick="signIn()">
    Sign in
    </span>  now and save time! We'll auto-fill the answers you've previously provided. To continue as guest, submit answer below and click next.
    `
    )
    return;
  }
  // if (user && account && (isSignInAllowed)) {
  //   $('#authBanner').addClass('authBanner').html(`
  // Welcome back ${user.firstName}, please continue the process to check if you are eligible for any clinical studies near you`)
  //   return;
  // }
  if (user && account) {
    $('#authBanner').removeClass('authBanner').html('')
    $('#profileDisplay').html(`
            <a id="navbarHome" class="text-center js-acurianLogoLink translation" target="_blank"
              data-translate-link="link.header" data-translate-link-default="http://www.acurianhealth.com">
              <img id="ah-logo-screener" src="/images/company/ah-logo-screener.png" alt="AES Solutions" />
            </a>
            <ul class="navbar-nav" id="user-dropdown">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src="/images/user-icon.png" alt="" width="40" height="40" class="rounded-circle" />
              </a>
              <div id="ppUser" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <p class="dropdown-item">${account.username}</p>
                <div class="dropdown-divider"></div>
                <p id="SignOut" class="dropdown-item" type="button" onclick="signOut()">Sign Out</p>
              </div>
            </li>
            </ul>
          `)
    if (user && account && signUpAllowed) {
      $('#authBanner').addClass('authBanner').html(`
      <img src="/images/pp-logo.png" class="pp-logo" />
      Click here
      <span id="SignIn" class="inline-button" onclick="openPatientPortal()">
        My AcurianHealth Portal
      </span>
      to review your upcoming appointment and health information.`)
    }

    if (account.username !== userEmail) {
      userEmail = account.username
      sessionStorage.setItem('userEmail', account.username)
    }
    return;
  }
  if (!user && !(account && account.username) && signUpAllowed) {
    $('#authBanner').addClass('registerBanner').html(`
      <img src="/images/pp-logo.png" class="pp-logo" />
      <p class="sign-up-title">Enjoy secure access to your pre-trial information.</p>
      <br />
      <ul class="registerBannerUl">
        <li class="registerBannerli">View upcoming appointments </li>
        <li class="registerBannerli">Manage your health information</li>
        <li class="registerBannerli">Learn more about clinical research</li>
      </ul>
      <div class="signup-button" onclick="signUp()">Create Account</div>
      <br />
      <p>Already have an account.
      <span id="SignIn" class="inline-button fz-18" onclick="signIn()">
        Sign In
      </span>
      </p>
      `)
    return;
  }
  if (user && !(account && account.username) && signUpAllowed) {

    $('#authBanner').removeClass('authBanner').addClass('registerBanner').html(`
    <img src="/images/pp-logo.png" class="pp-logo" />
    <p class="sign-up-title">Enjoy secure access to your pre-trial information.</p>
    <ul class="registerBannerUl">
        <li class="registerBannerli">View upcoming appointments </li>
        <li class="registerBannerli">Manage your health information</li>
        <li class="registerBannerli">Learn more about clinical research</li>
      </ul>
      <br />
      <br />
      <div class="signup-button" onclick="signIn()">SIGN IN</div>
        `)
    return;
  }
}

// site selection
let ssRadius = null;
let ssRadiusUnit = 'km';
let ssSelectedPlaceResult;
let aesSitesSearchAPIToken = 'YWN1cmlhbjpBY3VyaWFuMQ=='; // dev, may need to be setup for envs
let ssRadiusDropdownDataForOptions = [5, 10, 25, 50]; // the miles value for each dropdown option
let lastFetchedSiteSelectionData;
let selectedSiteUniqueCode;
let selectedSiteFacilityCD;
let selectedSiteFacilityGroupId;
let searchedSitesPayload; // used for rebuilding if the user comes back to this question, build these sites if available
let siteSelectionQuestionCode;
let mapRouteOrigin;
let lockNoSiteConvenient = false;
let currentSites_m_Choices // sent when user has chosen a new site that was returned from a search not the original list
let shouldShowDebugContent = false;

// The following hostnames will bypass checkForScheduledMaintenance
const bypassCheckMaintenanceHostnames = [
  'https://screener.globalaes-aws.systems', // Prod
  // 'https://dev-screener-fe.np.globalaes-aws.systems', // Dev
  // 'https://pp-screener-fe.np.globalaes-aws.systems', // PreProd/QA
  // 'https://uat-screener-fe.np.globalaes-aws.systems', // UAT/Staging
  // 'http://localhost:5000' // Local Development
]

// this deifnitely needs to change and be sent by aes
const RSVStudies = ['GSKXXXRSV006', 'JANIQVRSV300', 'MODPPDRSV301'];

if (!String.prototype.includes) {
  String.prototype.includes = function () {
    'use strict';
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}

// code to run when closing the tab
window.onbeforeunload = onCloseTab;
function onCloseTab() {
  localStorage.removeItem('previousRevisionPID');
}

// generate random image at top of app
function returnRandomImage() {
  const randNumber = Math.floor(Math.random() * (synexusFlag ? 3 : 4) + (synexusFlag ? 2 : 1));
  const selector = $(".question-header-illustration");
  selector.attr("src", "./../images/illustrations/" + randNumber + ".svg");
}

function cardForeground(card) {
  $(".card").css({ "z-index": 11 });
  card.css({ "z-index": 12 });
}

$(document).ready(function () {
  // show debug by default on envs other than prod then check if it has show debug flag
  let curURL = window.location.href;

  if (ENV !== "production") {
    shouldShowDebugContent = true;

    if ((curURL && curURL.includes("show_debug=0"))) {
      shouldShowDebugContent = false;
    }
  }
  else {
    shouldShowDebugContent = false;

    if ((curURL && curURL.includes("show_debug=1"))) {
      shouldShowDebugContent = true;
    }
  }

  //VISUAL/ELEMENTS SETUP

  if (sessionStorage["userType"] === "cc") {
    $(".call-center-title").removeClass("d-none");
    $(".main-page-container").addClass("container-cc-screener");
    sessionStorage.removeItem("isCCEmailCheckBoxChecked")
  } else {
    $(document.body).removeClass("call-center-font-size");
  }

  //Disable bottom bar buttons on mobile
  disableBottomBarButtons(true);

  //Hide container to hide buttons and other things
  $("#questions-form").hide();

  //Initialize custom tooltip for glossary
  $("body").tooltip({
    selector: ".glossary"
  });

  bottomBarIphoneXFix();
  checkForScheduledMaintenance();
});

function checkForScheduledMaintenance() {

  // Allow specific hostnames through without checking maintenance
  if (bypassCheckMaintenanceHostnames && bypassCheckMaintenanceHostnames.includes(window.location.origin)) {
    console.log('BYPASSING Check Maintenance');
    removeUserError();
    continueDocumentReady();
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const phoneNumber = urlParams.get("phoneNumber");

    $.ajax({
      url: apiUrl + '/checkScheduledMaintenance',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      method: "GET",
      data: { phoneNumber },
      success: function (data) {
        if (data.maintenance == false) {
          continueDocumentReady();
        }
        else {
          if (data.locale) {
            curLocale = data.locale;
          }
          handleUserErrors(ErrorTypes.MAINTENANCE);
        }
      },
      error: (error) => {
        handleUserErrors(ErrorTypes.MAINTENANCE);
      }
    });
  }

}

function continueDocumentReady() {
  //LOAD CONTENT

  if (sessionStorage.getItem("userType") === "cc") {
    if (
      sessionStorage.getItem("TID") &&
      sessionStorage.getItem("callId") &&
      (sessionStorage["previousUrl"] === cookieCampaign)) {
      //refreshed the page, continue current session.
      fetchPayload(sessionStorage["TID"], sessionStorage["callId"]).then(() => {
        sessionStorage.removeItem('callId');
        sessionStorage.removeItem('search');
      })
    } else {
      if (sessionStorage.getItem("callId")) {
        endCall(sessionStorage.getItem("callId"));
      }
      getFirstQuestion();
    }
  } else {
    //Get phone number from stored cookie
    cookieCampaign = window.getCookie("campaign");
    if (!cookieCampaign || cookieCampaign.length < 1) {
      cookieCampaign = "?campaign=8883519685";
    }

    //Check if url has got parameters to recover progress
    let urlTid = $.urlParamFromString(sessionStorage["originalURL"], "tid");
    let urlToken = $.urlParamFromString(sessionStorage["originalURL"], "token");
    let urlCallId = $.urlParamFromString(sessionStorage["originalURL"], "callId");

    //Start user session and questionnaire
    sessionStorage.setItem("userType", "guest"); //Force to always guest for now, accountType is needed for all following cases
    if (urlTid && urlToken && urlCallId) {
      //If data from url, save it in the session to be used.
      sessionStorage.setItem("token", urlToken);
      sessionStorage.setItem("TID", urlTid);
      fetchPayload(urlTid, urlCallId);
    } else if (
      sessionStorage.getItem("TID") &&
      sessionStorage.getItem("callId") &&
      (sessionStorage["previousUrl"] === cookieCampaign)
    ) {
      //refreshed the page, continue current session.
      fetchPayload(sessionStorage["TID"], sessionStorage["callId"]).then(() => {
        sessionStorage.removeItem('callId');
        sessionStorage.removeItem('search');
      })
    } else if (sessionStorage.getItem("TID") && sessionStorage.getItem("token")) {
      if (sessionStorage.getItem("callId")) {
        endCall(sessionStorage.getItem("callId"));
      }
      //If user already has session, get first question using that session
      getFirstQuestion();
    } else {
      //If no session data, create new session and call getFirstQuestion after
      generateGuestAuth();
    }
  }

  $(".card").on("click", function () {
    cardForeground($(this));
  });

  $(".card.draggable").draggable({
    handle: ".card-header",
    cancel: ".card-header .btn",
    start: function (event, ui) {
      cardForeground($(this));
    }
  });

  $(".card.resizable").resizable({
    start: function (event, ui) {
      cardForeground($(this));
    }
  });

  $("#question-debug-link").on("click", function (e) {
    e.preventDefault();
    showDebugContent("questionHistory");
  });

  $("#info-debug-link").on("click", function (e) {
    e.preventDefault();
    showDebugContent("studyInfo");
  });

  $(".btnCloseDebugContentContainer").on("click", function (e) {
    e.preventDefault();
    let thisBtn = $(this);
    collapseDebugContent(thisBtn);
  });

  $("#debugBody, #studyInfo, #questionHistory").on("show.bs.collapse", function () {
    const thisCard = $(this);
    const restoreHeight = sessionStorage.getItem(thisCard.attr("id") + "-height") || "200px";
    thisCard.css({ height: restoreHeight });
    $(this)
      .closest(".card")
      .find(".toggleCollapse")
      .toggleClass("fa-rotate-180");
  });

  $("#debugBody, #studyInfo, #questionHistory").on("hide.bs.collapse", function () {
    let thisCard = $(this);
    sessionStorage.setItem(thisCard.attr("id") + "-height", thisCard.outerHeight() + "px");
    $(this)
      .closest(".card")
      .find(".toggleCollapse")
      .toggleClass("fa-rotate-180");
    thisCard.css({ height: "auto" });
  });

  $(document).on('click', '.osano-cm-window__widget.osano-cm-widget, .osano-cm-dialog__button', function () {
    $('a.osano-cm-powered-by__link').attr('target', '_blank');
    $('#osano-cm-drawer-toggle--category_OPT_OUT').closest('.osano-cm-drawer-item').hide()
  });
}

// left and right scrolls for the site selection
$(document).on('click', '.scnb-left', () => {
  const cardsContainer = document.querySelector('#siteCardsContainer');
  cardsContainer.scroll({ left: cardsContainer.scrollLeft - document.querySelector('#siteCardsContainer .location-card').offsetWidth, behaviour: 'smooth' })
});
$(document).on('click', '.scnb-right', () => {
  const cardsContainer = document.querySelector('#siteCardsContainer');
  cardsContainer.scroll({ left: cardsContainer.scrollLeft + document.querySelector('#siteCardsContainer .location-card').offsetWidth, behaviour: 'smooth' })
});

// no site convenient checkbox
$(document).on('click', '#noneOption', () => {
  noSiteConvenientClicked();
});

// when clicking a site card
$(document).on('click', '.choiceSelectionContainer', (e) => {
  siteCardSelected(e);
});

// ss clicking google search input
// needs to keep result if box just clicked or highlighted, but if the user starts typing we need to clear the selected result otherwise they can type whatever they want
// but its still searching for the previous result...until they click a suggestion
$(document).on('keyup', '#pac-input', (e) => {
  if ($('#pac-input').val().length <= 1) {
    ssSelectedPlaceResult = null;
  }
  if (e.keyCode == 13) {
    e.preventDefault();
    return false;
  }
});

// prevent enter on google search box clicking next
$(document).on('keydown', '#pac-input', function (e) {
  if (e.keyCode == 13) {
    e.preventDefault();
    return false;
  }
});

// ss help

// show help
$(document).on('click', '.ss-helpBtn', () => {
  showSSHelp();
});

// close help
$(document).on('click', '.ss-closeHelpBtn', () => {
  hideSSHelp();
});

// aes site search and reset
$(document).on('click', '.sites-toolbar--search-btn', () => {
  callAESSiteSearchAPI();
});

$(document).on('click', '.sites-toolbar--reset-btn', () => {
  resetSiteSelection();
});


function noSiteConvenientClicked() {
  const selected = $('#noneOption').hasClass('selected');

  if (selected) {
    deselectNoSitesConvenient();
  }
  else {
    selectNoSitesConvenient();

    // deselect all site cards
    $('.choiceSelectionContainer').removeClass('selected');
    $('.choiceSelectionContainer .js-location-card--input').val('').attr('checked', false).attr('data-ignore-answer', true); // create json payload looks for this when submitting answers - only want to send the one thats selected
  }
}

function selectNoSitesConvenient() {
  $('#noneOption').addClass('selected');
  $('#noneOption input').attr('checked', true);
  $('#noneOption input').val(lockNoSiteConvenient ? 'NoSites' : 'None');
  $('#noneOption input').removeAttr('data-ignore-answer'); // createJSONPayload() needs to read this if ticked

  selectedSiteUniqueCode = null;
  selectedSiteFacilityCD = null;
  selectedSiteFacilityGroupId = null;
}

function deselectNoSitesConvenient(selectFirstSite = true) {
  if (!lockNoSiteConvenient) {
    $('#noneOption').removeClass('selected');
    $('#noneOption input').attr('checked', false);
    $('#noneOption input').val('None');
    $('#noneOption input').attr('data-ignore-answer', true);// createJSONPayload() needs to ignore this if NOT ticked

    // select first card
    if (selectFirstSite) {
      let firstSite = document.querySelector('.choiceSelectionContainer');
      if (firstSite) {
        firstSite.click();
      }
    }
  }
}

function siteCardSelected(e) {
  let element;

  // get the correct element if a child ele was clicked
  if ($($(e)[0].target).hasClass('choiceSelectionContainer')) {
    element = e.target;
  }
  else {
    element = $($(e)[0].target).closest('.choiceSelectionContainer')
  }

  // unselect all other cards
  $('.choiceSelectionContainer').removeClass('selected');
  $('.choiceSelectionContainer .js-location-card--input').val('').attr('checked', false).attr('data-ignore-answer', true); // create json payload looks for this when submitting answers - only want to send the one thats selected

  // set the value on the input and mark as checked
  const cardsInput = $(element).find('.js-location-card--input');
  cardsInput.val(cardsInput.attr('data-unique-code'));
  cardsInput.attr('checked', true);
  cardsInput.removeAttr('data-ignore-answer');
  $(element).addClass('selected');

  // deselect the no sites convenient box
  deselectNoSitesConvenient(false);

  // save the location key of selection for reselecting if coming back tot his page
  selectedSiteUniqueCode = e.currentTarget.querySelector('.js-location-card--input').getAttribute('data-unique-code');
  selectedSiteFacilityCD = e.currentTarget.querySelector('.js-location-card--input').getAttribute('data-facility-cd');
  selectedSiteFacilityGroupId = e.currentTarget.querySelector('.js-location-card--input').getAttribute('data-facility-group-id');
}


function addHorizontalScrollToSiteCardsContainer() {
  // add horizontal scroll to the site cards div
  const siteCardsContainer = document.getElementById('siteCardsContainer');

  if (siteCardsContainer) {
    // dont add listener if it already exists
    if (!siteCardsContainer.getAttribute('mouseWheelListenerAdded')) {

      siteCardsContainer.addEventListener('mousewheel', function (e) {
        this.scrollLeft -= (e.wheelDelta);
        e.preventDefault();
      }, false);

      siteCardsContainer.setAttribute('mouseWheelListenerAdded', 'true');
    }
  }
}

window.onload = loadGoogleMapsScript(ENV);

window.onbeforeunload = function () {
  if (ENV === "production") {
    if (!recoverProgressEmailSent && displayExitWarning) return "Are you sure you want to leave?";
  }
};

function endCall(callId) {
  return $.ajax({
    url: apiUrl + "/endCall?callId=" + callId + "&userType=" + sessionStorage["userType"],
    async: false,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    method: "POST"
  });
}

const abortCall = function (e) {
  if ($(this).hasClass("isloading")) return;
  saveSessionReloading = false;
  $(this).addClass("isloading");
  if (m_screening && m_screening.m_state) {
    endCall(m_screening.m_state.m_lCallId)
  }
  //remove caller info when ending a call
  $("#caller-name, #project-code, #study-name")
    .prev()
    .addBack()
    .remove();
  sessionStorage.removeItem('callId');
  sessionStorage.removeItem('search');
  sessionStorage.removeItem('patientID');
  //redirect to start new call
  location.href = "/callcenter/dnis";
};

let saveSessionReloading = true;

window.onunload = function () {
  endCall(m_screening.m_state.m_lCallId);
  //store callId to recover on refresh, if after first page.
  if (currentPage > 1 && saveSessionReloading) {
    sessionStorage["callId"] = m_screening.m_state.m_lCallId;
    sessionStorage["previousUrl"] = cookieCampaign;
  }
};

$("#abort-call").click(function () {
  $('#abortCallModal').modal({ keyboard: false });
  $('#abortCallModal').modal("show");
  $('#abortCallModal').find(".continue").click(function () {
    abortCall($(this));
  });
});

window.addEventListener("orientationchange", function () {
  bottomBarIphoneXFix();
});

const startNewSession = () => {
  endCall(m_screening.m_state.m_lCallId);
  sessionStorage.removeItem("callId");
  sessionStorage.removeItem("search");
  sessionStorage.removeItem('isSiteAppointmentQuestion');
  sessionStorage.removeItem('gpState');
  sessionStorage.removeItem('gpCity');
  sessionStorage.removeItem('gpCountry');
  sessionStorage.removeItem('gpPostalCode');
    //Adding this as same qstn can exist in a different study leading to back btn disabled
  for (var key in sessionStorage) {
    if (key.indexOf("siteApptVisited") === 0) {
      sessionStorage.removeItem(key);
    }
  }
  sessionStorage.removeItem('isCCEmailCheckBoxChecked');
  saveSessionReloading = false;
  location.reload();
};

//Adds button to nav bar to refresh session in dev environment.
if (ENV === "development" || ENV === "test" || ENV === "qa" || ENV === "qa2") {
  $("body").prepend(
    $(
      "<button type='button' title='New session' class='btn btn-outline' style='margin: 5px; width: 30px; padding: 0px; height: 30px; min-height: auto; font-size: 16px; position: absolute; right: 0; z-index: 999; display: flex; justify-content: center; align-items: center;'><i class='fas fa-redo-alt' style='flex: 1;'></i></button>"
    ).click(() => startNewSession())
  );
}

function generateGuestAuth() {
  const url = apiUrl + "/auth/guest";

  $.ajax({
    url: url,
    method: "POST",
    success: data => {
      // store session data
      setSessionData(data, "guest");
      getFirstQuestion();
    },
    error: (error, textStatus, errorThrown) => {
      const statusCode = error.status;
      const errorMessage = error.message;
      callGoogleAnalytics("send", "exception", {
        exDescription: `Get guest auth request fail. Error code: ${statusCode}. Error message: ${errorMessage} - ${textStatus} (${url})`,
        exFatal: false
      });

      handleUserErrors(ErrorTypes.API_GENERIC);
    }
  });
}

function setSessionData(data, type) {
  sessionStorage.setItem("TID", data.TID);
  sessionStorage.setItem("token", data.access_token.token);
  sessionStorage.setItem("userId", data.user.id);
  sessionStorage.setItem("userType", type);
}

$(".themePicker").on("change", function () {
  var selectValue = this.value;
  let accountType = sessionStorage.getItem("userType");

  if (selectValue == 1) {
    if (accountType == "guest") {
      $("body link#theme").attr("href", "/stylesheets/dist/themes/acurian-light.min.css");
    } else if (accountType == "user") {
      $("body link#theme").attr("href", "/stylesheets/dist/themes/white.min.css");
    } else {
      $("body link#theme").attr("href", "/stylesheets/dist/themes/aes.min.css");
    }
  } else if (selectValue == 2) {
    $("body link#theme").attr("href", "/stylesheets/dist/themes/acurian-light.min.css");
  } else if (selectValue == 3) {
    $("body link#theme").attr("href", "/stylesheets/dist/themes/aes.min.css");
  } else if (selectValue == 4) {
    $("body link#theme").attr("href", "/stylesheets/dist/themes/white.min.css");
  } else if (selectValue == 5) {
    $("body link#theme").attr("href", "/stylesheets/dist/themes/outdoors.min.css");
  } else if (selectValue == 6) {
    $("body link#theme").attr("href", "/stylesheets/dist/themes/young.min.css");
  }

  $(".collapse").collapse("hide");
});

function getUserDeviceInfo() {
  let device = md.mobile()
  let browserVersion = ''
  let language = ''
  let platform = ''
  let resolution = ''

  if (device == null) {
    device = "Desktop " + window.navigator.platform
  } else {
    device += " " + md.os()
  }

  let browser = md.userAgent()

  if (browser == null) {
    browser = window.navigator.userAgent
  } else {
    browser += " " + md.version(md.userAgent())
  }

  language = window.navigator.language
  platform = window.navigator.platform
  resolution = `${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}`
  browserVersion = navigator.appVersion

  return { device, browser, language, platform, browserVersion, resolution }
}

function getFirstQuestion() {
  let userInfo = {}
  let endpoint = `${apiUrl}/questions/getFirstQuestion?tid=${sessionStorage.getItem("TID")}&userType=${sessionStorage.getItem("userType")}`

  let deviceInformation = getUserDeviceInfo()
  let dataToSend = {};

  if (sessionStorage["userType"] === "cc") {

    endpoint = `${apiUrl}/questions/getFirstQuestion?tid=${sessionStorage["TID"]}&userType=${sessionStorage["userType"]}&interaction_id=${sessionStorage.getItem('interaction_id')}`;

    if (sessionStorage["dnis"].length === 10) { //CC users need to start a screener with phone number as well (for QA). A quick way to differentiate this is by checking if dnis number is of length 10
      cookieCampaign = `?campaign=${sessionStorage["dnis"]}`;
    } else {
      cookieCampaign = `?DNIS=${sessionStorage["dnis"]}`;
    }

    if (sessionStorage.getItem('interaction_id')) {
      cookieCampaign += '&interaction_id=' + sessionStorage.getItem('interaction_id');
    }

    const url = sessionStorage["originalURL"].split("?")[1];
    if (url) {
      cookieCampaign += `&${url}`;
    }

    cookieCampaign += `&cc_call_type=${sessionStorage["typeOfCall"]}`;
  }

  //check for these cookies, if we have them then we need to append extra fields to endpoint
  const dropOffEmailRequest = getCookie('dropOffEmailRequest') ? getCookie('dropOffEmailRequest') : false
  endpoint = `${endpoint}&dropOffEmailRequest=${dropOffEmailRequest}`

  if (dropOffEmailRequest === 'true') {
    const orig_patient_id = getCookie('orig_patient_id')
    //append URL
    endpoint = `${endpoint}&orig_patient_id=${orig_patient_id}`
  }

  //delete cookies
  deleteCookie('orig_patient_id')
  deleteCookie('dropOffEmailRequest')
  // always pass the phoneNumber to the backend as campaign for Project Harmony
  cookieCampaign = cookieCampaign.replace('phoneNumber', 'campaign')

  userInfo.device = deviceInformation.device
  userInfo.browser = deviceInformation.browser
  userInfo.language = deviceInformation.language
  userInfo.platform = deviceInformation.platform
  userInfo.resolution = deviceInformation.resolution
  userInfo.browserVersion = deviceInformation.browserVersion

  dataToSend.parametersFromUrl = cookieCampaign
  dataToSend.userInfo = userInfo

  $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    data: dataToSend,
    method: "GET",
    timeout: 200000,
    success: function (data) {
      let parsedData = JSON.parse(data); //not ideal to parse this here because loadData parses again
      const parsedDataPatientId = parsedData["fullAcPayload"]["m_screening"]["m_state"]["m_lPatientId"]
      currentPatientId = parsedDataPatientId
      sessionStorage.setItem("patientID", parsedDataPatientId);
      sessionStorage.setItem("usedProtos", '{}');
      sessionStorage.setItem("local.m_map", '{}');
      sessionStorage.removeItem("currentPatientPortalUser")
      sessionStorage.removeItem("currentPatientPortalPIIData")
      sessionStorage.removeItem("patientProfileId")
      translationMap = parsedData.fullAcPayload.m_appTranslationMap; //set translation map with parsedDate that we get back from AES
      let accountType = sessionStorage.getItem("userType");

      //Catches the on submit event in the form and adds validation checks
      const m_locale = parsedData["fullAcPayload"]["m_screening"]["m_locale"];
      curLocale = m_locale;
      screenerType = parsedData.fullAcPayload.m_screening.m_type
      createOsanoTag()


      const themeObject = parsedData.fullAcPayload.m_screening.m_studyCampaign.m_Theme;
      if (themeObject && themeObject.m_sInternalTheme) {
        const themeDataArr = themeObject.m_sInternalTheme.split("|");
        theme = themeDataArr[0];

        if (themeDataArr.length === 2) {
          category = themeDataArr[1];
        }
      }

      addValidation(m_locale);
      setTheme(m_locale, theme, category);
      // initCookieBanner(translationMap)
      setFooterLinks(m_locale);   //dedicated function to decide which footer links to display. Certain countries will hide various footer links

      //Load data received into page for next question
      loadData(data);

      // hide the ss help icon
      hideSSHelp();
      hideSSHelpBtn();
      // fade in scroll down icon

      callGoogleAnalytics("send", { hitType: "event", eventCategory: "Guest account", eventAction: "Started new screener" });

      getTranslationTexts();

      $("#fullscreenLoader").fadeOut(1000);
    },
    error: (error, textStatus, errorThrown) => {
      const statusCode = error.status;
      callGoogleAnalytics("send", "exception", {
        exDescription: `Get first question ${statusCode} - ${textStatus} - ${errorThrown} - (${endpoint})`,
        exFatal: false
      });

      $("#fullscreenLoader").fadeOut(1000);

      if (statusCode === 401) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.TOKEN_EXPIRED, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.TOKEN_EXPIRED_AUTORESTART);
        }
      } else if (statusCode === 403) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.UNAUTHORISED, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.TOKEN_INVALID_AUTORESTART);
        }
      } else {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.API_GENERIC, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.API_GENERIC);
        }
      }
    }
  });
}

function setFooterLinks(m_locale) {
  if (synexusCountries.includes(m_locale.split('_')[1])) {
    $('#footerLinksContainer a[data-translate-link="link.more_about_acurian"], #footerLinksContainer a[data-translate-link="link.terms-and-conditions"]').hide();
  }
  if (m_locale === "en_US") {
    $('.d-us').removeClass('d-none')
  }
}

//translations from the db for this locale, overwrite any already stored
function getTranslationTexts() {
  // get translation texts from db and overwrite local
  $.ajax({
    url: asApiUrl + getSchedulerTextUrl,
    async: false,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${asBearerToken}`
    },
    data: {
      locale: curLocale
    },
    success: (response) => {
      if (response.data.data && response.data.data.length) {

        let languageStringsData = JSON.parse(JSON.stringify(response.data.data));

        for (const i of languageStringsData) {

          // create object for this language if it doesnt exist
          if (!languageStrings[i.LOCALE]) {
            languageStrings[i.LOCALE] = {};
          }

          // overwrite everything inside
          languageStrings[i.LOCALE][i.TRANSLATION_STRING] = i.LOCAL_TEXT;
        }
      }
      setTranslatedContent(); //GFQ function that translates the page contents
    },
    error: (error) => {
      console.log('Error setting Translation Strings')
      console.log(error)
    }
  });
}

function setTranslatedContent() {
  const elementsObj = $("[data-translate], [data-translate-combine], .translation");
  for (let i = 0; i < elementsObj.length; i++) {
    const element = $(elementsObj[i]); //work with jquery objects for ease of use

    if (element.attr("data-translate")) {
      //standard translation
      element.html(translationMap[element.attr("data-translate")] || getTranslatedLanguageString('languageStrings', m_screening.m_locale, element.attr("data-translate")) || element.attr("data-translate-default")); //set the text to that of the translationMap or if null set to default value
    }

    // using loaded strings or strings in translationStrings
    if (element.hasClass('translation')) {
      let newString = element.html();

      if (languageStrings[curLocale] && languageStrings[curLocale][element.attr('name')]) {
        newString = languageStrings[curLocale][element.attr('name')];
      }
      else if (languageStrings['en_US'] && languageStrings['en_US'][element.attr('name')]) {
        newString = languageStrings['en_US'][element.attr('name')];
      }

      // this replaces the text with the button icons
      if (element.attr('name') == 't_ssInstruction2') {
        newString = newString.replace('*search icon*', ssInstructionsSearchIcon);
      }
      else if (element.attr('name') == 't_ssInstruction3') {
        newString = newString.replace('*reset icon*', ssInstructionsResetIcon);
      }

      element.html(newString);
    }

    if (element.attr("data-translate-link")) {
      //translation for links (majority in footer)
      const translationLink = element.attr("data-translate-link");

      element.attr("href", translationMap[translationLink] || element.attr("data-translate-link-default"));
    }

    if (element.attr("data-translate-combine")) {
      //special case where we need to concat more than one translation map property value.
      const concatChar = element.attr("data-translate-combine-char") || " "; //the character that will be used between each translation word/s
      const translationElements = element.attr("data-translate-combine").split("|"); //an array of all translation map keys which will be used
      const parsedStrings = []; //array of parsed strings that eventually get joined into one final string
      let failed = false; //flag to check against at the end. If it's still false, all has gone well.

      for (let i = 0; i < translationElements.length; i++) {
        parsedStrings.push(`${translationMap[translationElements[i]]}`);
        if (!translationMap[translationElements[i]]) {
          failed = true; //failed to get this translation from the translations map
        }
      }

      if (failed) {
        //any number of the split translation elements failed or not concat attr. Resort to translate-default value
        element.html(element.attr("data-translate-default"));
      } else {
        //all good. Insert the concat value
        element.html(parsedStrings.join(`${concatChar}`));
      }
    }
  }

  if (ppdFlag) {
    // set footer
    $('#footerLinksContainer .js-footerPrivacyPolicy').attr('href', 'https://www.ppd.com/who-we-are-/company-resources/privacy-policy');
    $('#footerLinksContainer .js-footerPrivacyPolicy').attr('data-translate-link-default', 'https://www.ppd.com/who-we-are-/company-resources/privacy-policy');

    $('#footerLinksContainer .js-footerMoreAbout').attr('href', 'https://www.ppd.com/');
    $('#footerLinksContainer .js-footerMoreAbout').attr('data-translate-link-default', 'https://www.ppd.com/');
    $('#footerLinksContainer .js-footerMoreAbout').html('More About PPD');

    $('#footerLinksContainer .js-footerTerms').attr('href', 'https://www.ppd.com/who-we-are/company-resources/legal-notices/');
    $('#footerLinksContainer .js-footerTerms').attr('data-translate-link-default', 'https://www.ppd.com/who-we-are/company-resources/legal-notices/');
    $('#footerLinksContainer .js-footerTerms').html('Legal Notices');

    $('#footerLinksContainer .js-footerDoNotSell').hide();
  }
}

function loadData(data, studySearchQuestion = false) {
  //Parse data
  let currentJsonPayload = JSON.parse(data);
  //Set the usedProtos array from the data we get back
  if (currentJsonPayload.usedProtos) {
    //we wont get this through for first question but only getNextQuestion
    sessionStorage.setItem('usedProtos', currentJsonPayload.usedProtos);
  }

  m_screening = currentJsonPayload["fullAcPayload"]["m_screening"];
  m_screen = currentJsonPayload["fullAcPayload"]["m_screen"];
  //get current page number
  currentPage = currentJsonPayload["currentPage"];


  hideBackButtonForCntrl = currentJsonPayload["hideBackButtonForCntrl"];

  //refresh usertimeout
  if (currentPage > 1 || sessionStorage["callId"]) userTimeout.restart();

  //Get if a valid site has been selected by the user
  let selectedValidSite = currentJsonPayload["selectedValidSite"];
  selectedSite = currentJsonPayload["selectedSite"];

  //Check if question is end of screener
  if (checkForEndOfScreener(currentJsonPayload)) {
    if (shouldShowDebugContent) {
      populateDebugConsole(currentJsonPayload["fullAcPayload"]);
    }
    return; //do not continue trying to load data
  }

  questionsTrack.push(currentJsonPayload.fullAcPayload.m_screen.m_question.m_sUniqueCode);
  //now set variable to be just the AC payload
  currentJsonPayload = currentJsonPayload["fullAcPayload"];

  $("#consent-text").html("");  // Fix for SCREEN-35389
  //First delete current generated divs
  $("#questions").empty();
  //Reset button visibility
  $("#submit").show();
  hideFixedButtonBar(false);
  if (currentPage > 1 && !hideBackButtonForCntrl) {
    $("#previous").css("display", "inline-block");
    $("#bottom-bar-previous").show();
    $(".question-header-illustration").removeClass("d-none d-sm-block");
  } else {
     //removing in case of page refresh
     sessionStorage.removeItem('argenxSkip')
    $("#previous").hide();
    $("#bottom-bar-previous").hide();
    if (!synexusFlag) {
      $(".question-header-illustration").addClass("d-none d-sm-block");
    } else {
      $(".question-header-illustration").addClass("mb-sm-8");
    }
  }

  var findResultPolitical = function(results, name) {
    var result =  _.find(results, function(obj) {
        return obj.types[0] == name && obj.types[1] == "political";
    });
    return result ? result.long_name : null;
  };

  var findResult = function(results, name) {
    var result =  _.find(results, function(obj) {
        return obj.types[0] == name
    });
    return result ? result.long_name : null;
  };

  $(function() {
    var options = {
        fields : ["geometry", "address_components"],  // Getting only co-ordinated so we are billed less
        types: ['(regions)'],   // Need to discuss, only 5 things can be passed
        componentRestrictions: {
          country: curLocale.split('_')[1].toLowerCase()
        } //restricting to a country
    };
    let inputField = $("#gpAutoTextField")[0];
    let maps = new google.maps.places.Autocomplete(inputField , options);
    
    maps.addListener('place_changed', function() {
      console.log(maps.getPlace());
      let place = maps.getPlace();
      m_screening.m_geocode = {};

      screeningGLatitude = place.geometry.location.lat();
      screeningGLongitude = place.geometry.location.lng();
      console.log('Latitude: ' + place.geometry.location.lat());
      console.log('Longitude: ' + place.geometry.location.lng());
      disableBottomBarSubmitButton(false);

      sessionStorage.setItem("gpCity", findResultPolitical(place.address_components, "locality"));
      sessionStorage.setItem("gpState", findResultPolitical(place.address_components, "administrative_area_level_1"));
      sessionStorage.setItem("gpCountry", findResultPolitical(place.address_components, "country"));
      sessionStorage.setItem("gpPostalCode", findResult(place.address_components, "postal_code"));
    });
  });

  //Display contact phone number (only do once)
  displayContactNumber(
    currentJsonPayload
  );

  //Reenable bottom bar
  disableBottomBarButtons(false);

  let disablePreviousButtonForArgenX = false;

  if(sessionStorage.argenxSkip){
    disablePreviousButtonForArgenX = true;
  }
  disablePreviousButton(disablePreviousButtonForArgenX, synexusFlag);

  //On ended, animate question in and show hidden elements
  $("#questions-form").show();
  questionInAnimation(studySearchQuestion);

  //Populate screen
  //Get all questions
  let questions = [];
  let answerTypes = []; //Save answer types too as it will be needed later on
  questions[0] = currentJsonPayload["m_screen"]["m_question"];
  answerTypes[0] = currentJsonPayload["m_screen"]["m_question"]["m_answerType"];
  let subquestions = currentJsonPayload["m_screen"]["m_question"]["m_screeningSubs"];
  if (subquestions != undefined) {
    for (let index = 0; index < subquestions.length; index++) {
      questions.push(subquestions[index]);
      answerTypes.push(subquestions[index]["m_answerType"]);
      if (subquestions[index]["m_answerType"] === 'CONSENT' && subquestions[index]["m_screeningSubs"] && subquestions[index]["m_screeningSubs"].length > 0) {
        //consent question can have m_subs 3 levels deep, per each question add as a question / answerType
        for (let subConsentQuestionObj of subquestions[index]["m_screeningSubs"]) {
          questions.push(subConsentQuestionObj);
          answerTypes.push(subConsentQuestionObj["m_answerType"]);
        }
      }
    }
  }
  
  /*
  if (questions[0]['m_basis'] === 'GPAUTO' && questions[0].m_bRequired) {
    disableBottomBarSubmitButton(true)
  }
  */

  //create container form
  const questionsContainer = document.getElementById("questions");
  selectedSiteFacilityGroupId = null;

  if (questions[0]["m_sGroupAttribute"] === 'CONSENT' || questions[0]["m_sPIIType"] === 'CONSENT') {
    buildQuestions(questions, questionsContainer, currentJsonPayload);  //CHECK FOR CONSENT BEFORE CHECKING FOR PII. STANDALONE CONSENT CAN BE PII TYPE
  }
  else if (questions[0]["m_sGroupAttribute"] == "IDQUES") {
    buildPIIQuestions(questions, questionsContainer, currentJsonPayload);
  }
  else {
    buildQuestions(questions, questionsContainer, currentJsonPayload);
  }

  //For all inputs created, add event to scroll page to focused input
  $(".form input").focus(function ($event) {
    // only scroll to focused input on small devices
    if (getDeviceWidth() <= mobileBreakPoint) {
      // based on the mobile breakpoint in scss, not sure if there's a better way of doing this?
      scrollTo($(this).offset().top - 40, 600);
    }
  });
  /**
   * Slightly hacky way of detecting autofill from the browser using fake animations
   * Not ideal - but not many better ways of doing it that I can find!
   */
  $(".form input").on("animationstart", e => {
    // this sometimes fires on inputs you dont want it to and e is undefined
    if (e) {
      switch (e.originalEvent.animationName) {
        case "onAutoFillStart":
          $("html, body").stop();
          break;
        case "onAutoFillCancel":
          // Not sure if we'd need to do anything for this
          break;
      }
    }
  });

  $(".tooltip").remove();

  // Configure question for CC or OLS:

  if (currentJsonPayload["m_screening"]["m_type"] == "CALLCENTER") {
    //Adds the project code and study name.

    $('#faq-link').attr('href', () => {
      if (translationMap["filename.faq"]) {
        return `https://acurianhealth.com/questionnaire${translationMap["filename.faq"].replace(/\{0\}/, m_screening.m_study.m_sProjectCode)}`; //filename.faq includes the faq path e.g. "/faqs/{0}-Frequently-Asked-Questions_pl_PL.html"
      } else {
        return `https://acurianhealth.com/questionnaire/faqs/${currentJsonPayload.m_screening.m_study.m_sFaqName}`;
      }
    })

    //Adds the project code and study name.
    const $projectCode = $('#project-code')
    const projectCode = currentJsonPayload.m_screening.m_study.m_sProjectCode
    if ($projectCode.length) {
      $projectCode.html(projectCode);
    }

    const $studyName = $("#study-name");
    const studyName = currentJsonPayload.m_screening.m_study.m_sName
    if ($studyName.length) {
      $studyName.html(studyName);
    }

    //Add the question-title to the top of the question.
    let questionTitle;
    const question = currentJsonPayload.m_screen.m_question;
    if (subquestions) {
      if (question.m_sGroupAttribute === "IDQUES") {
        questionTitle = `Identification Question`;
      } else {
        questionTitle = `Study Question`;
      }
    } else if (question.answer_type === "SITE_SELECTION") {
      questionTitle = `Site Selection Question`;
    } else {
      questionTitle = `Question ${showDecSigFig(question.m_fOrder)}`;
    }
    $("#question-title").text(questionTitle);
  }

  setOlsOrCc(currentJsonPayload["m_screening"]["m_type"]); //set which dom elements to include/remove

  const questionToCheck = currentJsonPayload.m_screen.m_question;
  if (sessionStorage.getItem('isSiteAppointmentQuestion') === 'true'
      || sessionStorage.getItem(`siteApptVisited[${questionToCheck.m_sUniqueCode}]`) === 'true') {

    disablePreviousButton(true, synexusFlag);
    sessionStorage.setItem(`siteApptVisited[${questionToCheck.m_sUniqueCode}]`, 'true');
    sessionStorage.removeItem('isSiteAppointmentQuestion');
  }

  //Checking for site schedule links in question
  let siteScheduleLink = $('a[href*="/site-schedule?"], a[href*="/scheduling/"]');
  if (siteScheduleLink.length === 1) {
    //current question has the siteSchedule link (provided by AES)
    outputSiteSchedule(`${window.location.origin}/site-schedule?${siteScheduleLink[0].href.split("?")[1]}&Aes=true&locale=${m_screening.m_locale}&screener_type=${m_screening.m_type}`); //take the href of the AES link and just take everything after the ?
    initialiseSiteSchedule(); //set message listeners
    $("#fixed-bottom-bar").addClass("d-none"); //hide the fixed-bottom-bar
  }

  //Animate the content container when its height changes for a smoohter UX.
  const heightMod = synexusFlag ? 200 : 300;
  var totalHeight = heightMod + $(questionsContainer).outerHeight(); //calculate an the empty container (no question content) + the hidden questionContainer height
  $("#screener-container").animate({ "min-height": totalHeight }, 300, "linear");

  //Add disclaimer text
  let disclaimerElement = $("#disclaimer-text");

  if (
    currentJsonPayload["m_appTranslationMap"] &&
    currentJsonPayload["m_appTranslationMap"]["first_question.disclaimer_text"]
  ) {
    disclaimerText = currentJsonPayload["m_appTranslationMap"]["first_question.disclaimer_text"];
  }

  const doNotShowDisclaimerTextProjectCodes = ['PERS', 'GRA1VFS', 'AHCP', '12027', 'MMRS', '12027HP'];
  if (currentPage === 1 && m_screening.m_type === "OLS" && doNotShowDisclaimerTextProjectCodes.indexOf( m_screening.m_study.m_sProjectCode ) == -1 && currentJsonPayload.m_screening.m_answers.m_historyOfMaplist.length === 0) {
    disclaimerElement.html("<hr/><p>" + disclaimerText + "</p>");
    disclaimerElement.show();
    makeThisQuestionAutosubmit(answerTypes, m_screening.m_state.sessionId);
  } else {
    disclaimerElement.hide();
  }
  if( currentPage === 1 && (m_screening.m_study.m_sProjectCode == '12027') ) {
    $('#footerLinksContainer').prepend('<a>MED-US-EFG-2400075</a>');
  }

  //Configure telephone input fields
  const telFieldsObj = $('input[type="tel"]:not(".noTelephone")');
  //If screener locale is US, force to not use intl tel plugin (their API only accepts 10 digits for OUS)
  if (currentJsonPayload["m_screening"]["m_locale"] === "en_US") {
    for (let index = 0; index < telFieldsObj.length; index++) {
      setTelephoneMask(telFieldsObj[index], "US");
    }
  } else {
    configureTelInputs(telFieldsObj, currentJsonPayload["m_screening"]["m_locale"]);
  }

  //Conditionally decide whether to display debug console
  if (shouldShowDebugContent) {
    saveQuestionText(questions , m_screening.m_study.m_sProjectCode); //create object of question text
    populateDebugConsole(currentJsonPayload);

    $(".debugQuestionInfo").show();
  }

  deviceDetails = detectDevice();
  const isMobile = deviceDetails.isMobile;


  if(window.matchMedia('(max-width: 767px)').matches || isMobile){
    let divHeightQstn;
    $(".question-text").each(function (){
      let currentElement = $(this);
      if($(this).find(".glossary-tooltip-mobile").length > 0){
            divHeightQstn = currentElement.innerHeight()/2;
            currentElement.parents('div.col-md-12.col-10.col-lg-12').eq(0).siblings('div.d-inline.d-md-none.col-2').eq(0)
            .css("margin-top",divHeightQstn);
      }
    })
      const isAnyHasGlossaryData = $(".answers-container div.row").find("button").find('.glossary-tooltip-mobile').hasClass('glossary-tooltip-mobile');
    $(".answers-container div.row").each(function(){
        let divHeight = $(this).find("button").innerHeight();
        const hasGlossaryData = $(this).find("button").find('glossary-tooltip-mobile').hasClass('glossary-tooltip-mobile');
        if($.isNumeric(divHeight) && isAnyHasGlossaryData){
          $(this).find("i.fa.fa-question-circle").parent().css("margin-top",divHeight/2);
          $(this).css("height",divHeight+20);
        }
    })
  }


  //Place tracking pixels from this payload
  if (m_screening) {
    injectTrackingPixels(m_screening, currentJsonPayload["m_screen"]["m_question"]);
  }

  if (translationMap && translationMap["link.privacy_policy"]) {
    createOsanoListener(translationMap["link.privacy_policy"])
  }

  //Check for errors
  if (handleErrors(currentJsonPayload)) {
    disableBottomBarSubmitButton(true);
  } else {
    //Now check if the next button has to be disabled based on the types of questions and answers
    const answerTypesRequireSelection = [
      "TEXT_DATE",
      "TEXT_MM_YYYY",
      "TEXT_YEAR",
      "TEXT_NUMBER",
      "TEXT",
      "TEXT_ZIP",
      "TEXT_PHONE",
      "TEXT_HEIGHT",
      "TEXT_WEIGHT",
      "EMAIL",
      "TEXT_AREA",
      "RADIO",
      "CHECKBOX",
      "DUAL_HEIGHT",
      "DUAL_WEIGHT",
      "SITEDISPL",
      "STUDYSEL",
      "PROTODD",
      "STUDYSWPR",
      "INDICATION_LIST",
      'GPAUTO'
    ];
    for (let answerType of answerTypes) {
      if (answerTypesRequireSelection.includes(answerType)) {
        disableBottomBarSubmitButton(true);
        //Now check if answers have been prepopulated (i.e. PII question prepropulates answers as they are being built if data is in the payload)
        checkIfAllRequiredQuestionsAreAnswered();
        break;
      }
    }
  }

  //CHECK IF SCREENER CONTENT NEEDS TO BE RTL (ONLY APPLIES TO BODY ONCE)
  if (m_screening.m_ptd === "RIGHT_TO_LEFT" && !$('body').hasClass('rtl')) {
    $('body').addClass('rtl');
  }
}

function saveQuestionText(questions , projectCode) {
  for (let question of questions) {
    const questionText = question.m_sText || question.m_sRawText;
    let formattedQuestionText = "";

    if (/<[a-z][\s\S]*>/i.test(questionText) && questionText.includes(`show-in-${olsOrCcQuestion.toLowerCase()}`)) {
      //raw text contains html syntax, and it contains 'show-in-x' class, lets get the correct div content and tidy it
      try {
        formattedQuestionText = $(questionText)
          .toArray()
          .filter(x => x.className === `show-in-${olsOrCcQuestion.toLowerCase()}`); //will have multiple spaces new lines etc
        formattedQuestionText = $(formattedQuestionText).text();
        formattedQuestionText = formattedQuestionText.replace(/\s\s+/g, " "); //format text - replace multiple spaces with singles (including new lines)
      } catch {
        formattedQuestionText = questionText ? questionText : "";
      }
    } else {
      formattedQuestionText = questionText ? questionText : ""; //plain text
    }

    formattedQuestionText =
      formattedQuestionText.length > 150 ? formattedQuestionText.slice(0, 150) + "..." : formattedQuestionText; //ellipsis text that exceeds x chars
    debugQuestionContent[question.m_sUniqueCode+"/"+projectCode] = { text: formattedQuestionText, pageNum: currentPage };
  }
}

const autoPopulateIndependentPIIQuestions = (questionData) => {
  const pIIData = currentPatientPortalPIIData || JSON.parse(sessionStorage.getItem('currentPatientPortalPIIData'))
  if (!pIIData) { return }
  switch (questionData.m_basis) {
    case "ZIP": {
      autoPopulateAnswers([{
        questionUniqueId: questionData.m_sUniqueCode,
        answers: [pIIData.zip]
      }])
      sessionStorage.setItem("postcode", pIIData.zip);
    }
      break;
    case "DOB": {
      autoPopulateAnswers([{
        questionUniqueId: questionData.m_sUniqueCode,
        answers: [pIIData.dob.split(" ")[0]]
      }])
    }
      break;
    case "GENDER": {
      autoPopulateAnswers([{
        questionUniqueId: questionData.m_sUniqueCode,
        answers: [pIIData.gender]
      }])
    }
      break;
    case "EMAIL": {
      autoPopulateAnswers([{
        questionUniqueId: questionData.m_sUniqueCode,
        answers: [pIIData.email]
      }])
    }
      break;
  }
}

function buildQuestions(questions, questionsContainer, currentJsonPayload) {
  isCurrentQuestionPII = false
  isParentCoreModuleFound = questions.find(question => question['m_sModuleName'] === 'Core')
  //Loop through questions
  // renderAuthBanner()
  reRenderChecks();
  answerCount = 0;
  checkEmailFlagAndSignUpFlags(questions)
  questions.forEach(questionData => {
    let answerType = questionData["m_answerType"];
    let node = createQuestionElement(questionData);

    questionsContainer.appendChild(node);

    //Check if question is an autosubmit question, if not, continue to create answers
    if (checkIfAutosubmitQuestion(questionData, currentJsonPayload.m_screening.m_state.sessionId)) {
      return;
    }

    if (
      questionData["m_basis"] === "PRIVACY_POLICY_EMAIL_FLAG" &&
      (m_screening["m_type"] === "OLS" || !m_screening.m_state.m_bShowPrivacyPolicyOption)
    ) {
      return;
    }

    if (answerType != "INFO") {
      createAnswers(answerType, node, questionData);
    }

    //If DOB question, autopopulate details
    if (node.dataset.questionBasis == "DOB") {
      getAnswerFromGetPayload(currentJsonPayload, questionData);
    }
    autoPopulateIndependentPIIQuestions(questionData)
    if (questionData['m_sModuleName'] === 'Core' || isParentCoreModuleFound !== undefined || (questionData.m_basis && (questionData.m_basis === "HEIGHT" || questionData.m_basis === "WEIGHT"))) {
      autoPopulatePatientPortalGenericData(questionData)
    }
  });
}

//If page is PII, questions need to be handled differently
function buildPIIQuestions(questions, questionsContainer, currentJsonPayload) {
  isCurrentQuestionPII = true
  // reset setup email so that email can be changed when going back to pii
  recoverProgressEmailSent = false;

  //Check if main question is qualified PII
  let showProgressConsentForEmail = true;
  if (questions[0]["m_basis"] == "QUALIFIED_PII") {
    showProgressConsentForEmail = false;
  }

  let allQuestionBasis = [];
  let studyCampaign = currentJsonPayload["m_screening"]["m_studyCampaign"];
  const piiTypesList = ["PATINFO", "CAREGIVER", "PEDIATRIC", "QCPATINFO", "QCCAREGIVER", "QCPEDIATRIC"];
  // Save the Question PIIType
  const questionPIIType = questions[0]["m_sPIIType"];

  if (piiTypesList.includes(questionPIIType) ) {
    disablePreviousButton(true, synexusFlag);
  }
  //This should be populated (shouldn't it wait until above call is complete? is this related to the error we were seeing?)
  //Check what type of PII it is
  if (
    questions[0]["m_sPIIType"] == "CAREGIVER" ||
    questions[0]["m_sPIIType"] == "PEDIATRIC" ||
    questions[0]["m_sPIIType"] == "QCPEDIATRIC" ||
    questions[0]["m_sPIIType"] == "QCCAREGIVER"
  ) {
    //Dependant person
    //Create 3 containers
    let mainContainer = document.createElement("div");
    mainContainer.className = "pii-main-container";

    let caregiverContainer = document.createElement("div");
    caregiverContainer.className = "pii-caregiver-container";

    let patientContainer = document.createElement("div");
    patientContainer.className = "pii-patient-container";

    questionsContainer.appendChild(mainContainer);
    questionsContainer.appendChild(caregiverContainer);
    questionsContainer.appendChild(patientContainer);

    //Loop through questions
    questions.forEach(questionData => {
      if (questionData["m_sShowIndicator"] != null && questionData["m_sShowIndicator"] == "N") {
        return;
      }

      //If OLS and PRIVACY_POLICY_EMAIL_FLAG
      if (
        questionData["m_basis"] === "PRIVACY_POLICY_EMAIL_FLAG" &&
        (m_screening["m_type"] === "OLS" || !m_screening.m_state.m_bShowPrivacyPolicyOption)
      ) {
        return;
      }

      allQuestionBasis.push(questionData["m_basis"]);

      //Create question container element
      let node = createQuestionElement(questionData);

      if ((questionData['m_answerType'] === "CONSENT" || questionData['m_sGroupAttribute'] === "PRECONSENT" || questionData['m_sGroupAttribute'] === "POSTCONSENT") && !isConsentEligible()) {
        //consent question OR a pre-consent question (terms and conditions). Hide the question if m_screening hideConsentFlag is true for this m_locale
        $(node).addClass('d-none');
      }

      //Add required symbol for PII questions
      if (questionData["m_iPIIElementId"] != 0 && questionData["m_bRequired"] == true) {
        node.getElementsByClassName("question-text")[0].innerHTML +=
          "<span class='text--error font-weight-bold'> *</span>";
      }

      /**if (
        questionData["m_iPIIElementId"] != 0 &&
        questionData["m_bRequired"] == false &&
        questionData["m_basis"] !== "PATIENT_INFO_TEXT" &&
        questionData["m_answerType"] !== "INFO"
      ) {
        node.getElementsByClassName("question-text")[0].innerHTML += "<span class='text--optional'>(optional)</span>";
      }**/

      if (questionData["m_basis"] == null || questionData["m_basis"] == "QUALIFIED_PII") {
        mainContainer.appendChild(node);
      } else if (questionData["m_basis"].includes("CAREGIVER")) {
        if (questionData["m_basis"] == "CAREGIVER_INFO_TEXT" && caregiverContainer.childNodes.length > 0) {
          caregiverContainer.insertBefore(node, caregiverContainer.childNodes[0]); //Assume there is no "INFO" question
        } else {
          caregiverContainer.appendChild(node);
        }

        if (questionData["m_basis"] === 'CAREGIVER_DAY_PHONE' && m_screening.m_state.isSendRVAllowed && piiTypesList.includes(questionPIIType) && m_screening.m_type === 'OLS') {
          let textElement = document.createElement("p");
          let rvPhoneNumberText = translationMap["rv.phone_number_text"];
          textElement.innerHTML = rvPhoneNumberText.replace("${ORIGINATION_PHONE}", studyCampaign.m_sOriginationNumber);
          caregiverContainer.appendChild(textElement);
        }
      } else {
        if (questionData["m_basis"] == "PATIENT_INFO_TEXT" && patientContainer.childNodes.length > 0) {
          patientContainer.insertBefore(node, patientContainer.childNodes[0]); //Assume there is no "INFO" question
        } else {
          patientContainer.appendChild(node);
        }
      }

      if (questionData["m_answerType"] != "INFO") {
        createAnswers(questionData["m_answerType"], node, questionData, showProgressConsentForEmail);
      }
      const account = myMSALObj.getAllAccounts()[0]
      if (patientProfileId && currentPatientPortalPIIData && account) {
        autoPopulatePatientPortalPIIData(currentJsonPayload)
      }
      // auto populate PII
      getAnswerFromGetPayload(currentJsonPayload, questionData);

    });
  } else {
    //Loop through questions
    questions.forEach(questionData => {
      //If OLS and PRIVACY_POLICY_EMAIL_FLAG
      if (
        questionData["m_basis"] === "PRIVACY_POLICY_EMAIL_FLAG" &&
        (m_screening["m_type"] === "OLS" || !m_screening.m_state.m_bShowPrivacyPolicyOption)
      ) {
        return;
      }

      allQuestionBasis.push(questionData["m_basis"]);

      //Create question container element
      let node = createQuestionElement(questionData);

      if ((questionData['m_answerType'] === "CONSENT" || questionData['m_sGroupAttribute'] === "PRECONSENT" || questionData['m_sGroupAttribute'] === "POSTCONSENT") && !isConsentEligible()) {
        //consent question OR a pre-consent question (terms and conditions). Hide the question if m_screening hideConsentFlag is true for this m_locale
        $(node).addClass('d-none');
      }

      //Add required symbol for PII questions
      if (questionData["m_iPIIElementId"] != 0 && questionData["m_bRequired"] == true) {
        node.getElementsByClassName("question-text")[0].innerHTML +=
          "<span class='text--error font-weight-bold'> *</span>";
      }

      /**if (
        questionData["m_iPIIElementId"] != 0 &&
        questionData["m_bRequired"] == false &&
        questionData["m_basis"] !== "PATIENT_INFO_TEXT" &&
        questionData["m_answerType"] !== "INFO"
      ) {
        node.getElementsByClassName("question-text")[0].innerHTML += "<span class='text--optional'>(optional)</span>";
      }**/

      //If PII title, place first, if not, place at the end normally
      if (questionData["m_basis"] == "PATIENT_INFO_TEXT" && questionsContainer.childNodes.length > 1) {
        questionsContainer.insertBefore(node, questionsContainer.childNodes[1]); //Assume first child is empty "INFO" question
      } else {
        questionsContainer.appendChild(node);
      }

      if (questionData["m_basis"] === 'DAY_PHONE' && m_screening.m_state.isSendRVAllowed && piiTypesList.includes(questionPIIType) && m_screening.m_type === 'OLS') {
        let textElement = document.createElement("p");
        let rvPhoneNumberText = translationMap["rv.phone_number_text"];
        textElement.innerHTML = rvPhoneNumberText.replace("${ORIGINATION_PHONE}", studyCampaign.m_sOriginationNumber);
        questionsContainer.appendChild(textElement);
      }

      if (questionData["m_answerType"] != "INFO") {
        createAnswers(questionData["m_answerType"], node, questionData, showProgressConsentForEmail);
      }
      if (patientProfileId && currentPatientPortalPIIData) {
        autoPopulatePatientPortalPIIData(currentJsonPayload)
      }

      // auto populate PII
      getAnswerFromGetPayload(currentJsonPayload, questionData);

    });
  }

  //Check if any consent questions are displayed, if not show privacy text from translation map
  let consentTextNeeded = true;
  const consentText = $("#consent-text");

  if (m_screening["m_type"] === "OLS") {
    const consentsQuestions = ["PRECSNT0_INFO", "PRECSNT1_CHK", "PRECSNT2_INFO"];
    for (let questionBasis of allQuestionBasis) {
      if (consentsQuestions.includes(questionBasis)) {
        consentTextNeeded = false;
        return;
      }
    }
    if (consentTextNeeded === true && translationMap["contact_page.ols_legal_contact_text"] !== "contact_page.ols_legal_contact_text") {
      //sometimes "contact_page.ols_legal_contact_text" can come through as the value, check that this is not the case before showing to the fe
      consentText.html(translationMap["contact_page.ols_legal_contact_text"]);
      consentText.removeClass("d-none");
    } else {
      consentText.addClass("d-none");
    }
  } else {
    consentText.addClass("d-none"); //for cc users we do not want to display any legal_contact_text content
  }
}

//Checks if get payload has pre-populated answers for the question passed
function getAnswerFromGetPayload(jsonPayload, questionToPopulate) {
  if (jsonPayload["m_screen"]["m_answers"][questionToPopulate["m_sUniqueCode"]] != undefined) {
    //Set answer using raw text or choices
    let answerValue = jsonPayload["m_screen"]["m_answers"][questionToPopulate["m_sUniqueCode"]]["m_sRawAnswer"];

    if ((answerValue == undefined || answerValue == "") &&
      jsonPayload["m_screen"]["m_answers"][questionToPopulate["m_sUniqueCode"]]["m_sChoices"].length > 0
    )
      answerValue = jsonPayload["m_screen"]["m_answers"][questionToPopulate["m_sUniqueCode"]]["m_sChoices"][0];

    //If found, set input
    if (answerValue != undefined && answerValue != "") {
      //PII will be input field or dropdown
      let inputElement = $("#" + questionToPopulate["m_sUniqueCode"] + "> .answers-container > input");
      if (inputElement.length == 0)
        inputElement = $("#" + questionToPopulate["m_sUniqueCode"] + "> .answers-container > .dropdown2 > select");
      //If element found, set value
      if (inputElement.length != 0) {
        if (questionToPopulate["m_answerType"] === "TEXT_DATE") {
          inputElement.val(formatDateToLocale(answerValue, questionToPopulate["m_answerType"]));
          populateDateField(inputElement[0]);
        } else if (questionToPopulate["m_answerType"] === "TEXT_MM_YYYY") {
          inputElement.val(formatDateToLocale(answerValue, questionToPopulate["m_answerType"]));
          populateDateField(inputElement[0]);
        } else {
          inputElement.val(answerValue);
        }
      }

      // multiple choice questions also arent always checkbox.input
      if (questionToPopulate.m_answerType == 'CHECKBOX') {
        const qEle = $("#" + questionToPopulate["m_sUniqueCode"]);
        if (qEle) {
          for (const c of questionToPopulate['m_choiceSource']['m_choices']) {
            const subQuesBtn = $('#answer-option-' + c['m_sUniqueChoiceCode']);

            if (subQuesBtn && jsonPayload["m_screen"]["m_answers"][questionToPopulate["m_sUniqueCode"]]["m_sChoices"].includes(c['m_sUniqueChoiceCode'])) {
              $(subQuesBtn).click();
            }
          }
        }
      }
    }
  }
}

function createOsanoListener(privacyLink) {
  if (!osanoIntervalExpire) {
    osanoIntervalExpire = Date.now() + 10000; // expire in 10 seconds
  }

  //This code is to handle info icon in glossary width < 767 for centering
  $("#questions").find("button").each(function(){
    $(this).parent().parent().find("i").parent().css("margin-top",$(this).innerHeight()/2)
  });

  $(".question-text").each(function(){
    $(this).parent().siblings().find('i').parent().css("margin-top",$(this).innerHeight()/2);
  });


  osanoInterval = setInterval(function () {

    if ($('#osano-cm-drawer-toggle--category_OPT_OUT').length) {
      $('#osano-cm-drawer-toggle--category_OPT_OUT').closest('.osano-cm-drawer-item').hide();
    }

    if ($('a.osano-cm-powered-by__link').length) {
      $('a.osano-cm-powered-by__link').attr('target', '_blank')
    }

    // when the link exists, replace the link and stop the interval
    if ($('a.osano-cm-storage-policy').length) {
      replaceOsanoPrivacyPolicy(privacyLink)
      clearInterval(osanoInterval)
    }

    // Only run for x thousand milliseconds.  1 sec = 1000 milli
    if (Date.now() > osanoIntervalExpire) {
      clearInterval(osanoInterval)
    }

  }, 250)

}

const checkEmailFlagAndSignUpFlags = (questions) => {
  isEmailQuestion = Boolean(questions.find(question => question.m_basis === 'EMAIL'))
}

function createQuestionElement(questionData) {
  let node = document.createElement("div"); //question+answer container
  node.id = questionData["m_sUniqueCode"];
  node.setAttribute("data-question-basis", questionData["m_basis"]);
  node.setAttribute("data-answer-type", questionData["m_answerType"]);

  node.className = "question-answers-container";
  //Create div for text
  let textElement = document.createElement("div");
  textElement.className = "question-text";
  textElement.innerHTML = questionData["m_sText"] ? questionData["m_sText"] : "";
  signUpAllowed = Boolean(questionData["m_answerType"] === 'SIGNUP')
  if (signUpAllowed) {
    renderAuthBanner()
  } else {
    reRenderChecks()
  }

  if (questionData["m_answerType"] === 'CONSENT') {
    node.setAttribute("data-required", false);
  } else {
    node.setAttribute("data-required", questionData["m_bRequired"]);
  }

  node.appendChild(textElement);
  return node;
}

//Function to set hide divs with CC or OLS classes depending on type
function setOlsOrCc(type) {
  if (type == "CALLCENTER") {
    $(".show-in-ols").remove(); //remove ols content for cc user
    $(".show-in-cc-ui").removeClass("d-none");
    olsOrCcQuestion = "CALLCENTER";
  } else {
    $(".show-in-cc, .agent-note").remove(); //remove cc content for ols user
    olsOrCcQuestion = "OLS";
  }
}

function replaceOsanoPrivacyPolicy(privacyPolicyLink) {
  if (privacyPolicyLink) {
    const privacy_policy_url = privacyPolicyLink
    const privacyPolicySelector = 'a.osano-cm-storage-policy' //note: returns 2 links/nodes
    const privacyPolicyLinks = $(privacyPolicySelector)
    if (privacyPolicyLinks && privacyPolicyLinks.length > 0) {
      privacyPolicyLinks.each(function () { //Osano has more than 1 link looks like (was only replacing hidden link 1st time), so loop over and change the priv policy link to what was in our JSON response
        if (ppdFlag) {
          $(this).attr('href', 'https://www.ppd.com/who-we-are-/company-resources/privacy-policy');
          $(this).attr('target', '_blank');
        }
        else {
          $(this).attr('href', privacy_policy_url);
          $(this).attr('target', '_blank');
        }
      });
    }
  }
}

const buildCustomEventPayload = (uniqueQuestionCode, event) => {
  return {
    event,
    question: uniqueQuestionCode,
    project_code: m_screening.m_study.m_sProjectCode,
    aes_campaign_id: m_screening.m_studyCampaign.m_sOutreachCampaignId,
    app: m_screening.m_type,
    site_type: event === PICKED_SITE_ON_SITESEL_QUESTION ? translateFacilityGroupIDtoName(parseInt(selectedSiteFacilityGroupId)) : ''
  }
}

const fireCustomGTA = ({ questionCode, answerType }) => {
  // First Question Enter
  if (questionCode === questionsTrack[0]) {
    window.dataLayer.push(buildCustomEventPayload(questionCode, REACHED_FIRST_QUESTION));
    return
  }
  // First Question Exit, Second Question Enter
  if (questionCode === questionsTrack[1]) {
    window.dataLayer.push(buildCustomEventPayload(questionsTrack[0], LEFT_FIRST_QUESTION));
    window.dataLayer.push(buildCustomEventPayload(questionCode, REACHED_SECOND_QUESTION));
    return
  }
  // Second Question Exit
  if (questionCode === questionsTrack[2]) {
    window.dataLayer.push(buildCustomEventPayload(questionsTrack[1], LEFT_SECOND_QUESTION));
    return
  }
  // SiteSelection Enter
  if (answerType == SITE_SELECTION) {
    return window.dataLayer.push(buildCustomEventPayload(questionCode, REACHED_SITESEL_QUESTION));
  }
  // Node: Move code in submit Data
  // if (siteSelection !== null && siteSelection !== undefined && siteSelection.toLowerCase() !== 'none' && siteSelection.toLowerCase() !== 'nosites') {
  //   Remove 'return' as you may want to also process picking a site below
  //   window.dataLayer.push(buildCustomEventPayload(questionCode, PICKED_SITE_ON_SITESEL_QUESTION));
  // }

  let siteScheduleLink = $('a[href*="/site-schedule?"], a[href*="/scheduling/"]');
  if (siteScheduleLink.length >= 1) {
    window.dataLayer.push(buildCustomEventPayload(questionCode, REACHED_SCHEDULING_QUESTION));
    return;
  }
}

function injectTrackingPixels(m_screening, m_question) {

  // gtag("event", "page_view", {
  //   page_title: m_screening.m_study.m_sProjectCode + "|" + m_question.m_sUniqueCode,
  //   page_location: location.origin + "/project/" + m_screening.m_study.m_sProjectCode + "/question/" + m_question.m_sUniqueCode,
  //   page_path: "/project/" + m_screening.m_study.m_sProjectCode + "/question/" + m_question.m_sUniqueCode,
  //   send_to: "GTM-PJP94LW" //not sure if needed
  // });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "page_view",
    page_title: m_screening.m_study.m_sProjectCode + "|" + m_question.m_sUniqueCode,
    page_location: window.location.origin + "/project/" + m_screening.m_study.m_sProjectCode + "/question/" + m_question.m_sUniqueCode,
    page_path: "/project/" + m_screening.m_study.m_sProjectCode + "/question/" + m_question.m_sUniqueCode,
    question: m_question.m_sUniqueCode,
    project_code: m_screening.m_study.m_sProjectCode,
    aes_campaign_id: m_screening.m_studyCampaign.m_sOutreachCampaignId,
    app: m_screening.m_type,
  });

  fireCustomGTA({
    questionCode: m_question.m_sUniqueCode,
    answerType: m_question.m_answerType
  });

  if (m_screening.m_type != 'OLS') {
    hideCookieBanner() // do not show cookie banner for CC
    return // no need to handle tracking pixels
  }

  let iframe_holder = $('#tracking-pixels-container');

  // const variantCountries = [
  //   'AT', // Austria
  //   'BE', // Belgiun
  //   'BG', // Bulgaria
  //   'HR', // Croatia
  //   'CY', // Cyprus
  //   'CZ', // Czechia
  //   'DK', // Denmark
  //   'EE', // Estonia
  //   'FI', // Finland
  //   'FR',  // France
  //   'DE', // Germany
  //   'GB', // Great Britain
  //   'GR',  // Greece
  //   'HU', // Hungary
  //   'IE', // Ireland
  //   'IT', // Italy
  //   'LV', // Latvia
  //   'LT', // Lithuania
  //   'LU', // Luxembourg
  //   'MT', // Malta
  //   'NL', // Netherlands
  //   'PL', // Poland
  //   'PT', // Portugal
  //   'RO', // Romania
  //   'SK', // Slovakia
  //   'SL', // Slovenia
  //   'ES',  // Spain
  //   'SE', // Sweden
  // ]

  if (m_screening.m_locale) {
    // let localeParts = m_screening.m_locale.split('_')
    // const language = localeParts[0]
    // const country = localeParts[1]

    // append variant to the osano script in the head for all countries
    // const variant = '?variant=two'

    // // append variant to the osano script in the head
    // if (variantCountries.includes(country)) {
    //   hideCookieBanner()
    //   // append to dynamically injected tracking pixel script
    //   osanoScriptUrl += variant
    //   $('head').append(`<script src="${osanoScriptUrl}"></script>`)
    // }

    if (m_screening
      && m_screening.m_trackingPixels
      && m_screening.m_trackingPixels.m_snippets
      && m_screening.m_trackingPixels.m_snippets.length > 0
      && m_screening.m_type !== "CALLCENTER"
    ) {

      setTimeout(function () {

        for (let i = 0; i < m_screening.m_trackingPixels.m_snippets.length; i++) {
          let pixel = m_screening.m_trackingPixels.m_snippets[i];

          if (pixel.m_bUseOnCurrentQuestion && pixel.m_sFinalSnippet) {
            if (!(trackingPixels.includes(pixel.m_lStudyWebTrackingId) && pixel.m_bFireOnlyOnce)) {
              trackingPixels.push(pixel.m_lStudyWebTrackingId)
              let iframeId = `tracking-iframe-${pixel.m_lStudyWebTrackingId}`
              let iframeContainer = $(`<iframe id="${iframeId}" style="display:none;"></iframe>`);
              iframe_holder.append(iframeContainer)

              let iframe = document.getElementById(iframeId);
              let osano_tag = `<scr` + `ipt type="text/javascript" src="${osanoScriptUrlLocal}"></scr` + `ipt>`;
              // get a handle on the <iframe>d document (in a cross-browser way)
              let doc = iframe.contentWindow || iframe.contentDocument;
              if (doc.document) {
                doc = doc.document;
              }
              // open, write content to, and close the document
              doc.open();


              // append variant to the osano script in the head for all countries
              // if (variantCountries.includes(country)) {
              doc.write(osano_tag); // Add Osano Tag
              // }

              doc.write(pixel.m_sFinalSnippet); // Always Add Tracking Pixel Snippet
              doc.close();
            }
          }

        }

      }, 500)

    }

  }

}

function getUserAccountType(titleCase = false) {
  let accountType = sessionStorage.getItem("userType") ? sessionStorage.getItem("userType") : "unknown";
  if (titleCase) {
    //title case can be used for presentation/GA purposes
    accountType = `${accountType.charAt(0).toUpperCase()}${accountType.slice(1)}`; //title case the single word
  }
  return accountType;
}

function checkForEndOfScreener(data) {
  signUpAllowed = false;
  //There is two possible ending types (this field is true if AC payload was null)
  let isEndOfScreener = data["endState"];
  const accountType = getUserAccountType(true);

  //If AC payload wasn't null, then check for endState in it and redirect url
  let redirectUrl;
  if (!isEndOfScreener) {
    isEndOfScreener = data["fullAcPayload"]["m_screen"]["m_bEndState"];
    if (isEndOfScreener) {
      redirectUrl = data["fullAcPayload"]["m_cpaEndLink"];
    }
  }

  //If end of screener
  if (isEndOfScreener) {
    displayExitWarning = false; //If end of screener, turn this to false before redirecting to other pages
    $("#questions").empty();
    $("#screener-container .loader").removeClass("show-loader");
    //If we got a redirect url, go there. Otherwise, show THank you page and redirect to acuranhealth
    const callId = data.fullAcPayload.m_screening.m_state.m_lCallId;

    // this unsubscribes all communications for this email regardless of call id
    unsubscribeEmailAddress();

    //Remove question history
    // for ppd they requested a faster load
    if (ppdFlag) {
      $.ajax({
        type: "POST",
        url: `${apiUrl}/QuestionHistory/CompleteScreener`,
        data: { tokenId: sessionStorage.getItem("TID"), callId: callId, patientId: sessionStorage.getItem('patientID'), currentPage: currentPage },

      });

      callGoogleAnalytics("send", {
        hitType: "event",
        eventCategory: `${accountType} account`,
        eventAction: "Last question reached"
      });

      // ppd specifically asked to speed up so dont wait for google
      if (Object.keys(data.fullAcPayload.m_screening.m_answers.m_map).includes('QSC9004')) {
        window.location = 'https://meandppd.com/thank-you-1';
      }
      else {
        window.location = 'https://meandppd.com/thank-you-2';
      }
    }
    else {
      $.ajax({
        type: "POST",
        url: `${apiUrl}/QuestionHistory/CompleteScreener`,
        data: { tokenId: sessionStorage.getItem("TID"), callId: callId, patientId: sessionStorage.getItem('patientID'), currentPage: currentPage },
        complete: function () {
          if (redirectUrl) {
            callGoogleAnalytics("send", {
              hitType: "event",
              eventCategory: `${accountType} account`,
              eventAction: "Last question reached",
              hitCallback: function () {
                window.location = m_screening.m_type === "CALLCENTER" ? "/callcenter/dnis" : data["fullAcPayload"]["m_cpaEndLink"]
              }
            });
          } else {
            callGoogleAnalytics("send", {
              hitType: "event",
              eventCategory: `${accountType} account`,
              eventAction: "Last question reached",
              hitCallback: function () {
                setTimeout(() => {
                  if (m_screening.m_type === "CALLCENTER") {
                    window.location.href = "/callcenter/dnis"
                  }
                }, 2500);
              }
            });
          }
        }
      });
    }

    const endingText = document.createElement("h4");
    endingText.innerHTML = getTranslatedLanguageString('languageStrings', data.fullAcPayload.m_screening.m_locale, 't_thankYouEndPage') || "Thank you";
    $("#questions-form").append(endingText);

    $("#previous").hide();
    $("#submit").hide();
    hideFixedButtonBar(true);
    $("#questions-form").show();
  }
  return isEndOfScreener;
}

const osanoLangMapping = {
  en_GB: 'en-gb',
  zh_HK: 'zh-hk',
  pt_BR: 'pt-br',
  iw: 'he',
  af: 'en',
  et: 'en',
  // hr: 'en',
  // lt: 'en',
  // lv: 'en',
}
const osanoVariant = {
  one: ['AU', 'CA', 'JP', 'RU', 'KR', 'CH'],
  two: ['AT', 'BE', 'CZ', 'DK', 'EE', 'FI', 'DE', 'GR', 'IE', 'IT', 'LU', 'MC', 'NL', 'PT', 'SA', 'ES', 'TH', 'AE', 'GB'],
  three: ['BR',
    'US' // Since we don't use 'Opt Out Timer mode' for US
  ],
  four: [],
  five: ['BG', 'HR', 'CY', 'HU', 'IS', 'LV', 'LI', 'LT', 'MT', 'NO', 'PL', 'RO', 'SK', 'SI', 'SE'],
  six: ['CN', 'FR']
}
const getOsanoVariant = (country) => {
  for (let x = 0; x < Object.keys(osanoVariant).length; x++) {
    const variant = Object.keys(osanoVariant)[x];
    if (osanoVariant[variant].includes(country)) {
      return variant
    }
  }
  return 'one'
}
const createOsanoTag = () => {
  const locale = curLocale.split('_')[0]
  const country = curLocale.split('_')[1]
  if (screenerType !== "CALLCENTER") {
    const selectedLang = osanoLangMapping[curLocale] || osanoLangMapping[locale] || locale
    const selectedVariant = getOsanoVariant(country)
    osanoScriptUrlLocal = `${osanoScriptUrl}?variant=${selectedVariant}&language=${selectedLang}`
    const isOsanoScriptExists = document.querySelectorAll(`script[src="${osanoScriptUrlLocal}"]`).length
    if (!isOsanoScriptExists) {
      $('head').append(`<script src="${osanoScriptUrlLocal}"></script>`)
    }
  }
}

function checkIfAutosubmitQuestion(data, sessionId) {
  if (data["m_basis"] == "QBSEARCH" || data["m_basis"] == "STUDY_SWITCH") {
    if (data["m_basis"] == "STUDY_SWITCH") {
      //If study swithc, reset the progress email sent flag
      recoverProgressEmailSent = false;
      if( sessionId === null){
        userEmail = undefined;
        userPhone = undefined;
        }
      userConsentedEmailRecoverProgress = true;
    }

    disableBottomBarButtons(true);

    //If autosubmit question, hide buttons
    $("#submit").hide();
    $("#previous").hide();

    //request next question after a few seconds
    setTimeout(function () {
      submitData(true);
    }, 4000);

    return true;
  }
  return false;
}

function handleErrors(data) {
  let errorsExist = false;
  if (data["m_errors"].length > 0) {
    errorsExist = true;
  }

  //will scroll to the first error
  let errorMessageToScrollTo;

  if (errorsExist) {
    for (let error of data["m_errors"]) {
      //create a new error message
      let newError = $("#error-message").clone();
      newError.attr("id", "--error" + error["m_sField"]);

      //find div to append to
      if (error["m_sField"]) {
        newError.appendTo($("#" + error["m_sField"]));
      }

      //set text and show
      newError.find(".error-message").html(error["m_sTranslatedMessage"]);
      newError.fadeIn(200);

      if (errorMessageToScrollTo === undefined) {
        errorMessageToScrollTo = newError;
      }

      // if (errorMessageToScrollTo !== undefined) {
      if (error == data["m_errors"][0]) {
        scrollToElement(errorMessageToScrollTo);
      }
    }
  } else {
    $($('div[id^="--error"]')).remove();
  }

  return errorsExist;
}

function fetchPayload(tokenId, callId) {
  let endpoint =
    apiUrl +
    "/questionHistory/recoverProgressGuest?tid=" +
    tokenId +
    "&userType=" +
    sessionStorage.getItem("userType") +
    "&callId=" +
    callId;

  //if "&patient_id" is in the URL generated by the BE,
  //and &dropOffEmailRequest=true
  //we need to save two cookies for later
  const urlParams = new URLSearchParams(window.location.search);
  const patientId = urlParams.get('orig_patient_id')
  const dropOffEmailRequest = urlParams.get('dropOffEmailRequest')
  setCookie('dropOffEmailRequest', dropOffEmailRequest + '; secure')

  if (dropOffEmailRequest === 'true') {
    setCookie('orig_patient_id', patientId + '; secure');
  }

  if (patientId) {
    sessionStorage.setItem('patientID', patientId);
  }

  let origin = $.urlParamFromString(sessionStorage["originalURL"], "origin");
  if (origin) {
    //user has recovered progress via an email reminder
    endpoint += `&origin=${origin}`;
  }

  const translationMapEndpoint = `${apiUrl}/questionHistory/goToQuestion?tid=${tokenId}&userType=${sessionStorage.getItem(
    "userType"
  )}&pageNumber=1&callId=${callId}&recoverProgress=true`;

  return $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    method: "GET",
    success: function (data) {

      const parsedData = JSON.parse(data);

      //Assume that the email was sent if a session has been recovered
      recoverProgressEmailSent = sessionStorage["callId"] ? false : true;

      // get locale if available
      if (parsedData.fullAcPayload['m_screening']['m_locale']) {
        curLocale = parsedData.fullAcPayload['m_screening']['m_locale'];
        screenerType = parsedData.fullAcPayload.m_screening.m_type
        createOsanoTag()
      }

      // set answers from previous session
      let localAnswerMapObj = JSON.parse(sessionStorage.getItem("local.m_map")) || {};
      localAnswerMapObj.test = { answer: 'test question' };
      sessionStorage.setItem("local.m_map", JSON.stringify(localAnswerMapObj));
      localAnswerMapObjGlobal = localAnswerMapObj;

      // get zip and email if answered
      if (parsedData.fullAcPayload.m_screening.m_answers && parsedData.fullAcPayload.m_screening.m_answers.m_map) {
        for (let i in parsedData.fullAcPayload.m_screening.m_answers.m_map) {
          if (parsedData.fullAcPayload.m_screening.m_answers.m_map[i].m_answerbasis == 'ZIP') {
            sessionStorage.setItem("postcode", parsedData.fullAcPayload.m_screening.m_answers.m_map[i].m_sRawAnswer);
          }
          else if (parsedData.fullAcPayload.m_screening.m_answers.m_map[i].m_answerbasis == 'EMAIL') {
            userEmail = parsedData.fullAcPayload.m_screening.m_answers.m_map[i].m_sRawAnswer;
            sessionStorage.setItem('userEmail', userEmail);
          }
          // Add elseif from m_map[i] where m_answerbasis == 'DAY_PHONE'
          else if (parsedData.fullAcPayload.m_screening.m_answers.m_map[i].m_answerbasis == 'DAY_PHONE') {
            userPhone = parsedData.fullAcPayload.m_screening.m_answers.m_map[i].m_sRawAnswer;
            sessionStorage.setItem('userPhone', userPhone);
          }
        }
      }

      if (
        !checkIfCookieExists("patient") &&
        sessionStorage.getItem("userType") == "guest" &&
        sessionStorage.getItem("saveGuestSession") == "true"
      ) {
        setCookie("patient", sessionStorage.getItem("patientID") + '; secure');
      }
      if (
        !checkIfCookieExists("tid") &&
        sessionStorage.getItem("userType") == "guest" &&
        sessionStorage.getItem("saveGuestSession") == "true"
      ) {
        setCookie("tid", sessionStorage.getItem("TID") + '; secure');
        setCookie("token", sessionStorage.getItem("token") + '; secure');
      }

      //Get the translationMap data obj that would have been saved from the first question
      $.ajax({
        url: translationMapEndpoint,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`
        },
        method: "GET",
        async: false,
        success: function (data) {
          let parsedData = JSON.parse(data); //not ideal, because loadData also parses the payload
          translationMap = parsedData.fullAcPayload.m_appTranslationMap;
          addValidation(parsedData.fullAcPayload.m_screening.m_locale);

          const themeObject = parsedData.fullAcPayload.m_screening.m_studyCampaign.m_Theme;
          if (themeObject && themeObject.m_sInternalTheme) {
            const themeDataArr = themeObject.m_sInternalTheme.split("|");
            theme = themeDataArr[0];

            if (themeDataArr.length === 2) {
              category = themeDataArr[1];
            }
          }
          setTheme(parsedData.fullAcPayload.m_screening.m_locale, theme, category);
          $("#fullscreenLoader").fadeOut(1000);
        }
      });

      //Load data received into page for next question
      $("#previous").css("display", "block"); //show previous button as we are loading an existent session that has passed the first question
      $("#bottom-bar-previous").show();
      loadData(data);

      setTranslatedContent();
    },
    error: (error, textStatus, errorThrown) => {
      const statusCode = error.status;
      const errorJSON = error.responseJSON;

      // store the phone number for resarting same questionnaire
      if (errorJSON && errorJSON.data && errorJSON.data.phoneNumber) {
        sessionStorage.setItem('previousScreenerPhoneNumber', errorJSON.data.phoneNumber);
      }

      callGoogleAnalytics("send", "exception", {
        exDescription: `Recover progress request fail ${statusCode} - ${textStatus} (${endpoint})`,
        exFatal: false
      });

      $("#fullscreenLoader").fadeOut(1000);

      if (statusCode === 400) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.API_GENERIC, $('#questions-form'));
        }
        else {
          handleUserErrors(ErrorTypes.PROGRESS_RECOVERY_COMPLETED);
        }
      }
      else if (statusCode === 401) {
        // if screener version changed
        if (errorJSON && errorJSON.data && errorJSON.data.revisionChanged) {
          // the patient will be restarting the screener if they continue and will have a new pid
          // save the old one so that when they reach the setup of recovery email updateRecoveryEmailForNewPID will ne called
          const patID = sessionStorage.getItem('patientID');

          if (patID) {
            localStorage.setItem('previousRevisionPID', patID);
          }

          handleUserErrors(ErrorTypes.REVISION_CHANGED);
          $('#fatalErrorContainer').addClass('px-4');
        }
        else if (sessionStorage["userType"] === "cc") {
          //relocate user to gfq
          handleUserErrorsCC(ErrorTypesCC.TOKEN_EXPIRED, $('#questions-form'));
        }
        else {
          handleUserErrors(ErrorTypes.TOKEN_EXPIRED);
        }
      }
      else if (statusCode === 403) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.UNAUTHORISED, $('#questions-form'));
        }
        else {
          handleUserErrors(ErrorTypes.TOKEN_INVALID);
        }
      }
      else {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.API_GENERIC, $('#questions-form'));
        }
        else {
          handleUserErrors(ErrorTypes.API_GENERIC);
        }
      }
    }
  });
}

function goToQuestion(pageNumber = currentPage - 1) {
  hideSSHelpBtn();
  scrollTo(0, "slow");

  $(".tooltip").remove();

  //Fade out animation and reset of elements
  questionOutAnimation();

  // change image at top of page
  returnRandomImage();

  //Make request:
  const endpoint = `${apiUrl}/questionHistory/goToQuestion?tid=${sessionStorage.getItem(
    "TID"
  )}&userType=${sessionStorage.getItem("userType")}&pageNumber=${pageNumber.toString()}&callId=${m_screening.m_state.m_lCallId
    }`; // always pass previous for now

  $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    method: "GET",
    success: function (data) {
      removeUserError(); //should the error content be present, hide this
      if (isEmailQuestion) {
        isSignInAllowed = true
      } else {
        isSignInAllowed = false
      }

      renderAuthBanner()

      let parsedData = JSON.parse(data);
      const parsedDataPatientId = parsedData["fullAcPayload"]["m_screening"]["m_state"]["m_lPatientId"]
      currentPatientId = parsedDataPatientId
      sessionStorage.setItem("patientID", parsedDataPatientId);
      //Load questions
      finishQuestionOutAnimation(); //finish animation in case request was faster
      loadData(data); //this needs to change as load data parses json again

      //automatically select answers from the answers part of the response
      autoPopulateAnswers(parsedData.answers.questions);

      //Check validation for all required questions answered after answer autopopulation
      checkIfAllRequiredQuestionsAreAnswered();

      //translate new dom content
      setTranslatedContent();
    },
    error: (error, textStatus, errorThrown) => {
      const statusCode = error.status;
      callGoogleAnalytics("send", "exception", {
        exDescription: `Screener back button request fail ${statusCode} for tid ${sessionStorage.getItem(
          "TID"
        )} - ${textStatus} (${endpoint})`,
        exFatal: false
      });

      if (statusCode === 401) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.TOKEN_EXPIRED, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.TOKEN_EXPIRED);
        }
      } else if (statusCode === 403) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.UNAUTHORISED, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.TOKEN_INVALID);
        }
      } else {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.API_GENERIC, $('#questions-form'));
        } else {
          if (currentPage > 1) {
            handleUserErrors(ErrorTypes.GNQ_GENERIC);
          } else {
            handleUserErrors(ErrorTypes.API_GENERIC);
          }
        }
      }
    }
  });
}

function autoPopulateAnswers(questionsWithAnswersData) {
  //First check if it is an autosubmit question and don't autopopulate if so
  if ($(".autosubmit").length > 0) {
    return;
  }
  //Get all existent question
  var allQuestions = document.getElementsByClassName("question-answers-container");

  //For each existent question on the page, check if there are answers
  let matchingAnswer;
  let answerType;
  for (let i = 0; i < allQuestions.length; i++) {
    //Loop through all question elements on page
    //Check if this question element has answer elements
    if (allQuestions[i].getElementsByClassName("answers-container").length > 0) {
      //Set answer type to know what type of elements we are dealing with
      answerType = allQuestions[i].dataset.answerType;

      //Find the data that matches this element
      //For each answers for each question we got from the API
      for (let n = 0; n < questionsWithAnswersData.length; n++) {
        //Check if this set of data matches this question element ID
        if (allQuestions[i].id == questionsWithAnswersData[n].questionUniqueId && questionsWithAnswersData[n].answers) {
          //If answers found for this questions unique code and answers not null, select this answer
          matchingAnswer = questionsWithAnswersData[n];
        } else {
          //If not, check next answers data item
          continue;
        }

        //Do something depemnding on answer type
        switch (answerType) {
          case "TEXT_DATE":
          case "TEXT_MM_YYYY":
          case "TEXT_YEAR":
          case "TEXT_NUMBER":
          case "TEXT":
          case "TEXT_ZIP":
          case "TEXT_PHONE":
          case "TEXT_HEIGHT":
          case "TEXT_WEIGHT":
          case "GPAUTO":
            autopopulateInputFields(allQuestions[i], matchingAnswer.answers, answerType);
            break;
          case "EMAIL":
            autopopulateInputFields(allQuestions[i], matchingAnswer.answers, answerType);
            break;
          case "DROPDOWN":
            autopopulateDropdowns(allQuestions[i], matchingAnswer.answers);
            break;
          case "TEXT_AREA":
            autopopulateTextArea(allQuestions[i], matchingAnswer.answers);
          case "RADIO":
          case "PROTODD":
          case "STUDYSWPR":
            autopopulateSingleChoice(allQuestions[i], matchingAnswer.answers);
            break;
          case "CHECKBOX":
          case "INDICATION_LIST":
            autopopulateMultipleChoice(allQuestions[i], matchingAnswer.answers);
            break;
          case "DUAL_HEIGHT":
            let heightMetric = ["cm"];
            if (matchingAnswer.m_bMetric == "false") {
              heightMetric[0] = "ftIn";
            }
            $(".height-inputs-container .form-row#cm").removeClass("selected");
            $(".height-inputs-container .form-row#ftIn").removeClass("selected");
            $(".height-inputs-container .form-row#" + heightMetric[0]).addClass("selected");
            autopopulateDropdowns(allQuestions[i], heightMetric);
            autopopulateInputFields(allQuestions[i], matchingAnswer.answers, answerType);
            break;
          case "DUAL_WEIGHT":
            let weightMetric = ["kg"];
            if (matchingAnswer.m_bMetric == "false") {
              weightMetric[0] = "lbs";
            }
            $(".weight-inputs-container .form-row#kg").removeClass("selected");
            $(".weight-inputs-container .form-row#lbs").removeClass("selected");
            $(".weight-inputs-container .form-row#" + weightMetric[0]).addClass("selected");
            autopopulateDropdowns(allQuestions[i], weightMetric);
            autopopulateInputFields(allQuestions[i], matchingAnswer.answers), answerType;
            break;
          case "SITE_SELECTION":
            autopopulateSiteSelection(allQuestions[i], matchingAnswer.answers);
            break;
          case "PROTOLIST":
            autopopulateMultipleChoice(allQuestions[i], matchingAnswer.answers);
            break;
          case "SITEDISPL":
          case "STUDYSEL":
            autopopulateSingleChoice(allQuestions[i], matchingAnswer.answers);
            break;
        }
      }
    }
  }
}

function autopopulateInputFields(questionElement, answersData, answerType) {
  //Loop through all inputs if any
  let allInputs = $(questionElement).find(".answers-container input:visible");
  if (allInputs != undefined) {
    for (let i2 = 0; i2 < allInputs.length; i2++) {
      //It should always be just one input element, but in case it is multiple, check if there are as many answers as input fields
      if (i2 < answersData.length) {
        //If it's type date, need to parse date
        if (answerType == "TEXT_DATE") {
          allInputs[i2].value = formatDateToLocale(answersData, answerType);
          populateDateField(allInputs[i2]);
        } else if (answerType == "TEXT_MM_YYYY") {
          allInputs[i2].value = formatDateToLocale(answersData, answerType);
          populateDateField(allInputs[i2]);
        } else if (answerType == "GPAUTO") {
          const splitGPAutoData = answersData[i2].split('|||');
          // Override global lat/long variables
          screeningGLatitude = splitGPAutoData[0];
          screeningGLongitude = splitGPAutoData[1];
          allInputs[i2].value = splitGPAutoData[2];
        } else {
          allInputs[i2].value = answersData[i2];
        }
      }
    }
  }
}

function autopopulateDropdowns(questionElement, answersData) {
  //Loop through all dropdowns if any
  let allDropdowns = questionElement.getElementsByClassName("answers-container")[0].getElementsByTagName("select");
  if (allDropdowns != undefined) {
    for (let i2 = 0; i2 < allDropdowns.length; i2++) {
      //It should always be just one input element, but in case it is multiple, check if there are as many answers as input fields
      if (i2 < answersData.length) {
        allDropdowns[i2].value = answersData[i2];
      }
    }
  }
}

function autopopulateTextArea(questionElement, answersData) {
  //Loop through all inputs if any
  let allInputs = $(questionElement).find(".answers-container textarea:visible");
  if (allInputs != undefined) {
    for (let i2 = 0; i2 < allInputs.length; i2++) {
      //It should always be just one input element, but in case it is multiple, check if there are as many answers as input fields
      if (i2 < answersData.length) {
        allInputs[i2].value = answersData[i2];
      }
    }
  }
}

function autopopulateSingleChoice(questionElement, answersData) {
  //Check for input of type single choice
  let allSingleChoice = questionElement.getElementsByClassName("single-choice-answers-container");
  //Loop through all containers for this question, if any (it should always be only one in reality)
  for (let i2 = 0; i2 < allSingleChoice.length; i2++) {
    if (answersData.length > i2) {
      //check we have an answer for this container. Assume if multiple containers, and answersData > 1, item 1 in answersData = container 1 and so on
      let allChoices = allSingleChoice[i2].getElementsByClassName("answer-option-container");

      for (let i3 = 0; i3 < allChoices.length; i3++) {
        if (allChoices[i3].value == answersData[i2]) {
          onAnswerOptionSingleChoiceClicked(allChoices[i3], allSingleChoice[i2]);
          break;
        }
      }
    }
  }
}

function autopopulateMultipleChoice(questionElement, answersData) {
  //Check for input of type multiple choice
  let allMultipleChoice = questionElement.getElementsByClassName("multiple-choice-answers-container");
  //Loop through all containers for this question, if any (it should always be only one)
  for (let i2 = 0; i2 < allMultipleChoice.length; i2++) {
    let allChoices = allMultipleChoice[i2].getElementsByClassName("answer-option-container");
    for (let i3 = 0; i3 < allChoices.length; i3++) {
      if (answersData.includes(allChoices[i3].value))
        onAnswerOptionMultipleChoiceClicked(allChoices[i3], allMultipleChoice[i2]);
    }
  }
}

function autopopulateSiteSelection(questionElement, answersData) {
  let markers = siteSelectionLocations;
  let choiceSelectionContainer = $(questionElement)
    .find(`input[value="${answersData}"]`)
    .closest(".choiceSelectionContainer");

  const markerKey = choiceSelectionContainer.attr("data-location-key");

  if (markerKey) {
    // select only the matching one
    $(".choiceSelectionContainer").removeClass("selected");
    choiceSelectionContainer.addClass("selected");

    if (markerKey && window.map) {
      selectMapLocation(markers[markerKey]["lat"], markers[markerKey]["lng"]);
    }
  }
}
function clearIframesFromPixelsContainer() {
  const pixelsContainer = document.getElementById('tracking-pixels-container')
  pixelsContainer.querySelectorAll('iframe').forEach(iframe => iframe.remove());
}
function submitData(studySearchQuestion = false) {
  // hide the help for site selection
  hideSSHelp();
  hideSSHelpBtn();

  let stringJson = createJsonPayload();
  //If returned value was null, user didn't provide required answers
  if (stringJson == null) {
    //alert("Please provide an answer");
    return;
  }

  //Fade out animation and reset of elements
  scrollTo(0, "slow");
  questionOutAnimation(studySearchQuestion);

  //Disable action buttons while loading next question
  disableBottomBarButtons(true);

  let token = sessionStorage.getItem("token");
  let ptid = sessionStorage.getItem("ptid");
  let endpoint =
    apiUrl +
    "/questions/getNextQuestion?tid=" +
    sessionStorage.getItem("TID") +
    "&userType=" +
    sessionStorage.getItem("userType") +
    "&currentPage=" +
    currentPage +
    "&callId=" +
    m_screening.m_state.m_lCallId;
  if (ptid != null) {
    endpoint =
      apiUrl +
      "/questions/getNextQuestion?tid=" +
      ptid +
      "&userType=" +
      sessionStorage.getItem("userType") +
      "&currentPage=" +
      currentPage;
  }

  stringJson['usedProtos'] = sessionStorage.getItem("usedProtos");
  const account = myMSALObj.getAllAccounts()[0]
  if (patientProfileId && account) {
    if (isCurrentQuestionPII) {
      updateUserPIIData(stringJson)
    } else {
      updateGenericQuestion(stringJson)
    }
  }

  if (currentSites_m_Choices != null) {
    stringJson.siteChoices = currentSites_m_Choices;
  }

  if (selectedSiteFacilityCD) {
    stringJson.selectedSiteFacilityCD = selectedSiteFacilityCD;
  }

  if (selectedSiteFacilityGroupId) {
    const siteSelection = stringJson.questions[0].answers[0];
    if (siteSelection !== null && siteSelection !== undefined && siteSelection.toLowerCase() !== 'none' && siteSelection.toLowerCase() !== 'nosites') {
      // Remove 'return' as you may want to also process picking a site below
      window.dataLayer.push(buildCustomEventPayload(stringJson.questions[0].questionUniqueId, PICKED_SITE_ON_SITESEL_QUESTION));
      selectedSiteFacilityGroupId = null;
    }
  }

  //SAVE A LOCAL VERSION OF M_MAP WHERE WE HAVE ANSWERS SUBMITTED APPLICATION SIDE.
  let localAnswerMapObj = JSON.parse(sessionStorage.getItem("local.m_map"));

  if (!localAnswerMapObj) {
    localAnswerMapObj = {};
    sessionStorage.setItem("local.m_map", '{}');
  }

  for (let ansObj of stringJson.questions) {
    localAnswerMapObj[ansObj.questionUniqueId] = ansObj;
  }

  sessionStorage.setItem("local.m_map", JSON.stringify(localAnswerMapObj));
  localAnswerMapObjGlobal = localAnswerMapObj;  //global scope for later reference
  
  //Get next question
  $.ajax({
    url: endpoint,
    headers: {
      Authorization: `Bearer ${token}`
    },
    method: "POST",
    data: stringJson,
    timeout: 200000,
    success: function (data) {
      if (sessionStorage["userType"] !== "cc" && userEmail && userConsentedEmailRecoverProgress && !recoverProgressEmailSent) {
        initialiseProgressRecoveryEmail();
      }
      if (isEmailQuestion) {
        isSignInAllowed = true
      } else {
        isSignInAllowed = false
      }
      renderAuthBanner();
      clearIframesFromPixelsContainer()
      // call the api to set the study that the user has qualified for...this is then used by BI in site scheduler
      sendQualifiedStudyToAPI()

      const parsedData = JSON.parse(data);
      const parsedDataPatientId = parsedData["fullAcPayload"]["m_screening"]["m_state"]["m_lPatientId"]
      currentPatientId = parsedDataPatientId
      sessionStorage.setItem("patientID", parsedDataPatientId);

      const beforeStudySwitchPatientId = sessionStorage.getItem("beforeSwitchPatientId");
      if (beforeStudySwitchPatientId != currentPatientId && m_screening.m_state.sessionId != null) {
        recoverProgressEmailSent = false;
        sessionStorage.setItem("beforeSwitchPatientId", parsedDataPatientId); 
      }
      // get locale if available
      if (parsedData.fullAcPayload['m_screening']['m_locale']) {
        curLocale = parsedData.fullAcPayload['m_screening']['m_locale'];
      }

      //Load data received into page for next question

      //Load data received into page for next question
      finishQuestionOutAnimation(); //Finish animation first in case request was faster than animation
      loadData(data, studySearchQuestion);

      // patient id isnt always retrieved on first question so if we dont have one then check if this payload has one
      if (!sessionStorage.getItem('patientID') || sessionStorage.getItem('patientID') == '-1') {
        if (m_screening["m_state"]["m_lPatientId"]) {
          sessionStorage.setItem('patientID', m_screening["m_state"]["m_lPatientId"]);
        }
      }

      if (!getNextQuestionEventFired) {
        //GA event never fired before
        callGoogleAnalytics("send", {
          hitType: "event",
          eventCategory: `${getUserAccountType(true)} account`,
          eventAction: "Get next question"
        });

        getNextQuestionEventFired = true;
      }

      setTranslatedContent(); //GNQ

      //Forward auto-population (if we already have the answer saved)
      // JD - added this if in because in OUS screeners it was populating the consent answers in getAnswerFromGetPayload then getting wiped again
      // when going through forwardAutoPopulation, autoPopulateAnswers, onAnswerOptionMultipleChoiceClicked
      if (!parsedData?.fullAcPayload?.m_screen?.m_question?.m_basis == 'QUALIFIED_PII') {
        forwardAutoPopulation();
      }
    },
    error: (error, textStatus, errorThrown) => {
      const statusCode = error.status;
      callGoogleAnalytics("send", "exception", {
        exDescription: `Submit answer request fail ${statusCode} - ${textStatus} - ${errorThrown} - (${endpoint})`,
        exFatal: false
      });

      if (statusCode === 401) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.TOKEN_EXPIRED, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.TOKEN_EXPIRED);
        }
      } else if (statusCode === 403) {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.UNAUTHORISED, $('#questions-form'));
        } else {
          handleUserErrors(ErrorTypes.TOKEN_INVALID);
        }
      } else {
        if (sessionStorage["userType"] === "cc") {
          handleUserErrorsCC(ErrorTypesCC.API_GENERIC, $('#questions-form'));
        } else {
          if (currentPage > 1) {
            handleUserErrors(ErrorTypes.GNQ_GENERIC);
          } else {
            handleUserErrors(ErrorTypes.API_GENERIC);
          }
        }
      }
    }
  });
}

function forwardAutoPopulation() {
  //auto population of input fields when going FORWARDS through pages. If we already have a submitted answer pre-populate that value
  const localAnswerMapObj = localAnswerMapObjGlobal;  //references global map
  const savedAnswerKeys = Object.keys(localAnswerMapObj);
  const autoPopulateObj = [];
  $('#question-container #questions > div').each(function (index) {
    const questionId = $(this).attr('id');
    const matchedKeyIndex = savedAnswerKeys.findIndex(answerKey => localAnswerMapObj[answerKey].questionUniqueId === questionId);  //a key found already for a saved answer
    if (matchedKeyIndex >= 0) {
      //build an object that's compatible for 'autoPopulateAnswers'
      const matchedAnswerObj = localAnswerMapObj[savedAnswerKeys[matchedKeyIndex]];
      autoPopulateObj.push({
        questionUniqueId: matchedAnswerObj.questionUniqueId,
        answers: matchedAnswerObj.answers,
        m_bMetric: String(matchedAnswerObj.m_bMetric)
      });
    }
  });

  if (autoPopulateObj.length > 0) {
    autoPopulateAnswers(autoPopulateObj);
  }

  //Check validation for all required questions answered after answer autopopulation
  checkIfAllRequiredQuestionsAreAnswered();
}


function initialiseMap() {
  let markers = siteSelectionLocations;
  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer();

  // when clicking a site card - Add a marker at the center of the map.
  $(".choiceSelectionContainer").click(e => {
    const markerKey = $(e.currentTarget).attr("data-location-key");
    $(".choiceSelectionContainer").removeClass("selected");
    $(e.currentTarget).addClass("selected");

    if (markerKey && window.map) {
      selectMapLocation(markers[markerKey]["lat"], markers[markerKey]["lng"]);

      // show directions
      const patientLat = m_screening["m_geocode"]["m_dLatitude"];
      const patientLong = m_screening["m_geocode"]["m_dLongitude"];

      if (mapRouteOrigin && mapRouteOrigin.lat && mapRouteOrigin.lng && markers[markerKey] && markers[markerKey]['lat'] && markers[markerKey]['lng']) {
        calculateAndDisplayRoute(directionsService, directionsRenderer, mapRouteOrigin, { lat: markers[markerKey]["lat"], lng: markers[markerKey]["lng"] });
      }
    }
  });

  window.map = new google.maps.Map(document.getElementById("mapArea"), {
    zoom: 12,
    center: { lat: markers[0]["lat"], lng: markers[0]["lng"] },
    controlsSize: 5
  });

  directionsRenderer.setMap(map);

  //Adds a marker to the map.
  function addMarker(location, title, label, customIcon = "") {
    new google.maps.Marker({
      position: location,
      map: map,
      animation: google.maps.Animation.DROP,
      title: title,
      label: label,
      icon: customIcon
    });
  }

  markers.forEach(data => {
    const latLong = { lat: data.lat, lng: data.lng };
    addMarker(latLong, data.title, data.label, data.icon);
  });

  let countryCode = null;

  if (curLocale) {
    countryCode = curLocale.split('_')[1];
  }

  // search box

  const searchOptions = {
    origin: window.map.getCenter(),
    componentRestrictions: {
      country: countryCode.toLowerCase()
    },
    language: 'en' //  this seems to actually do nothing even after reading up on it
  };
  // Create the search box and link it to the UI element.
  const input = document.getElementById("pac-input");

  // if using search box not places - results seems similar tbh, places did initially seem more like local places rather than addresses but seems to work the same now
  const searchBox = new google.maps.places.Autocomplete(input, searchOptions);
  searchBox.setComponentRestrictions({
    country: countryCode.toLowerCase()
  });
  // Bias the SearchBox results towards current map's viewport.
  map.addListener("bounds_changed", () => {
    searchBox.setBounds(map.getBounds());
  });

  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener("place_changed", () => {
    const place = searchBox.getPlace();

    if (place.length == 0) {
      return;
    }

    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();


    if (!place.geometry || !place.geometry.location) {
      console.log("Returned place contains no geometry");
      return;
    }

    if (place.geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    map.fitBounds(bounds);

    //site selection processing with selected place
    ssPlaceSelected(place);
  });

  // route to the first site by default - [0] is th epatients home or searched place
  if (mapRouteOrigin && mapRouteOrigin.lat && mapRouteOrigin.lng && markers[1] && markers[0]['lat'] && markers[0]['lng']) {
    calculateAndDisplayRoute(directionsService, directionsRenderer, mapRouteOrigin, { lat: markers[0]["lat"], lng: markers[0]["lng"] });
  }
}

function selectMapLocation(lati, long) {
  const latLng = new google.maps.LatLng(lati, long); //Makes a latlng
  map.panTo(latLng); //Make map global
}
function calculateAndDisplayRoute(directionsService, directionsRenderer, origin, destination) {
  var request = {
    origin: new google.maps.LatLng(origin.lat, origin.lng),
    destination: new google.maps.LatLng(destination.lat, destination.lng),
    travelMode: google.maps.DirectionsTravelMode.DRIVING
  };
  directionsService.route(request, function (response, status) {
    if (status == google.maps.DirectionsStatus.OK) {
      directionsRenderer.setDirections(response);
    }
  });
}

var esignOnMessageAction = function (e) {
  if (
    (e.data && e.data.includes("invalid or expired")) ||
    e.data.includes("signature_request_signed") ||
    e.data["type"] === "hellosign:userCancelRequest"
  ) {
    if (decodeURI(e.data).includes("invalid or expired")) {
      HelloSign.close();
      $(".question-text").append("Invalid document link or document has expired.");
    } else if (decodeURI(e.data).includes("signature_request_signed")) {
      //signed event passed back
      esignResponse = "STATUS_SIGNED";
      $("#esignValue").val(esignResponse);
      HelloSign.close();
      $("#questions-form").submit(); //SUBMIT TO NEXT QUESTION
      const eventMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
      window[eventMethod]("message", esignOnMessageAction, false);
    } else if (typeof e.data === "object" && e.data["type"] === "hellosign:userCancelRequest") {
      //Only want to check the object messages received. Others are strings
      esignResponse = "STATUS_CANCELED";
      $("#esignValue").val(esignResponse);
      $("#questions-form").submit(); //SUBMIT TO NEXT QUESTION
    }
  }
};

function initialiseEsign(eSignUrl, clientId) {
  HelloSign.init(`${clientId}`);
  HelloSign.open({
    url: `${eSignUrl}`,
    allowCancel: true,
    skipDomainVerification: (ENV.toLowerCase() === 'prod' ? false : true),
    messageListener: function (eventData) {
      parent.postMessage(`EventData: ${eventData}`, `${window.location.origin}`);
    }
  });

  if (esignInitiated) {
    return true; //stop here if esign has already been intitiated (dont set several message listeners)
  }
  esignInitiated = true;

  const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  const eventer = window[eventMethod];
  const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  // Listen to message from child window
  eventer(messageEvent, esignOnMessageAction);
}

var chartfillOnMessageAction = function (e) {
  const result = e.data["Result"].toUpperCase();
  if (result === "SIGNED" || result === "CANCELED" || result === "REJECTED" || result === "DELEGATED") {
    let status = "";
    switch (
    result //CHECK origin later
    ) {
      case "SIGNED":
        status = "SIGNED";
        break;
      case "CANCELED":
        status = "CANCELED";
        break;
      case "REJECTED":
        status = "REJECTED";
        break;
      case "DELEGATED":
        status = "DELEGATED";
        break;
      default:
        //unknown state
        status = "UNKNOWN_STATE";
    }

    chartfillResponse = status;
    $("#chartfillValue").val(chartfillResponse);
    $("#chartfillOverlay").remove();
    $("#chartfillSpinner").remove();
    $("#chartfill-iframe-container").remove();
    $(".button-block").show();
    $("#questions-form").submit(); //advance to next question

    const eventMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
    window[eventMethod]("message", chartfillOnMessageAction, false);
  }
};

function initialiseChartfill() {
  let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  let eventer = window[eventMethod];
  let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  eventer(messageEvent, chartfillOnMessageAction);
}

//Creates elements for questions and answers
function createAnswers(answerType, elementToAppendTo, questionData, belongsToPII = false) {
  let questionsContainer = document.createElement("div");
  questionsContainer.className = "answers-container";
  let createdElement = getElementForAnswer(answerType, questionData, belongsToPII);
  questionsContainer.appendChild(createdElement);
  elementToAppendTo.appendChild(questionsContainer);

  if (createdElement.type === 'email' && screenerType === 'CALLCENTER' && isCurrentQuestionPII) {
    let elementCreated = document.createElement("div");
    // elementCreated.className = "single-choice-answers-container w-100";
    elementCreated.id = 'callCenterDummyEmail';
    elementCreated.setAttribute("data-required", false);

    elementToAppendTo.appendChild(elementCreated);

    $('#callCenterDummyEmail').html(
      `<input type="checkbox" id="ccEmailCheckBox" style="width: 3%; min-height: 20px;" onClick="addCCNoEmail()" />
      <span>User has no email</span>`);
  
    if (sessionStorage.getItem('isCCEmailCheckBoxChecked') !== null) {
      document.getElementById('ccEmailCheckBox').checked = sessionStorage.getItem('isCCEmailCheckBoxChecked') === 'true' ? true : false;
    } 
  }

  // save this if its for site selection so it can be reset later on
  if (answerType == 'SITE_SELECTION') {
    lastFetchedSiteSelectionData = {
      answerType: answerType,
      elementToAppendTo: elementToAppendTo,
      questionData: questionData,
      belongsToPII: false
    };
  }

  if (createdElement && createdElement.tagName && document.activeElement) {
    if (createdElement.tagName.toLowerCase() === "input" && document.activeElement.tagName.toLowerCase() !== "input") {
      $(createdElement).focus();
    }
  }

  if (answerType === "EMAIL" && userEmail) {
    //we already have the users email, lets pre populate it
    createdElement.value = userEmail;
  }
  if (answerType === "TEXT_PHONE" && userPhone) {
    //we already have the users phone, lets pre populate it
    createdElement.value = userPhone;
  }
  /**
   * Capitalise the first letter of any questions with an m_basis in the array
   * Can add more elements to the array if we require anything else to have
   * the first letter capitalised.
   */
  if (["FIRST_NAME", "LAST_NAME", "CAREGIVER_FIRST_NAME", "CAREGIVER_LAST_NAME"].some(v => v === questionData.m_basis)) {
    $(createdElement).change(() => $(createdElement).val(capitaliseFirst($(createdElement).val())));
  }

  switch (answerType) {
    case "TEXT_DATE": // Add a text field to display the date the user has input in written format
      $(`<div class="col-md-6 col-sm-12 date-display-text" id="dateTextFor-${createdElement.name}"></div>`).insertAfter(
        createdElement
      );
      $(createdElement).on("keyup change", function () {
        // Add a key up event listener, display the written date when a full date is provided
        populateDateField(createdElement);
      });
      break;
    case "SITE_SELECTION":
      initialiseMap();
      break;
    case "ESIGN":
      if (m_screening.m_type !== "CALLCENTER" && questionData["m_sEsign_url"] && m_screening["s_brand_hellosign_client_id"]) {
        if (esignResponse) {
          $("#esignValue").val(esignResponse); //always start by assigning value
        }
        initialiseEsign(questionData["m_sEsign_url"], m_screening["s_brand_hellosign_client_id"]);
      } else {
        $("#esignValue").remove();    //remove hidden dom input element so that the question can be passed when clicking next
      }
      break;
    case "CHARTFILL":
      if (chartfillResponse) {
        $("#chartfill-iframe-container").remove(); //do not display iframe
        $("#chartfillValue").val(chartfillResponse);
      } else {
        initialiseChartfill();
      }
      break;
  }

  var glossaryPopUp = translationMap['glossary.popup.close.button'];
  var isGlossaryEmpty = $('.glossary').attr('title') === '' ? $('.glossary').attr('data-original-title') : $('.glossary').attr('title');
  if(typeof isGlossaryEmpty !== "undefined")
  $('.glossary').addClass('glossary-tooltip-mobile')

  deviceDetails =  detectDevice();
  let isMobilePixel = deviceDetails.isMobile;

  let hideInfo = 'd-none col-2';
  let rowInfo= 'col-lg-12 col-10 col-md-12';
  if(isMobilePixel){
    hideInfo = 'd-inline col-2';
    rowInfo= 'col-lg-10 col-10 col-md-10';
  }

  const txt = `<div class='${hideInfo}'><i class='fa fa-question-circle unique-${currentPage}' aria-hidden='true'></i></div>`;
  //Assuming span is used as glossary in every question
  let qstnData = null;
  let qstnSeconds = null;
  $(".question-text").each(function (){
    if($(this).find(".glossary-tooltip-mobile").length > 0)
        $(this).wrap(`<div class='row hideFaInfo'><div class='${rowInfo}'></div>${txt}</div>`);
  })

  $(".question-text").parents('.row').eq(0).find("i.unique-"+currentPage).click(function (){
    qstnSeconds = 30;
    qstnData = "";
     $(this).parents('.row').eq(0).find('.glossary-tooltip-mobile').each(function (){
      var txtHeading = $(this).html();
      var txtBody =  $(this).attr('title') === '' ? $(this).attr('data-original-title') : $(this).attr('title')
      qstnData = qstnData + "</br><i><b>"+ txtHeading.toUpperCase() +"</b></i> : "+ txtBody+"</br>";
     })

      $("#glossaryTextModalBody-"+currentPage).html(qstnData);
      $("#dismiss-glossary-modal-"+currentPage).html("");
      $("#modalTimer").html("");
      $("#glossaryTextModal-"+currentPage).modal("show");
      $('.modal-backdrop').remove();
      glossaryModalTooltipInterval = setInterval(function () {
        if($("#glossaryTextModalBody-"+currentPage).html() === '')
            $("#glossaryTextModalBody-"+currentPage).html(qstnData);
        if (parseInt(qstnSeconds) <= 0) {
           $("#glossaryTextModal-"+currentPage).modal("hide");
          qstnData = null;
          qstnSeconds = null;
          clearInterval(glossaryModalTooltipInterval);
          // $("#dismiss-glossary-modal").html(`You can close here otherwise it will close in 10 seconds`);
        } else if(qstnSeconds != null){
            $("#dismiss-glossary-modal-"+currentPage).html(`${glossaryPopUp}`);
            $("#modalTimer").html(`${translationMap['glossary.popup.after.close.button'].replace('###' , qstnSeconds)}`)
            qstnSeconds--;
        }else{
          clearAllTimers();
        }
      }, 1000)

    $('#glossaryTextModal-'+currentPage).on("hidden.bs.modal", function () {
      qstnSeconds = null;
      clearInterval(glossaryModalTooltipInterval);
      });
   })


   //While loading glossary class will be there , so using accourding to glossary
   $(".question-text").find(".glossary-tooltip-mobile").each(function(){
    if(window.matchMedia('(max-width: 767px)').matches || isMobilePixel){
      $(this).removeClass("glossary");
      $(this).css("text-decoration", "underline");
    }
  })

  //now find glossary elements in all answers
  let allGlossaryElements = document.getElementsByClassName("glossary");
  for (let index = 0; index < allGlossaryElements.length; index++) {
    $(allGlossaryElements[index]).attr({
      "data-toggle": "tooltip",
      "data-animation": true,
      "data-placement": "auto"
    });
    //allGlossaryElements[index].setAttribute('onmouseover', 'showTooltip(this)');
    //allGlossaryElements[index].setAttribute('onmouseout', 'hideTooltip()');
  }

  setUpSiteSelectionExtras();
}

function addCCNoEmail() {
  let answerType;
  var allQuestions = document.getElementsByClassName("question-answers-container");

  for (let i = 0; i < allQuestions.length; i++) {
    //Loop through all question elements on page
    //Check if this question element has answer elements
    if (allQuestions[i].getElementsByClassName("answers-container").length > 0) {
      //Set answer type to know what type of elements we are dealing with
      answerType = allQuestions[i].dataset.answerType;
      if (answerType === 'EMAIL') {
        let allInputs = $(allQuestions[i].getElementsByClassName("answers-container")[0]).find('input:visible, textarea:visible, .invisible-required-input');
        let emailFieldId = `${allQuestions[i].id}`;

        let isCCEmailCheckBoxChecked = document.getElementById("ccEmailCheckBox").checked;
        sessionStorage.setItem('isCCEmailCheckBoxChecked', isCCEmailCheckBoxChecked);

        if (isCCEmailCheckBoxChecked) {
          allInputs[0].value = 'no.email@globalaes.com';
          document.getElementById(emailFieldId).getElementsByTagName('input')[0].readOnly = true;
        } else {
          allInputs[0].value = "";
          document.getElementById(emailFieldId).getElementsByTagName('input')[0].readOnly = false;
        }
      }
    }
  }
}

function setUpSiteSelectionExtras() {
  //add horizontal scroll to sites container
  if (document.querySelector('#siteCardsContainer')) {
    addHorizontalScrollToSiteCardsContainer();
  }

  // set placeholder for google autocomplete
  const autoCompleteInput = document.getElementById("pac-input");
  if (autoCompleteInput) {
    let placeholderText = 'Enter a location';

    if (languageStrings[curLocale] && languageStrings[curLocale].t_locationInputPlaceholder) {
      placeholderText = languageStrings[curLocale].t_locationInputPlaceholder;
    }
    else if (languageStrings['en_US'] && languageStrings['en_US'].t_locationInputPlaceholder) {
      placeholderText = languageStrings['en_US'].t_locationInputPlaceholder;
    }

    autoCompleteInput.setAttribute('placeholder', placeholderText);
  }
}

function getElementForAnswer(answerType, questionData, belongsToPII) {
  let elementCreated;

  switch (answerType) {
    case "TEXT_DATE":
      elementCreated = document.createElement("input");
      elementCreated.type = "text";
      elementCreated.pattern = "[0-9/]*";
      elementCreated.minLength = "8";
      elementCreated.setAttribute("inputmode", "numeric");
      elementCreated.className = "noTelephone col-sm-12 col-md-6";

      elementCreated.placeholder = translationMap && translationMap['frontend.ui.mm_dd_yyyy.placeholder'] ? translationMap['frontend.ui.mm_dd_yyyy.placeholder'] : 'MM/DD/YYYY';
      $(elementCreated).inputmask({
        mask: translationMap && translationMap['frontend.ui.mm_dd_yyyy.mask'] ? translationMap['frontend.ui.mm_dd_yyyy.mask'] : '99/99/9999',
        showMaskOnFocus: false,
        clearMaskOnLostFocus: true,
        autoUnmask: true,
        placeholder: translationMap && translationMap['frontend.ui.mm_dd_yyyy.placeholder'] ? translationMap['frontend.ui.mm_dd_yyyy.placeholder'] : 'MM/DD/YYYY'
      });
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.name = "textDate" + answerCount;
      break;
    case "TEXT_MM_YYYY":
      elementCreated = document.createElement("input");
      elementCreated.type = "text";
      elementCreated.pattern = "[0-9/]*";
      elementCreated.setAttribute("inputmode", "numeric");
      elementCreated.className = "noTelephone";

      elementCreated.setAttribute("placeholder", translationMap && translationMap['frontend.ui.mm_yyyy.placeholder'] ? translationMap['frontend.ui.mm_yyyy.placeholder'] : 'MM/YYYY');
      $(elementCreated).inputmask({
        mask: translationMap && translationMap['frontend.ui.mm_yyyy.mask'] ? translationMap['frontend.ui.mm_yyyy.mask'] : '99/9999',
        showMaskOnFocus: false,
        clearMaskOnLostFocus: true,
        autoUnmask: true
      });
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.name = "textDate" + answerCount;
      break;
    case "TEXT_YEAR":
      elementCreated = document.createElement("input");
      elementCreated.type = "number";
      elementCreated.step = "1";
      elementCreated.className = "noTelephone";

      $(elementCreated).inputmask({
        mask: translationMap && translationMap['frontend.ui.yyyy.mask'] ? translationMap['frontend.ui.yyyy.mask'] : '9999',
        showMaskOnFocus: false,
        clearMaskOnLostFocus: true,
        autoUnmask: true
      });
      elementCreated.setAttribute("placeholder", translationMap && translationMap['frontend.ui.yyyy.placeholder'] ? translationMap['frontend.ui.yyyy.placeholder'] : 'YYYY');
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.pattern = "[0-9]*";
      elementCreated.maxLength = 4;
      elementCreated.setAttribute("inputmode", "numeric");
      break;
    case "TEXT_NUMBER":
      elementCreated = document.createElement("input");
      elementCreated.type = "text";
      elementCreated.className = "noTelephone";
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.name = "number" + answerCount;
      if (questionData["m_iMaxLength"] && questionData["m_iMaxLength"] > 0) {
        elementCreated.maxLength = questionData["m_iMaxLength"];
      }
      elementCreated.pattern = "[0-9]*";
      elementCreated.setAttribute("inputmode", "numeric");
      break;
    case "TEXT":
    case "TEXT_ZIP":
      elementCreated = document.createElement("input");
      elementCreated.type = "text";
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.setAttribute("name", "answer" + answerCount);

      if (questionData["m_iMaxLength"] && questionData["m_iMaxLength"] > 0) {
        if (questionData.m_basis !== 'ADDRESS1') {
          elementCreated.maxLength = questionData["m_iMaxLength"];
        }
      }

      if (questionData["m_basis"] == "COUNTRY" && sessionStorage.getItem("gpCountry") ) {
        elementCreated.value = sessionStorage.getItem("gpCountry");
      }

      if (questionData["m_basis"] == "STATE" && sessionStorage.getItem("gpState") ) {
        elementCreated.value = sessionStorage.getItem("gpState");
      }

      if (questionData["m_basis"] == "CITY" && sessionStorage.getItem("gpCity") ) {
        elementCreated.value = sessionStorage.getItem("gpCity");
      }

      if (questionData["m_basis"] == "ZIP" || questionData["m_basis"] == "CAREGIVER_ZIP") {

        if (m_screening.m_locale === "en_US") {
          elementCreated.pattern = "[0-9]*";
          elementCreated.type = "number";
          elementCreated.setAttribute("inputmode", "numeric");
          elementCreated.minLength = 5; // change to the following if AES change their end : questionData['m_iMaxLength'];
          elementCreated.maxLength = 5; // change to the following if AES change their end : questionData['m_iMaxLength'];
        } else {
          elementCreated.type = "text";
          elementCreated.maxLength = 9; // change to the following if AES change their end : questionData['m_iMaxLength'];
        }

        let basisPrefix = "";
        if (questionData["m_basis"] == "CAREGIVER_ZIP") {
          basisPrefix = "CAREGIVER_";
        }

        if (sessionStorage.getItem("gpPostalCode")) {
          elementCreated.value = sessionStorage.getItem("gpPostalCode");
        }

        if (sessionStorage.getItem("postcode")) {
          elementCreated.value = sessionStorage.getItem("postcode");
          autofillCityAndStateByPostcode(elementCreated.value, basisPrefix);
        }
        $(elementCreated).on("change input propertychange", { basisPrefixTemp: basisPrefix }, function (event) {
          if (event.data) {
            autofillCityAndStateByPostcode(this.value, event.data.basisPrefixTemp);
            sessionStorage.setItem("postcode", this.value);
          }
        });

      }

      const isLengthDefined = elementCreated.maxLength;
      if(isLengthDefined === -1)
        elementCreated.maxLength = 500;

      break;
    case "TEXT_AREA":
      elementCreated = document.createElement("textarea");
      elementCreated.setAttribute("name", "answer" + answerCount);
      elementCreated.className = "w-100";
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.rows = "5";
      if (questionData["m_iMaxLength"] && questionData["m_iMaxLength"] > 0) {
        if (questionData.m_basis !== 'ADDRESS1') {
          elementCreated.maxLength = questionData["m_iMaxLength"];
        }
      } else {
        elementCreated.maxLength = 500; //PQAR only allows 500 chars
      }
      break;
    case "EMAIL":
      elementCreated = document.createElement("input");
      elementCreated.type = "email";
      elementCreated.setAttribute("name", "email" + answerCount);
      elementCreated.required = questionData["m_bRequired"];
      break;
    case "TEXT_PHONE":
      elementCreated = document.createElement("input");
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.type = "tel";
      elementCreated.setAttribute("name", "phone");
      elementCreated.setAttribute("inputmode", "numeric");
      elementCreated.setAttribute("placeholder", "");

      let label = document.createElement("label");
      label.className = "form__label";
      label.setAttribute("for", "phone");
      elementCreated.appendChild(label);
      break;
    case "TEXT_HEIGHT":
      elementCreated = createHeightInputField(questionData);
      break;
    case "TEXT_WEIGHT":
      elementCreated = createWeightInputField(questionData);
      break;
    case "DROPDOWN":
      elementCreated = createDropdown(questionData);
      break;
    case "RADIO":
    case "STUDYSWPR":
      elementCreated = createSingleChoiceList(questionData);
      break;
    case "CHECKBOX":
    case "INDICATION_LIST":
      elementCreated = createMultipleChoiceList(questionData);
      break;
    case "DUAL_HEIGHT":
      elementCreated = createDualHeightChoiceList(questionData);
      break;
    case "DUAL_WEIGHT":
      elementCreated = createDualWeightChoiceList(questionData);
      break;
    case "SITE_SELECTION":
      elementCreated = createSelectSiteOutput(questionData);

      //To remove the item if user comes back to Site Selection again!!
      for (var key in sessionStorage) {
        if (key.indexOf("siteApptVisited") === 0) {
          sessionStorage.removeItem(key);
        }
      }

      $('body').on('click', '.showMapArea', function () {
        $('#mapArea').removeClass('d-none');
      });

      $('body').on('click', '.hideMapArea', function () {
        $('#mapArea').addClass('d-none');
      });
      break;
    case "ESIGN":
      const esignContent = document.createElement("div");
      $(esignContent).html(`<input type="text" id="esignValue" class="invisible" readonly>`);
      elementCreated = esignContent;
      break;
    case "CHARTFILL":
      elementCreated = createChartfillOutput(questionData);
      break;
    case "PROTODD": //changes for new question type
      const emrContentNew = createEmrContent(questionData.m_choiceSource.m_choices,"PROTODD");
      const singleChoiceContentNew = createSingleChoiceList(questionData);
      singleChoiceContentNew.classList.add('d-none'); //hide checkboxes from the UI
      elementCreated = $(emrContentNew).append(singleChoiceContentNew)[0]; //append will return the entire dom object as array key 0

      $('#questions').append(`<script>
        $(document).ready(function(){
           var tailSelectProtoDD = tail.select("#protocolSelectProtoDD", {
            animate: false,
            multiple: false,
            deselect: true,
            searchMinLength: 2,
            search: true,
            classNames: "w-100",
            stayOpen: true,
            startOpen: true,
            openAbove: null,
            height: 250,
            locale: m_screening.m_locale
           });

           var itemsPrePopulated = "";

           $(".single-choice-answers-container").parents(".question-answers-container").each(function(){
            if($(this).attr('data-answer-type') === 'PROTODD'){
              itemsPrePopulated =  $(this).find('.single-choice-answers-container button.answer-option-container-clicked') //if back button is used items should be in this object
            }
          })

           if(itemsPrePopulated.length > 0){
            //answers are pre populated. Make the tail select reflect this
            itemsPrePopulated.each(function(){
              tailSelectProtoDD.options.select(this.value, '#'); //check the tail select option
              prepopulateItemProtoDD(this.value, this.textContent); //run a fuction to populate the html item in the selected protocols list
            });
           }





           tailSelectProtoDD.on("change", function(item, state){
            modifyItemsProtoDD(item, state)
           });

           $('.tail-select .search-input').attr('data-ignore-answer', true).keydown(function (e) {
            if (e.keyCode == 13) {
              e.preventDefault(); //enter key disabled
              return false;
            }
           });  //ensure that the search input for tail select does not get submitted as an answer and dont allow the enter key to close tailselect

        });

        function prepopulateItemProtoDD(key, value){
          $('#selectedProtocolsProtoDD>div').remove();
          $('#selectedProtocolsProtoDD > p').after('<div class="protocol" data-key="'+key+'"><span class="faBackground"><i class="fa fa-pills"></i></span><span class="protocolValue">'+value+'</span></div>');
        }

        function modifyItemsProtoDD(item, type){
          //triggered when a tail select options state is changed
          if (type === "select"){
            $('#selectedProtocolsProtoDD>div').remove();
            $('#selectedProtocolsProtoDD > p').after('<div class="protocol" data-key="'+item.key+'"><span class="faBackground"><i class="fa fa-pills"></i></span><span class="protocolValue">'+item.value+'</span></div>');
          } else if (type === "unselect"){
            $('#selectedProtocolsProtoDD>div[data-key="'+item.key+'"]').remove();
          }

          $('.single-choice-answers-container button#answer-option-'+item.key).click(); //change status of the hidden checkbox
        }
      </script>`);
      break;
    case "PROTOLIST": //emr question
      const emrContent = createEmrContent(questionData.m_choiceSource.m_choices,"PROTOLIST");
      const multiChoiceContent = createMultipleChoiceList(questionData);
      multiChoiceContent.classList.add('d-none'); //hide checkboxes from the UI
      elementCreated = $(emrContent).append(multiChoiceContent)[0]; //append will return the entire dom object as array key 0

      $('#questions').append(`<script>
        $(document).ready(function(){
           var tailSelect = tail.select("#protocolSelect", {
            animate: false,
            multiple: true,
            deselect: true,
            searchMinLength: 2,
            search: true,
            classNames: "w-100",
            stayOpen: true,
            startOpen: true,
            openAbove: null,
            height: 250,
            locale: m_screening.m_locale
           });

           var itemsPrePopulated = "";
           $(".multiple-choice-answers-container").parents(".question-answers-container").each(function(){
            if($(this).attr('data-answer-type') === 'PROTOLIST'){
              itemsPrePopulated =  $(this).find('.multiple-choice-answers-container button.answer-option-container-clicked') //if back button is used items should be in this object
            }
          })

           if(itemsPrePopulated.length > 0){
            //answers are pre populated. Make the tail select reflect this
            itemsPrePopulated.each(function(){
              tailSelect.options.select(this.value, '#'); //check the tail select option
              prepopulateItem(this.value, this.textContent); //run a fuction to populate the html item in the selected protocols list
            });
           }


           tailSelect.on("change", function(item, state){
             modifyItems(item, state)
           });

           $('.tail-select .search-input').attr('data-ignore-answer', true).keydown(function (e) {
            if (e.keyCode == 13) {
              e.preventDefault(); //enter key disabled
              return false;
            }
           });  //ensure that the search input for tail select does not get submitted as an answer and dont allow the enter key to close tailselect

        });

        function prepopulateItem(key, value){
          $('#selectedProtocols').append('<div class="protocol" data-key="'+key+'"><span class="faBackground"><i class="fa fa-pills"></i></span><span class="protocolValue">'+value+'</span></div>');
        }

        function modifyItems(item, type){
          //triggered when a tail select options state is changed
          if (type === "select"){
            $('#selectedProtocols').append('<div class="protocol" data-key="'+item.key+'"><span class="faBackground"><i class="fa fa-pills"></i></span><span class="protocolValue">'+item.value+'</span></div>');
          } else if (type === "unselect"){
            $('#selectedProtocols>div[data-key="'+item.key+'"]').remove();
          }

          $('.multiple-choice-answers-container button#answer-option-'+item.key).click(); //change status of the hidden checkbox
        }
      </script>`);
      break;
    case "SITEDISPL":
    case "STUDYSEL":
      elementCreated = createSingleChoiceList(questionData);
      break;
    case "GPAUTO":
      elementCreated = document.createElement("input");
      elementCreated.type = "text";
      elementCreated.id = 'gpAutoTextField';
      elementCreated.required = questionData["m_bRequired"];
      elementCreated.setAttribute("name", "answer" + answerCount);
      break;
    default:
      elementCreated = document.createElement("div"); //always have a dom element to pass back
  }

  deviceDetails =  detectDevice();
  let isMobilePixel = deviceDetails.isMobile;

  let hideInfo = 'd-none col-2';
  let rowInfo= 'col-lg-12 col-10 col-md-12';
  if(isMobilePixel){
    hideInfo = 'd-inline col-2';
    rowInfo= 'col-lg-10 col-10 col-md-10';
  }

  $(elementCreated).find("button").each(function(){
    var hasData = $(this).find(".glossary");
    let isGlossaryEmpty = hasData.attr('title') === '' ? hasData.attr('data-original-title') : hasData.attr('title');

    if (typeof isGlossaryEmpty !== "undefined"){
      $(this).wrap(`<div class='row rowInfo'><div class='${rowInfo}'></div><div class='${hideInfo}' id='centerFaIcon'><i class='fa fa-question-circle unique-${currentPage}' aria-hidden='true'></i></div> </div>`);
      hasData.addClass('glossary-tooltip-mobile');
    }else if($(elementCreated).find(".glossary , .glossary-tooltip-mobile").length > 0)
      $(this).wrap(`<div class='row'><div class='${rowInfo}'></div></div>`);

    if(window.matchMedia('(max-width: 767px)').matches || (isMobilePixel)){
      hasData.css("text-decoration", "underline");
      hasData.removeClass("glossary");
    }

  })

  let allTimeOuts =  [];
  let emContent = null;
  let emSeconds = null;

  $(`#attchModalId-${parseInt(currentPage - 1)}`).remove();
  $(`#attchModalId-${parseInt(currentPage + 1)}`).remove();
  let isExecuted = false;
  if(!isExecuted){
    $(`<div id='attchModalId-${currentPage}'>`).appendTo('body');
    isExecuted = true;
  }


  $(`<div class='modal' id='glossaryTextModal-${currentPage}'>
  <div class='modal-dialog-scrollable modal-dialog-centered'>
    <div class='modal-content'>
    <div class='modal-body' id='glossaryTextModalBody-${currentPage}'>
    </div>
    <div class='modal-footer glossary-footer'>
    <button id='dismiss-glossary-modal-${currentPage}' data-dismiss='modal' type='button' class='btn-primary'></button>
    </div>
    <div class='text-center' style='margin-top: -20px;'>
    <p class='font-weight-light' id='modalTimer'></p>
    </div>
    </div>
  </div>
</div>`).appendTo(`#attchModalId-${currentPage}`);

  $(elementCreated).find("i.unique-"+currentPage).click(function (){
    emContent = "";
    emSeconds = 30;
     $(this).parents().eq(1).find(".glossary-tooltip-mobile").each(function (){
      var txtHeading = $(this).html();
      var txtBody =  $(this).attr('title') === '' ? $(this).attr('data-original-title') : $(this).attr('title');
      emContent = emContent + "</br><i><b>"+ txtHeading.toUpperCase() +"</b></i> : "+ txtBody+"</br>";
     })


      $("#dismiss-glossary-modal-"+currentPage).html("");
      $("#modalTimer").html("");
      $("#glossaryTextModal-"+currentPage).modal("show");
      $("#glossaryTextModalBody-"+currentPage).html(emContent);

      var glossaryPopUpem = translationMap['glossary.popup.close.button'];
      $('.modal-backdrop').remove();
      glossaryModalTooltipInterval = setInterval(function () {
        if($("#glossaryTextModalBody-"+currentPage).html() === '')
            $("#glossaryTextModalBody-"+currentPage).html(emContent);
        if (parseInt(emSeconds) <= 0) {
           $("#glossaryTextModal-"+currentPage).modal("hide");
          emSeconds = null;
          emContent = null;
          clearInterval(glossaryModalTooltipInterval);
          // $("#dismiss-glossary-modal").html(`You can close here otherwise it will close in 10 seconds`);
        } else if(emSeconds != null){
            $("#dismiss-glossary-modal-"+currentPage).html(`${glossaryPopUpem}`);//You can close here otherwise it will close in ${emSeconds} seconds
            $("#modalTimer").html(`${translationMap['glossary.popup.after.close.button'].replace('###' , emSeconds)}`)
            emSeconds--;
        }else{
          clearAllTimers();
        }
      }, 1000)

      $('#glossaryTextModal-'+currentPage).on("hidden.bs.modal", function () {
      emSeconds = null;
      clearInterval(glossaryModalTooltipInterval);
      });
   })

  // if it is a required field, make sure form__input is added to class list to trigger validation
  if (questionData["m_bRequired"] == true) {
    elementCreated.classList.add('form__input')
  }

  //elementCreated.autocomplete = "on";
  answerCount += 1;
  return elementCreated;
}

function clearAllTimers(){
  for (let i = 1; i < 99999; i++)
        window.clearInterval(i);
}


function createEmrContent(choices,questionType) {
  const emrContent = document.createElement("div");
  let optionsHtml = "";
  for (let i = 0; i < choices.length; i++) {
    //itterate through all choices and create the select option
    optionsHtml += "<option value='" + choices[i].m_sUniqueChoiceCode + "'>" + choices[i].m_sRawText + "</option>"
  }
let idType = "protocolSelect";
let displayIdType = "selectedProtocols";
let protocolTextDisplay = "Selected protocols:";
let dataIgnoreAnswer;
let textConversion = "t_emrSelectProtocolsText";
if(questionType == "PROTODD"){
  idType = "protocolSelectProtoDD";
  displayIdType = "selectedProtocolsProtoDD"
  protocolTextDisplay = "Selected protocol:";
  dataIgnoreAnswer = "data-ignore-answer='true'";
  textConversion = "t_emrSelectProtocolsTextDD"
}

  $(emrContent).html(`
      <div class="row mt-3">
        <div class="col-md-4">
          <select id=${idType} ${dataIgnoreAnswer}>
            ${optionsHtml}
          </select>
        </div>

        <div class="col-md-8" id=${displayIdType}>
          <p class="smaller-black-text mb-0" data-translate=${textConversion} data-translate-default=${protocolTextDisplay}></p>
        </div>
      </div>
  `);

  return emrContent;
}

function outputSiteSchedule(iframeSrc) {
  //this function is called when content passed through matches that of a site selction url
  const iframe = document.createElement("iframe");
  iframe.className = "w-100";
  iframe.id = "siteSchedule";
  iframe.frameBorder = 1;
  iframe.src = iframeSrc;
  iframe.onload = function () {
    this.style.height = this.contentWindow.document.body.scrollHeight + "px";
  };

  $(".question-header-illustration, #questions, #submit, #previous").hide(); //Hide all these form elements. These are needed for submission to the next question.
  $("#questions-form").prepend(iframe); //Add the iframe in
}

function createChartfillOutput(questionData) {
  const iFrameContainer = document.createElement("div");
  $(iFrameContainer).html(`
    <input type="text" id="chartfillValue" class="invisible" readonly>
    <div id="chartfill-iframe-container">
       <iframe id="chartfill-iframe" src="${questionData.m_sChartfill_url}" height="100%" width="100%"></iframe>
    </div>
  `);

  return iFrameContainer;
}

function getStarRatingHtml(numberString) {
  const filledStar = `<i class="fas fa-star"></i>`;
  const emptyStar = `<i class="far fa-star"></i>`;
  const halfStar = `<i class="fas fa-star-half-alt"></i>`;
  switch (numberString.toLowerCase()) {
    case "one":
      return `${filledStar.repeat(4)}${halfStar.repeat(1)}`;
    case "two":
      return `${filledStar.repeat(4)}${emptyStar.repeat(1)}`;
    case "three":
      return `${filledStar.repeat(3)}${halfStar.repeat(1)}${emptyStar.repeat(1)}`;
    case "four":
      return `${filledStar.repeat(3)}${emptyStar.repeat(2)}`;
    default:
      return false;
  }
}

function createSelectSiteOutput(questionData) {
  showSSHelpBtn();

  // check whether smart site selection is hidden or not
  const hideSmartSite = !m_screening.b_smartSiteSelectionInd;
  siteSelectionQuestionCode = questionData.m_sUniqueCode;

  // the selections are gathered in createJSONPayload where it checks for inputs and their values hence why theres a d-none input added to this element

  // this function returns an element that is then inserted after more elements are made later in the code
  // create a hidden div in the body that these elements can be inserted into and then returned
  document.querySelector('body').insertAdjacentHTML('beforeend', '<div class="d-none js-temporary-element-creation-div"></div>');
  const sitesChoices = questionData.m_choiceSource.m_choices;
  const questionCode = questionData.m_sUniqueCode;

  // build the main container insie the answers containers
  document.querySelector('.js-temporary-element-creation-div').innerHTML = `<div id="siteSelectionContainer" class="my-5 mx-0">

                                                                              <div class="row no-gutters ss-instructions">
                                                                                <p class="translation mb-2" name="t_ssInstruction1">Please select a site from the list below and click the "Next" button. You can use the left and right arrows to see more sites.</p>
                                                                                <p class="translation mb-3" name="t_ssInstruction2">To search for additional sites or for sites in a different location, please enter your preferred location (Address/Zip/City) and distance, and click on "Search".</p>
                                                                                <p class="translation mb-3" name="t_ssInstruction3">Click the "Reset" button to view your initial list of sites.</p>
                                                                              </div>

                                                                              <div id="siteSelectionToolBar"class="row no-gutters">
                                                                                <div class="sites-toolbar--inner col-12">
                                                                                  <div class="row no-gutters w-100">
                                                                                    <div class="sites-toolbar--input-container col order-1 order-md-1">
                                                                                      <div id="pac-container">
                                                                                        <input id="pac-input" type="text" placeholder="E.g. '1666' or 'Walnut Grove'" data-ignore-answer="true"/>
                                                                                      </div>
                                                                                      <div class="sites-toolbar-input-icon-container">
                                                                                        <i class="fas fa-search sites-input-icon-after"></i>
                                                                                      </div>
                                                                                    </div>
                                                                                    <div class="sites-toolbar--dropdown-container col order-3 order-md-2">
                                                                                      <select id="sitesRadiusDropdown" data-ignore-answer="true"></select>
                                                                                    </div>
                                                                                    <div class="col order-2 order-md-3 sites-toolbar--search-btn">
                                                                                      <div class="inner">
                                                                                        <i class="fas fa-check"></i>
                                                                                      </div>
                                                                                    </div>
                                                                                    <div class="col order-4 order-md-4 sites-toolbar--reset-btn is-disabled" disabled>
                                                                                      <div class="inner">
                                                                                        <i class="fas fa-redo-alt"></i>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>

                                                                              <div class="row no-gutters mb-4 sites-container-parent" style="overflow-x: hidden;">
                                                                                <div id="siteCardsContainer" class="col-12 d-flex"></div>
                                                                              </div>

                                                                              <div id="mapSection"class="row no-gutters">
                                                                                <div id="mapArea" class="col-12"></div>
                                                                              </div>

                                                                              <div id="noSiteConvenientContainer" class="row no-gutters pt-4 pl-0">
                                                                                <div id="noneOption" class="js-noSiteConvenient mr-0 ml-auto p-3 d-flex">
                                                                                  <div class="custom-control custom-checkbox my-auto pr-3">
                                                                                    <input value="None" name="location" class="custom-control-input m-auto" type="checkbox" data-ignore-answer="true">
                                                                                    <label class="custom-control-label"</label>
                                                                                  </div>
                                                                                  <span data-translate="msg.no_site_convenient" data-translate-default="No research site is convenient">No research site convenient</span>
                                                                                  <div class="debugQuestionInfo noMt js-none-convenient-debug"></div>
                                                                                </div>
                                                                              </div>
                                                                            </div>`;

  if (hideSmartSite) {
    $('#siteSelectionToolBar, [name="t_ssInstruction2"], [name="t_ssInstruction3"]').addClass('d-none');
    hideSSHelpBtn();
  }



  // set for mobile
  deviceDetails = detectDevice();
  const mobileView = deviceDetails.isMobile;

  // make the toolbar double height because elements will stack (this is a weird case because its a dropdown and if no max-height is set then it expands when you open the dropdown)
  if (mobileView) {
    document.querySelector('.sites-toolbar--inner').classList.add('mobile');
  }

  // set translations
  if (languageStrings[curLocale] && languageStrings[curLocale].t_ssInstruction1) {
    $('.translation[name="t_ssInstruction1"]').innerHTML = languageStrings[curLocale].t_ssInstruction1;
  }

  if (languageStrings[curLocale] && languageStrings[curLocale].t_ssInstruction2) {
    $('.translation[name="t_ssInstruction2"]').innerHTML = languageStrings[curLocale].t_ssInstruction2;
  }

  // reset variables
  ssSelectedPlaceResult = null;
  ssRadius = null;

  // build the tail select
  ssRadiusUnit = getSchedulerDistanceUnit();

  // translate dropdown options
  const options = [];
  const distances = ['5', '10', '25', '50'];
  const optString = (languageStrings[curLocale] && languageStrings[curLocale]['t_ssDropdownOption']) ? languageStrings[curLocale]['t_ssDropdownOption'] : languageStrings['en_US']['t_ssDropdownOption'];

  for (const d of distances) {
    let curOptString = "" + optString;
    curOptString = curOptString.replace("%0%", d);
    curOptString = curOptString.replace("%unit%", languageStrings[curLocale]['t_ssDropdownDistanceUnit'] ? languageStrings[curLocale]['t_ssDropdownDistanceUnit'] : ssRadiusUnit);
    options.push(curOptString);
  }

  const select = document.getElementById('sitesRadiusDropdown');
  const dropdownOptions = {
    multiple: false,
    search: false,
    deselect: true,
    locale: curLocale,
    placeholder: (languageStrings[curLocale] && languageStrings[curLocale]['t_ssDropdownLabel']) ? languageStrings[curLocale]['t_ssDropdownLabel'] : languageStrings['en_US']['t_ssDropdownLabel'],
    classNames: 'js-sitesRadiusDropdown',
    items: options
  };
  const distanceDropdown = tail.select(select, dropdownOptions);

  // add callback
  distanceDropdown.on('close', (item, state) => { ssDistanceDropdownChanged(distanceDropdown, item, state) });

  // add the icon to the dropdown
  addIconToRadiusDropdown();

  const siteContainer = document.querySelector('#siteCardsContainer');
  const mapContainer = document.getElementById('mapSection');
  const thisCallType = m_screening.m_type;

  // add the left and right nav buttons - event listener just after doc ready
  const siteCardsParent = document.querySelector('.sites-container-parent');
  let navBtnHTML = `<div class="site-cards-nav-btn scnb-left">
                        <i class="fas fa-chevron-left"
                      </div>`;

  // left btn
  siteCardsParent.insertAdjacentHTML('afterbegin', navBtnHTML);

  // right btn
  while (navBtnHTML.includes('left')) {
    navBtnHTML = navBtnHTML.replace('left', 'right')
  }
  siteCardsParent.insertAdjacentHTML('afterbegin', navBtnHTML);

  // build the site cards - if coming back to the question and user previously searched for sites they will be restored
  // currentSites need to be null or set to previous here incase coming back from another question
  if (searchedSitesPayload) {
    buildSiteCards(searchedSitesPayload);
    // this is used when sites have been searched for and the user picks a site that wasnt originally returned
    currentSites_m_Choices = searchedSitesPayload;
  }
  else {
    buildSiteCards(questionData.m_choiceSource.m_choices);
    // this is used when sites have been searched for and the user picks a site that wasnt originally returned
    currentSites_m_Choices = null;
  }

  // select previous selection
  if (selectedSiteUniqueCode) {
    for (let i of document.querySelectorAll('.location-card')) {

      // remove selected class of all, only select if match. also set value to '' since in createJSONPayload it checks all inputs and we only want the one that has the value (which happens after doc ready in event listener)
      i.classList.remove('selected');
      $(i).find('.js-location-card--input').attr('data-ignore-answer', true);

      if (i.querySelector('.js-location-card--input').getAttribute('data-unique-code') == selectedSiteUniqueCode) {
        i.classList.add('selected');
        $(i).find('.js-location-card--input').removeAttr('data-ignore-answer').val(selectedSiteUniqueCode).attr('checked', true);
      }
    }
  }

  // map details
  const patientLat = m_screening["m_geocode"]["m_dLatitude"];
  const patientLong = m_screening["m_geocode"]["m_dLongitude"];
  // add map marker for patients home
  siteSelectionLocations.push({ lat: patientLat, lng: patientLong, icon: "/images/markerHomeSmall.png" });

  // create no site convenient
  const overallSiteSelectionContainer = document.querySelector('#siteSelectionContainer');
  overallSiteSelectionContainer.querySelector('.js-none-convenient-debug').innerHTML = `${questionCode}_None`;

  // no choices
  if (!sitesChoices || !sitesChoices.length) {
    noSitesAvailable();
  }
  else {
    $('.site-cards-nav-btn').show();
  }

  // map if not callcenter
  if (thisCallType === 'CALLCENTER') {
    mapContainer.classList.add('d-none');
  }

  // disbale the search/reset buttons
  document.querySelector('.sites-toolbar--reset-btn').setAttribute('disabled', 'true');
  document.querySelector('.sites-toolbar--reset-btn').classList.add('is-disabled');
  ssRadius = null;
  ssSelectedPlaceResult = null;
  checkSiteSearchButtons();
  checkShowSiteCardsNavBtns();

  // set the route origin to the patients locations
  mapRouteOrigin = { lat: patientLat, lng: patientLong };

  // return whole container
  return document.querySelector('#siteSelectionContainer');
}

function getSchedulerDistanceUnit() {
  if (curLocale && curLocale.split('_')[1].toLowerCase() == 'us' || curLocale.split('_')[1].toLowerCase() == 'gb') {
    return 'miles';
  }
  else {
    return 'km';
  }
}

function convertMilesToKM(miles) {
  return Math.round(miles * 0.621371);
}


function buildSiteCards(sitesChoices) {
  // check to show debug sites or not
  let showDebugSites = false;

  if (m_screening && m_screening.b_show_debug) {
    showDebugSites = true;
  }
  else {
    showDebugSites = false;
  }

  // hide test sites if not debug
  if (!showDebugSites && !showDebugContent) {
    sitesChoices = removeTestSitesFromPayload(sitesChoices);
  }

  const siteContainer = document.querySelector('#siteCardsContainer');
  const thisCallType = m_screening.m_type;
  const mapLabels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  let facilityIndex = 0;
  let showMap = false;

  const locationCardHTML = `<div class="location-card choiceSelectionContainer">
                                <div class="row no-gutters p-3">
                                    <div class="col-12">
                                        <div>
                                            <p class="resultHeadlineText">
                                                <strong class="js-location-card--dr-name"></strong><br>
                                                <strong class="js-location-card--facility-name"></strong>
                                            </p>
                                        </div>
                                        <div class="resultSubText"><small class="js-location-card--full-address"></small></div>

                                        <div class="location-card--distance-details">
                                            <i class="location-card--map-icon fal fa-location"></i> <span class="location-card--distance"></span>
                                        </div>
                                        <div class="debugQuestionInfo noMt"></div>
                                        <input class="d-none js-location-card--input invisible-required-input" checked="false" data-ignore-answer="true">
                                    </div>
                                </div>
                              </div>`;
  // invisible-required-input: createJSONPayload gets inputs to create answers payload. this means it will be included in answer gathering,
  // however data-ignore-answer attr will be on all unselected cards and therefore they will be ignored anyway except for the selected one.
  // data-ignore-answer is on to start as above, removed on create first card below, and then on click of any card it is added to all cards and removed from the now selected one
  // it was done this way to keep to the existing answer building logic

  siteContainer.innerHTML = '';

  // if there are no sites auto select no sites convenient and lock the button so the user cant untick it
  if (!sitesChoices.length) {
    noSitesAvailable();
  }
  else {
    lockNoSiteConvenient = false;
    deselectNoSitesConvenient();
    $('.site-cards-nav-btn').show();
  }

  // build a card for each site returned
  for (let i = 0; i < sitesChoices.length; i++) {
    const data = sitesChoices[i];

    // create the card and add details from data
    const newCardHTML = locationCardHTML;
    siteContainer.insertAdjacentHTML('beforeend', newCardHTML);
    const newCard = siteContainer.lastChild;

    // details from data

    // site
    const siteAddress1 = data.m_sAddress1 ? data.m_sAddress1 : '';
    const siteAddress2 = data.m_sAddress2 ? (siteAddress1 ? ", " + " " + data.m_sAddress2 : data.m_sAddress2) : '';
    const siteCity = data.m_sCity ? data.m_sCity : '';
    const siteState = data.m_sState ? data.m_sState : '';
    const siteStateName = data.m_sStateName ? data.m_sStateName : '';
    const sitePostal = data.m_sPostal ? data.m_sPostal : '';
    const siteName = data.m_sFacilityName ? data.m_sFacilityName : '';
    let siteDistance = (data.m_fDistance || data.m_fDistance == 0) ? data.m_fDistance : '';
    const siteDistanceUnit = data.m_sDistanceMetricUnit ? data.m_sDistanceMetricUnit : '';
    const facilityGroupID = data.facility_group_id;
    // doctor
    const drName = `${data.m_sFirst} ${data.m_sLast}`;
    // debug
    const uniqueChoiceCode = data.m_sUniqueChoiceCode;
    // sending to calls when chosen
    const siteFacilityCD = data.facility_cd;

    // set debug question info
    $(newCard).find('.debugQuestionInfo').html(siteSelectionQuestionCode + '_' + uniqueChoiceCode);

    // populate element
    newCard.querySelector('.js-location-card--dr-name').innerHTML = `Dr. ${drName}`;
    newCard.querySelector('.js-location-card--facility-name').innerHTML = siteName;
    newCard.querySelector('.js-location-card--full-address').innerHTML = `${siteAddress1}${siteAddress2}<br>
                                                                            ${siteCity}, ${siteState} <br>${siteStateName}<br>
                                                                            ${sitePostal}`;
    newCard.querySelector('.location-card--distance').innerHTML = `${siteDistance} ${siteDistanceUnit} away`;

    // if virtual site hide distance details
    if (data['b_virtualSiteInd']) {
      siteDistance = '';
      newCard.querySelector('.location-card--map-icon').classList.add('d-none');
    }

    // hide icon if no distance provided
    if (!siteDistance && siteDistance != 0) {
      newCard.querySelector('.location-card--map-icon').classList.add('d-none');
      newCard.querySelector('.location-card--distance-details').classList.add('d-none');
    }

    // mormal or remove some details if 101
    if (facilityGroupID !== 101) {
      newCard.setAttribute('data-location-key', facilityIndex);
      newCard.classList.add('showMapArea');
      showMap = true;
    }
    else {
      newCard.classList.add('hideMapArea');
      newCard.querySelector('.js-location-card--full-address').innerHTML = 'Scheduled Phone';
      newCard.querySelector('.location-card--distance').remove();
      newCard.querySelector('.location-card--map-icon').classList.add('d-none');
    }

    // set first selected amd set main option text

    // the value is gotten through createJSONPayload() searching for inputs so the input is needed and theres an onclick just after doc ready that is assigning the value and marking checked which needs this value
    $(newCard).find('.js-location-card--input').attr('data-unique-code', uniqueChoiceCode);
    $(newCard).find('.js-location-card--input').attr('data-facility-cd', siteFacilityCD);
    $(newCard).find('.js-location-card--input').attr('data-facility-group-id', facilityGroupID);

    // select first card
    if (i === 0) {
      newCard.classList.add('selected');
      $(newCard).find('.js-location-card--input').val(uniqueChoiceCode).attr('checked', true);
      $(newCard).find('.js-location-card--input').removeAttr('data-ignore-answer');
      selectedSiteUniqueCode = uniqueChoiceCode;
      selectedSiteFacilityCD = siteFacilityCD;
      selectedSiteFacilityGroupId = facilityGroupID;
    }

    // remove map icon if callcenter
    if (thisCallType === 'CALLCENTER') {
      newCard.querySelector('.location-card--map-icon').remove();
    }

    // map
    if (showMap) {
      if (data.m_fLatitude && data.m_fLongitude) {
        if (!data['b_virtualSiteInd']) {
          siteSelectionLocations.push({
            lat: data.m_fLatitude,
            lng: data.m_fLongitude,
            label: mapLabels.charAt(facilityIndex),
            title: `${siteAddress1} ${siteAddress2}, ${siteCity}, ${siteState} ${siteStateName}, ${sitePostal}`
          });

          facilityIndex++;
        }
      }
    }
  }

  // add a blank div to pad since padding doesnt seem to work
  siteContainer.insertAdjacentHTML('beforeend', '<div class="pl-4 pr-4"></div>');
  document.querySelector('#mapArea').classList.remove('d-none');

  // show debug info
  if (shouldShowDebugContent) {
    $('.debugQuestionInfo').show();
  }
}

function removeTestSitesFromPayload(payload) {
  let newData = [];

  for (const i of payload) {
    if (!i.b_testSiteInd) {
      newData.push(i);
    }
  }

  return newData;
}

function noSitesAvailable() {
  const siteContainer = document.querySelector('#siteCardsContainer');
  const noTranslationsText = (languageStrings[curLocale] != undefined && languageStrings[curLocale].t_noSites != undefined) ? languageStrings[curLocale].t_noSites : languageStrings['en_US'].t_noSites;
  siteContainer.innerHTML = `<div class='py-3'>
                              <i class='fas fa-exclamation-triangle pr-1' style="color: rgb(255, 72, 0);"></i>${noTranslationsText}<br>
                            </div>`;

  // hide th eleft and right scroll buttons
  $('.site-cards-nav-btn').hide();

  // select the no sites checkbox and lock it so the user cant untick
  selectNoSitesConvenient();
  lockNoSiteConvenient = true;
}

function checkShowSiteCardsNavBtns() {
  // this used to check width of cards are wider than the container
  if ($('#noneOption input').val() == 'NoSites') {
    $('.site-cards-nav-btn').hide();
  }
  else {
    // make sure the objects have loaded in the dom so set timeout
    setTimeout(() => {
      let width = 0;
      $('.location-card').each(function () {
        width += $(this).outerWidth(true)
      });

      if (width > $('#siteCardsContainer').width()) {
        $('.site-cards-nav-btn').show();
      }
      else {
        $('.site-cards-nav-btn').hide();
      }
    }, 300);
  }
}



function addIconToRadiusDropdown() {
  const dropdownLabel = document.querySelector('.js-sitesRadiusDropdown .select-label');
  dropdownLabel.classList.add('d-flex');
  const iconHTML = '<i class="fal fa-location ss-dropdown-icon"></i>';
  if (!dropdownLabel.querySelector('.ss-dropdown-icon')) {
    dropdownLabel.insertAdjacentHTML('afterbegin', iconHTML);
  }
}

function ssDistanceDropdownChanged(dropdown, item, state) {
  const ddSelection = dropdown.options.selected[0];
  addIconToRadiusDropdown();

  if (ddSelection) {
    ssRadius = ssRadiusDropdownDataForOptions[parseInt(ddSelection.getAttribute('value'))];

    // remove red warning on dropdown
    $('.sites-toolbar--dropdown-container .tail-select').removeClass('missing-required');
  }
  else {
    ssRadius = null;
  }

  checkSiteSearchButtons();
}


//ss help btn to show help panel
function showSSHelpBtn() {
  $('.ss-helpBtn').addClass('active');
}

function hideSSHelpBtn() {
  $('.ss-helpBtn').removeClass('active');
}

// actual help panel
function hideSSHelp() {
  $('.ss-helpPopup').removeClass('active');
}

function showSSHelp() {
  $('.ss-helpPopup').addClass('active');
}

function ssPlaceSelected(place) {
  // save for use when calling aes api
  ssSelectedPlaceResult = place;

  // remove red warning on input
  $('.sites-toolbar-input-icon-container').removeClass('missing-required');
  $('#pac-input').removeClass('missing-required');

  checkSiteSearchButtons();
}

function checkSiteSearchButtons() {
  // enable search button if radius and place selected
  if (ssSelectedPlaceResult && ssRadius) {
    //document.querySelector('.sites-toolbar--search-btn').removeAttribute('disabled');
    document.querySelector('.sites-toolbar--search-btn').classList.remove('is-disabled');
  }
  else {
    //document.querySelector('.sites-toolbar--search-btn').setAttribute('disabled', 'true');
    document.querySelector('.sites-toolbar--search-btn').classList.add('is-disabled');
  }
}

function callAESSiteSearchAPI() {
  // user has to select radius and location
  if (!ssRadius || !ssSelectedPlaceResult) {
    // highlight in red which one isnt selected
    if (!ssRadius) {
      $('.sites-toolbar--dropdown-container .tail-select').addClass('missing-required');
    }

    if (!ssSelectedPlaceResult) {
      $('.sites-toolbar-input-icon-container').addClass('missing-required');
      $('#pac-input').addClass('missing-required');
    }

    return;
  }

  // get the data from the user choices
  // radius needs converting to miles if done in km
  let radius = ssRadius;
  if (ssRadiusUnit == 'km') {
    radius = convertMilesToKM(radius);
  }

  const data = {
    radius: radius,
    cid: m_screening.m_state.m_lCallId,
    pid: sessionStorage.getItem("patientID")
  };

  // get the places details
  data.lat = ssSelectedPlaceResult.geometry.location.lat();
  data.long = ssSelectedPlaceResult.geometry.location.lng();
  data.address = ssSelectedPlaceResult.formatted_address;
  data.throttling = m_screening['m_study']['b_throttlingInd'] || false;

  // google maps search doesnt state which part of the information is eg post code or region but it does provide html eg:
  // adr_address: "<span class=\"locality\">Horsham</span>, <span class…044</span>, <span class=\"country-name\">USA</span>"
  // so this can be inserted as an element into js-temporary-element-creation-div and then the value retreived by targeting eg region class element and getting value
  $('.js-temporary-element-creation-div').html(ssSelectedPlaceResult.adr_address);
  data.state = $('.js-temporary-element-creation-div .region').html();
  //data.country = $('.js-temporary-element-creation-div .country').html();
  //data.zip = $('.js-temporary-element-creation-div .postal-code').html();
  data.show_debug = m_screening.b_show_debug;
  data.assign = m_screening.m_study.b_autoAssignSite;

  // set the route origin to the chosen place for when the map loads
  mapRouteOrigin = { lat: data.lat, lng: data.long };

  // add the loader
  const loaderHTML = `<div class="sites-loader">
                      <img src="./../images/loading.gif" alt="Loading animation" class="sites-loader-image"></img>
                    </div>`;
  document.querySelector('#siteCardsContainer').innerHTML = loaderHTML;
  document.querySelector('#mapArea').classList.add('d-none');

  // clear map area
  document.querySelector('#mapArea').innerHTML = '';

  $.ajax({
    url: apiUrl + '/siteSelection/getSitesInRadius',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`
    },
    method: "POST",
    data: data,
    success: function (response) {
      if (response.data && response.data.length) {
        buildNewSitesFromPayload(response.data);
        searchedSitesPayload = response.data; // saves the payload for reloading when coming back to this question on back button pressed
        checkShowSiteCardsNavBtns();
      }
      else {
        noSitesAvailable();
      }
    },
    error: ((error) => {
      console.log(error);
    })
  });

  // set the reset button to active
  document.querySelector('.sites-toolbar--reset-btn').removeAttribute('disabled');
  document.querySelector('.sites-toolbar--reset-btn').classList.remove('is-disabled');
}

function resetSiteSelection() {
  // should have the original fetched aes element from landing on this page before any site searches
  if (lastFetchedSiteSelectionData) {
    searchedSitesPayload = null;
    selectedSiteUniqueCode = null;

    // remove all existing map markers from the variable and add the patients home. Then the new locations will be added in buildSiteCards
    siteSelectionLocations = [];

    document.querySelector('#siteSelectionContainer').parentElement.parentElement.querySelector('.answers-container').remove();
    createAnswers(lastFetchedSiteSelectionData.answerType, lastFetchedSiteSelectionData.elementToAppendTo, lastFetchedSiteSelectionData.questionData, lastFetchedSiteSelectionData.belongsToPII);
  }

  setTranslatedContent();
}


function buildNewSitesFromPayload(data) {
  // remove all existing map markers from the variable and add the patients home. Then the new locations will be added in buildSiteCards
  siteSelectionLocations = [];
  buildSiteCards(data);
  siteSelectionLocations.push({ lat: mapRouteOrigin.lat, lng: mapRouteOrigin.lng, icon: "/images/markerHomeSmall.png" });

  if (data.length) {
    currentSites_m_Choices = data;
    lockNoSiteConvenient = false;
    deselectNoSitesConvenient();
  }
  else {
    currentSites_m_Choices = null;
    lockNoSiteConvenient = true;
    selectNoSitesConvenient();
  }
  initialiseMap();
  setUpSiteSelectionExtras();

  // enable submit button
  $('#submit').removeAttr('disabled');
}

//Functions to create objects for answers
function createDualHeightChoiceList(questionData) {
  const containerObj = document.createElement("div");
  $(containerObj).attr({ class: "height-inputs-container row no-gutters" });
  $("#questions-form").append(`
  <script>
    $(function () {
      $('#toggleDualHeight').on('change', function() {

          const cmInputContainer = $('.height-inputs-container .form-row#cm');
          const feetInputContainer = $('.height-inputs-container .form-row#ftIn');

          cmInputContainer.find('input').val('');
          feetInputContainer.find('input').val('');

          const selectedId = $(this).val();
          if(selectedId === "ftIn"){
            feetInputContainer.find('input#ft').attr('min', 1);
            feetInputContainer.find('input').attr('required', true);
            cmInputContainer.find('input').attr({'required': false, 'min': 0});
          } else {
            feetInputContainer.find('input').attr('required', false);
            feetInputContainer.find('input#ft').attr('min', 0);
            cmInputContainer.find('input').attr({'required': true, 'min': 1});
          }

          validator.element($('.height-inputs-container div#ftIn input')[0]);
          validator.element($('.height-inputs-container div#ftIn input')[1]);
          validator.element($('.height-inputs-container input#cm')[0]);

          cmInputContainer.toggleClass('selected');
          feetInputContainer.toggleClass('selected');

         checkIfAllRequiredQuestionsAreAnswered();
      });
    });
  </script>
  `);

  let dropdownOptions = `<option value='cm' data-translate="height.metric.centimeter.shortform" data-translate-default="cm">cm</option>
    <option value='ftIn' data-translate-combine="height.nonmetric.feet.shortform|height.nonmetric.inches.longform" data-translate-combine-char=" / " data-translate-default="ft / inches">ft / inches</option>`;  //default to metric

  if (translationMap && translationMap['initial_format_metric_ind'] === "N") {
    dropdownOptions = `<option value='ftIn' data-translate-combine="height.nonmetric.feet.shortform|height.nonmetric.inches.longform" data-translate-combine-char=" / " data-translate-default="ft / inches">ft / inches</option>
  <option value='cm' data-translate="height.metric.centimeter.shortform" data-translate-default="cm.">cm</option>`;
  }

  $(containerObj).append(`
  <div class='col-md-4 py-2'>
    <div class='form-row selected h-100'>
    <div class='col'>
    <label for="toggleDualHeight" style="color:transparent;" class="d-block">-</label>
      <select id='toggleDualHeight' class='submissionNull toggleDualHeight'>
        ${dropdownOptions}
      </select>
      </div>
    </div>
  </div>

  <div class='col-md-8 py-2 px-2'>
    <div class='form-row' id='ftIn'>
        <div class='col'>
          <label for='ft' data-translate="height.nonmetric.feet.longform" data-translate-default="feet" class="d-block">feet</label>
          <input type='number' inputmode="numeric" step="1" pattern="[0-9]*" min='1' max='9' id='ft' class='noTelephone form__input' name="ft${answerCount}"/>
        </div>
        <div class='col'>
          <label for='inches' data-translate="height.nonmetric.inches.longform" data-translate-default="inches" class="d-block">inches</label>
          <input type='number' inputmode="numeric" step="1" pattern="[0-9]*" min='0' max='11' id='inches' class='noTelephone form__input' name="inches${answerCount}"/>
        </div>
    </div>

    <div id='cm' class='form-row'>
      <div class='col'>
        <label for='cm' data-translate="height.metric.centimeter.shortform" data-translate-default="cm" class="d-block">cm</label>
        <input type='number' min='1' step="1" max="999" id='cm'name="cm${answerCount}" inputmode="numeric" pattern="[0-9]*"/>
    </div>
  </div>
  `);

  if (!translationMap || !translationMap['initial_format_metric_ind'] || translationMap['initial_format_metric_ind'] === "Y") {
    $(containerObj).find('.form-row#cm').addClass('selected');
    $(containerObj).find('.form-row#cm input').attr('required', true);
  } else {
    $(containerObj).find('.form-row#ftIn').addClass('selected');
    $(containerObj).find('.form-row#ftIn input').attr('required', true);
  }

  return containerObj;
}

function createDualWeightChoiceList(questionData) {
  const containerObj = document.createElement("div");
  $(containerObj).attr({ class: "weight-inputs-container row no-gutters" });
  $("#questions-form").append(`
  <script>
  $(function () {
      $('#toggleDualWeight').on('change', function() {

          const lbsInputContainer = $('.weight-inputs-container .form-row#lbs');
          const kgInputContainer = $('.weight-inputs-container .form-row#kg');

          lbsInputContainer.find('input').val('');
          kgInputContainer.find('input').val('');

          const selectedId = $(this).val();
          if(selectedId === "kg"){
            lbsInputContainer.find('input').attr({'required': false, 'min': 0});
            kgInputContainer.find('input').attr({'required': true, 'min': 1});
          }else{
            lbsInputContainer.find('input').attr({'required': true, 'min': 1});
            kgInputContainer.find('input').attr({'required': false, 'min': 0});
          }

          validator.element($('.weight-inputs-container input#lbsInput'));
          validator.element($('.weight-inputs-container input#kgInput'));

          lbsInputContainer.toggleClass('selected');
          kgInputContainer.toggleClass('selected');

         checkIfAllRequiredQuestionsAreAnswered();
      });
    });
  </script>
  `);

  let dropdownOptions = `<option value='kg' data-translate="weight.metric.kilogram.shortform" data-translate-default="kg">kg</option>
    <option value='lbs' data-translate="weight.nonmetric.pounds.longform" data-translate-default="pounds (lbs)">pounds (lbs)</option>`;

  if (translationMap && translationMap['initial_format_metric_ind'] === "N") {
    dropdownOptions = `<option value='lbs' data-translate="weight.nonmetric.pounds.longform" data-translate-default="pounds (lbs)">pounds (lbs)</option>
  <option value='kg' data-translate="weight.metric.kilogram.shortform" data-translate-default="kg">kg</option>`;
  }

  $(containerObj).append(`
  <div class='col-md-4 py-2'>
    <div class='form-row selected h-100'>
      <div class='col'>
        <label for="toggleDualWeight" style="color:transparent;" class="d-block">-</label>
        <select id='toggleDualWeight' class='submissionNull toggleDualWeight'>
          ${dropdownOptions}
        </select>
      </div>
    </div>
  </div>

  <div class='col-md-8 py-2 px-2'>
    <div class='form-row' id='lbs'>
      <div class='col'>
        <label for='lbsInput' data-translate="weight.nonmetric.pounds.longform" data-translate-default="lbs" class="d-block">lbs</label>
        <input type='number' min='1' max='999' id='lbsInput' inputmode="numeric" step="1" pattern="[0-9]*" required name="lbsinput${answerCount}" class='noTelephone form__input'/>
      </div>
    </div>
    <div class='form-row' id='kg'>
      <div class='col'>
        <label for='kgInput' data-translate="weight.metric.kilogram.shortform" data-translate-default="kg" class="d-block">kg</label>
        <input type='number' min='1' max='999' id='kgInput' inputmode="numeric" step="1" pattern="[0-9]*" required name="kginput${answerCount}" class='noTelephone form__input'/>
      </div>
    </div>
  </div>
  `);

  if (!translationMap || !translationMap['initial_format_metric_ind'] || translationMap['initial_format_metric_ind'] === "Y") {
    $(containerObj).find('.form-row#kg').addClass('selected');
    $(containerObj).find('.form-row#kg input').attr('required', true);
  } else {
    $(containerObj).find('.form-row#lbs').addClass('selected');
    $(containerObj).find('.form-row#lbs input').attr('required', true);
  }
  return containerObj;
}

function createHeightInputField(questionData) {
  const elementCreated = document.createElement("div");
  elementCreated.className = "height-inputs-container row";

  $(elementCreated).append(`
    <div class="col-sm-6">
      <label data-translate="height.nonmetric.feet.longform" data-translate-default="feet"></label>
      <input class="form__input" min="0" max="30" type="number" step="1" required name="feet${answerCount}" inputmode="numeric" pattern="[0-9]*">
    </div>
    <div class="col-sm-6">
      <label data-translate="height.nonmetric.inches.longform" data-translate-default="inches"></label>
      <input class="form__input" min="0" max="11" type="number" step="1" required name="inches${answerCount}" inputmode="numeric" pattern="[0-9]*">
    </div>
  `);
  return elementCreated;
}

function createWeightInputField(questionData) {
  let elementCreated = document.createElement("div");
  elementCreated.className = "weight-inputs-container";
  $(elementCreated).append(
    `<label data-translate="weight.nonmetric.pounds.longform" data-translate-default="pounds (lbs.)"></label>`
  );

  let poundsInputField = document.createElement("input");
  poundsInputField.type = "number";
  poundsInputField.step = "1";
  poundsInputField.pattern = "[0-9]*";
  poundsInputField.setAttribute("inputmode", "numeric");
  poundsInputField.className = "noTelephone form__input";
  poundsInputField.min = "0";
  poundsInputField.max = "1000";
  poundsInputField.required = questionData["m_bRequired"];
  poundsInputField.name = "pounds" + answerCount;

  elementCreated.appendChild(poundsInputField);
  return elementCreated;
}

function isConsentEligible() {
  //return Bool whether we should show consent question or not based on 'availableLocales'
  const availableLocaleIndex = m_screening.m_availableLocales.findIndex(localeObj => localeObj.m_locale === m_screening.m_locale);  //index of correct m_locale object from array of 'm_availableLocales'
  if (availableLocaleIndex >= 0) {
    //locale exists in 'availableLocales'
    if (m_screening.m_availableLocales[availableLocaleIndex].b_hideConsent) {
      return false;
    } else {
      return true;
    }
  }
  return true;  //default to show all questions passed in the payload
}

function createSingleChoiceList(data) {
  let choices = data["m_choiceSource"]["m_choices"];

  let elementCreated = document.createElement("div");
  elementCreated.className = "single-choice-answers-container w-100";

  let listOption;
  let bulletDiv;
  let textDiv;
  let checkBoxContainer;
  let checkBox;

  for (let index = 0; index < choices.length; index++) {
    //Create option container
    listOption = document.createElement("button");
    listOption.type = "button";
    listOption.className = "answer-option-container ripple";
    listOption.id = "answer-option-" + choices[index]["m_sUniqueChoiceCode"];
    listOption.value = choices[index]["m_sUniqueChoiceCode"];

    //Create div for bullet
    bulletDiv = document.createElement("div");
    bulletDiv.className = "answer-option-bullet-container";

    //Create div for text and add answer text
    textDiv = document.createElement("div");
    textDiv.className = "answer-text";

    textDiv.innerHTML = choices[index]["m_sText"] || choices[index]["m_sRawText"];

    //Create container div for checkmark
    checkBoxContainer = document.createElement("div");
    checkBoxContainer.className = "checkbox-container";

    //Create checkmark
    checkBox = document.createElement("div");
    checkBox.className = "checkbox-off";

    //Add checkmark to checkbox container
    checkBoxContainer.appendChild(checkBox);

    //Add all 3 elements to option container
    listOption.appendChild(bulletDiv);
    listOption.appendChild(textDiv);
    listOption.appendChild(checkBoxContainer);

    //Add option element to container for all options
    elementCreated.appendChild(listOption);
    $(elementCreated).append(
      `<div class="debugQuestionInfo">${data.m_sUniqueCode}_${choices[index].m_sUniqueChoiceCode}</div>`
    );
  }

  let requiredAnswerFlag = document.createElement("div");
  requiredAnswerFlag.innerHTML = "<i class='fas fa-exclamation-triangle pr-1'></i> Please select one answer.";
  requiredAnswerFlag.className = "required-answer-flag";
  $(requiredAnswerFlag).hide();

  elementCreated.appendChild(requiredAnswerFlag);

  return elementCreated;
}

function createMultipleChoiceList(data) {
  //reset this variable when creating a new set of multiple choice
  exclusiveChoiceCurrentlySelected = false;

  let choices = data["m_choiceSource"]["m_choices"];

  let elementCreated = document.createElement("div");
  elementCreated.className = "multiple-choice-answers-container";

  let listOption;
  let bulletDiv;
  let textDiv;
  let checkBoxContainer;
  let checkBox;

  for (let index = 0; index < choices.length; index++) {
    //Create option container
    listOption = document.createElement("button");
    listOption.type = "button";
    listOption.className = "answer-option-container ripple";
    listOption.id = "answer-option-" + choices[index]["m_sUniqueChoiceCode"];
    listOption.value = choices[index]["m_sUniqueChoiceCode"];
    listOption.setAttribute("data-exclusive", choices[index]["m_bExclusive"]);

    //Create div for bullet
    bulletDiv = document.createElement("div");
    bulletDiv.className = "answer-option-bullet-container";

    //Create div for text and add answer text
    textDiv = document.createElement("div");
    textDiv.className = "answer-text";
    textDiv.innerHTML = choices[index]["m_sText"] || choices[index]["m_sRawText"];

    //Create container div for checkmark
    checkBoxContainer = document.createElement("div");
    checkBoxContainer.className = "checkbox-container";

    //Create checkmark
    checkBox = document.createElement("div");
    checkBox.className = "checkbox-off";

    //Add checkmark to checkbox container
    checkBoxContainer.appendChild(checkBox);

    //Add all 3 elements to option container
    listOption.appendChild(bulletDiv);
    listOption.appendChild(textDiv);
    listOption.appendChild(checkBoxContainer);

    //Add option element to container for all options
    elementCreated.appendChild(listOption);
    $(elementCreated).append(
      `<div class="debugQuestionInfo">${data.m_sUniqueCode}_${choices[index].m_sUniqueChoiceCode}</div>`
    );
  }

  let requiredAnswerFlag = document.createElement("div");
  requiredAnswerFlag.innerHTML = "<i class='fas fa-exclamation-triangle pr-1'></i> Please select at least one answer.";
  requiredAnswerFlag.className = "required-answer-flag";
  $(requiredAnswerFlag).hide();

  elementCreated.appendChild(requiredAnswerFlag);
  return elementCreated;
}

function createDropdown(data) {
  let choices = data["m_choiceSource"]["m_choices"];
  let elementCreated;
  let dropdownList;

  dropdownList = document.createElement("select");
  dropdownList.className = "dropdown2-menu";
  dropdownList.setAttribute("aria-labelledby", "dropdownMenuButton-" + data["m_sUniqueCode"]);

  let dropdownChoice;
  for (let index = 0; index < choices.length; index++) {
    dropdownChoice = document.createElement("option");
    dropdownChoice.className = "dropdown2-item";
    dropdownChoice.value = choices[index]["m_sUniqueChoiceCode"];
    dropdownChoice.innerHTML = choices[index]["m_sText"] || choices[index]["m_sRawText"];
    dropdownList.appendChild(dropdownChoice);
  }

  elementCreated = document.createElement("div");
  elementCreated.className = "dropdown2";
  elementCreated.appendChild(dropdownList);

  return elementCreated;
}

//Used for Controller as Descision Module is called Multiple times
$("#previous").on("click" , function(){
  backButtonClicked++;
})

$(document).on("click" , ".backToQuestionCta", function(){
  backButtonClicked++;
})

$("#submit").on("click" , function(){
  nextButtonClicked = true;
  if(globalThis.m_screening.m_state.sessionId != null
    && globalThis.m_screening.m_study.screenerType === "CRS"){
    nextButtonClicked = false;
    if(backButtonClicked > 0){
      nextButtonClicked = true;
    }
    backButtonClicked = 0;
  }
})

//Creates json payload with user ansewrs
function createJsonPayload() {
  var allQuestions = document.getElementsByClassName("question-answers-container");

  if(globalThis.m_screening.m_state.sessionId != null
    && globalThis.m_screening.m_study.screenerType === "CRS" &&
    globalThis.m_screen.m_answers[allQuestions[0].id].m_answerbasis === 'STUDY_SWITCH'){
      nextButtonClicked = false;
    }
    
  //Add question ID
  var json = {};
  json.questions = [];

  let isGuardian = false;
  const displayName = (value, pos, guardian) => {
    if (isGuardian && !guardian) return;
    if (guardian) isGuardian = true;

    if ($("#caller-name").html() === 'N/A') {
      $('#caller-name').html('')
    }

    if ($(`#caller-name .${pos}`).length === 1) {
      //update the value
      $(`#caller-name .${pos}`).html(value);
    } else {
      $('#caller-name').append(`<span class='${pos}'>${value}</span>`);
    }

  }

  //Add answers
  for (let i = 0; i < allQuestions.length; i++) {
    json.questions[i] = {};
    json.questions[i].questionUniqueId = allQuestions[i].id;

    if (allQuestions[i].getElementsByClassName("answers-container").length > 0) {
      // Create new answers aray
      json.questions[i].answers = [];

      //Get all answers of type input for this question.
      let allInputs = $(allQuestions[i].getElementsByClassName("answers-container")[0]).find('input:visible, textarea:visible, .invisible-required-input');

      let questionsArray = [];
      let valueToWrite;

      //VALIDATION: Check for m_bRequired in json.questions[i]. If we are inside this if statement, questions has answers elements, so we can check if it's required
      let answerIsRequired = allQuestions[i].getAttribute("data-required") == "true";

      //1
      //Loop through all inputs if any
      for (let i2 = 0; i2 < allInputs.length; i2++) {
        //Skip input field if it is not part of the question answers
        if (allInputs[i2].getAttribute("data-ignore-answer")) {
          continue;
        }

        //Get value of input
        valueToWrite = allInputs[i2].value;

        //Validation
        if (valueToWrite == "" && answerIsRequired) {
          return null;
        }

        // GPAUTO Question
        if (valueToWrite !== "" && valueToWrite !== undefined && allQuestions[i].getAttribute("data-question-basis") == "GPAUTO") {
          valueToWrite = screeningGLatitude + '|||' + screeningGLongitude + '|||' + valueToWrite;
        }

        //If it's type date, need to parse date
        if (allQuestions[i].getAttribute("data-answer-type") == "TEXT_DATE") {
          valueToWrite = formatDateToDatabase(valueToWrite, allQuestions[i].getAttribute("data-answer-type"));
        }

        //If it's type mm/yyyy, need to parse date
        if (allQuestions[i].getAttribute("data-answer-type") === "TEXT_MM_YYYY") {
          // valueToWrite = `${valueToWrite.slice(0, 2)}/${valueToWrite.slice(2, 6)}`
          valueToWrite = formatDateToDatabase(valueToWrite, allQuestions[i].getAttribute("data-answer-type"));
        }

        //If it is email, save to variable
        else if (allQuestions[i].getAttribute("data-answer-type") == "EMAIL") {
          userEmail = valueToWrite;
        }
        else if (allQuestions[i].getAttribute("data-answer-type") == "TEXT_PHONE") {
          userPhone = valueToWrite;
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "CAREGIVER_FIRST_NAME") {
          valueToWrite = valueToWrite.trim();
          if (m_screening.m_type === 'CALLCENTER') displayName(valueToWrite, 'first-name', true);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "CAREGIVER_LAST_NAME") {
          valueToWrite = valueToWrite.trim();
          if (m_screening.m_type === 'CALLCENTER') displayName(valueToWrite, 'last-name', true);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "FIRST_NAME") {
          valueToWrite = valueToWrite.trim();
          if (m_screening.m_type === 'CALLCENTER') displayName(valueToWrite, 'first-name', false);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "LAST_NAME") {
          valueToWrite = valueToWrite.trim();
          if (m_screening.m_type === 'CALLCENTER') displayName(valueToWrite, 'last-name', false);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "ZIP") {
          valueToWrite = valueToWrite.trim();
          sessionStorage.setItem("gpPostalCode", valueToWrite);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "COUNTRY") {
          valueToWrite = valueToWrite.trim();
          sessionStorage.setItem("gpCountry", valueToWrite);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "CITY") {
          valueToWrite = valueToWrite.trim();
          sessionStorage.setItem("gpCity", valueToWrite);
        }

        if (allQuestions[i].getAttribute("data-question-basis") == "STATE") {
          valueToWrite = valueToWrite.trim();
          sessionStorage.setItem("gpState", valueToWrite);
        }

        //if it's phone number, ensure just numeric
        if (allInputs[i2].type == "tel" && !$(allInputs[i2]).hasClass("noTelephone")) {
          json.internationalPatientPhoneNumber = formatTelNumberForApi(
            valueToWrite,
            sessionStorage.getItem("userTelCountryCode")
          );
          valueToWrite = valueToWrite.replace(/[a-zA-Z()_-\s\+]/g, ""); //Remove any char that could come from masking, white space, plus, brackets, underscore, hyphen

          if (window.intlTelInputGlobals.getInstance(allInputs[i2])) {
            //international phone selector
            json['selectedCountryCodeNum'] = window.intlTelInputGlobals.getInstance(allInputs[i2]).getSelectedCountryData().dialCode;    //pass through the selected country code
          }
        }

        //Add value to answers for this question
        questionsArray.push(valueToWrite);
      }

      //2
      //Now check for input of type dropdown
      let allDropdowns = allQuestions[i].getElementsByClassName("answers-container")[0].getElementsByTagName("select");

      //Loop through all dropdowns for this question, if any
      for (let i2 = 0; i2 < allDropdowns.length; i2++) {
        //Set m_bMetric as approriate (checking value)
        if ($(allDropdowns[i2]).hasClass("toggleDualHeight")) {
          json.questions[i]["m_bMetric"] = $(allDropdowns[i2]).val() === "ftIn" ? false : true;
          continue; //Don't save value as an answer
        } else if ($(allDropdowns[i2]).hasClass("toggleDualWeight")) {
          json.questions[i]["m_bMetric"] = $(allDropdowns[i2]).val() === "kg" ? true : false;
          continue; //Don't save value as an answer
        }

        //Validation
        if (allDropdowns[i2].options[allDropdowns[i2].selectedIndex].value == "" && answerIsRequired) {
          return null;
        }
        //Add value to answers for this question
        if (!allDropdowns[i2].hasAttribute('data-ignore-answer')) {
          questionsArray.push(allDropdowns[i2].options[allDropdowns[i2].selectedIndex].value);
        }
      }

      //3
      //Now check for input of type single choice
      let allSingleChoice = allQuestions[i].getElementsByClassName("single-choice-answers-container");
      let hasErrors = false;
      let errorToScrollTo;

      //Loop through all buttons for this question, if any
      $(allSingleChoice).each(function () {
        let val = $(this)[0].text;
        if ((val === undefined || val == "") && answerIsRequired) {
          $(this)
            .find(".required-answer-flag")
            .show();
          hasErrors = true;

          //set the first error found as errorToScrollTo
          if (errorToScrollTo === undefined) {
            errorToScrollTo = $(this).find(".required-answer-flag");
          }
        } else {
          if(val == ''){
            val = undefined;
          }
          questionsArray.push(val);
        }
      });

      if (hasErrors) {
        if (errorToScrollTo !== undefined) {
          scrollToElement(errorToScrollTo);
        }
        return null;
      }

      //4
      //Now check for input of type multiple choice
      hasErrors = false;
      let allMultipleChoice = allQuestions[i].getElementsByClassName("multiple-choice-answers-container");

      //Loop through all buttons for this question, if any
      $(allMultipleChoice).each(function () {
        //Validation
        const val = $(this).data("values");
        if ((val === undefined || val.length == 0) && answerIsRequired) {
          $(this)
            .find(".required-answer-flag")
            .show();
          hasErrors = true;

          //set the first error found as errorToScrollTo
          if (errorToScrollTo === undefined) {
            errorToScrollTo = $(this).find(".required-answer-flag");
          }
        }
        //Add values array to array of answers.This loop would always be a single item in theory. This whole code might need to change as in theory there is only one answers container so loops don't make much sense.
        // questionsArray.push($(allMultipleChoice[i2]).data("values"));

        questionsArray = $(this).data("values");
      });

      if (hasErrors) {
        if (errorToScrollTo !== undefined) {
          scrollToElement(errorToScrollTo);
          return null;
        }
      }

      //5 - Site select
      const siteLocationObj = $('input[name="location"]:checked');
      if (siteLocationObj.length > 0) {
        questionsArray = [];
        questionsArray.push(siteLocationObj.val());
        valueToWrite = ""; //Overwrite value here. Todo: Modify 'createJsonPayload' to know what selectors to use vs iterating through all types of inputs
      }

      //Once all possible type of elements have been checked, push array with answers to question.
      json.questions[i].answers = questionsArray;
    }
  }
  json.nextButtonClicked = nextButtonClicked;
  return json;
}

function initialiseSiteSchedule() {
  let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
  let eventer = window[eventMethod];
  let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
  eventer(messageEvent, siteScheduleMessageAction); //listen for messages from the iframe
  eventer("resize", siteScheduleWindowResize); //listen for if the window gets resized
}

var siteScheduleWindowResize = function () {
  //this is used to ensure that the iframe is the correct size, fitting its content. No scrolling etc
  if ($("iframe#siteSchedule").length) {
    $("iframe#siteSchedule")[0].style.height =
      $("iframe#siteSchedule")[0].contentWindow.document.body.scrollHeight + "px";
  }
};

var siteScheduleMessageAction = function (e) {
  switch (e.data) {
    case "PREVIOUS":
      goToQuestion();
      break;
    case "NEXT":
      submitData();
      break;
    case "SIZE_CHANGE":
      //when new dom content is displayed in the iframe, adjust the parent iframe size accordingly
      siteScheduleWindowResize();
      return;
    case "SCROLL_TOP":
      scrollTo(0, 200);
      return;
    case "ERROR":
      $("iframe#siteSchedule").remove(); //remove the iframe from the DOM
      handleUserErrors(ErrorTypes.SITE_SCHEDULER_GENERIC);
      return;
    case "BOOKED_APPT":
      sessionStorage.setItem('isSiteAppointmentQuestion', 'true');
      window.dataLayer.push(buildCustomEventPayload(m_screen.m_question.m_sUniqueCode, PICKED_APPT_ON_SCHEDULING_QUESTION) );
      return;
    default:
      return; //dont action any other events
  }

  //remove the listener once we're done
  const eventMethod = window.removeEventListener ? "removeEventListener" : "detachEvent";
  window[eventMethod]("message", siteScheduleMessageAction, false); //remove the iframe listener
  window[eventMethod]("resize", siteScheduleWindowResize, false); //remove the listener for window resize

  $("#fixed-bottom-bar").removeClass("d-none"); //allow fixed-bottom-bar to be seen on other questions

  setTimeout(() => {
    //this delay ensures that when the question content is displayed again it shows the next question/loader vs original question content behind the iframe
    $("iframe#siteSchedule").remove(); //remove the iframe from the DOM
    $(".question-header-illustration, #questions").show(); //set all other form children to display
  }, 1000);
};

function initialiseProgressRecoveryEmail() {
  // dont set this up on ppd screeners
  if (sessionStorage.getItem('theme') == 'ppd-eds') {
    return;
  }

  // only allow certain locales
  if (!dropOffLocales.includes(curLocale)) {
    return;
  }

  const endpoint = apiUrl + `/email/schedule/progressRecovery`;
  let previousRevisionPID = localStorage.getItem('previousRevisionPID');
  recoverProgressEmailSent = true;

  if (m_screening && m_screening.m_state) {
    $.ajax({
      url: endpoint,
      async: false,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      method: "POST",
      data: {
        email: `${userEmail}`,
        callId: `${m_screening.m_state.m_lCallId}`,
        tid: `${sessionStorage.getItem("TID")}`,
        patientId: sessionStorage.getItem("patientID"),
        userType: `${sessionStorage.getItem("userType")}`,
        m_locale: m_screening.m_locale,
        previousRevisionPID
      },
      success: () => {
        localStorage.removeItem('previousRevisionPID')
      }
    });
  } else {
    console.log("Failed to initialise progress recovery email")
  }
}

//Helper function, move somewhere else?
function formatDateToDatabase(date, answerType) {
  switch (answerType) {
    case "TEXT_DATE":
      return moment(date, translationMap && translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'] ? translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'].toUpperCase() : 'MM/DD/YYYY').format("MM/DD/YYYY");
      break;
    case "TEXT_MM_YYYY":
      return moment(date, translationMap && translationMap['frontend.ui.mm_yyyy.moment_dateformat'] ? translationMap['frontend.ui.mm_yyyy.moment_dateformat'].toUpperCase() : 'MM/YYYY').format("MM/YYYY");
      break;
    default:
      return moment(date, translationMap && translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'] ? translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'].toUpperCase() : 'MM/DD/YYYY').format("MM/DD/YYYY");
  }
}

function formatDateToLocale(date, answerType) {
  switch (answerType) {
    case "TEXT_DATE":
      return moment(date, 'MM/DD/YYYY').format(translationMap && translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'] ? translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'].toUpperCase() : 'MM/DD/YYYY');  //it will always come from the BE in mm/dd/yyyy
      break;
    case "TEXT_MM_YYYY":
      return moment(date, 'MM/YYYY').format(translationMap && translationMap['frontend.ui.mm_yyyy.moment_dateformat'] ? translationMap['frontend.ui.mm_yyyy.moment_dateformat'].toUpperCase() : 'MM/YYYY');  //it will always come from the BE in mm/yyyy
      break;
    default:
      return moment(date, 'MM/DD/YYYY').format(translationMap && translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'] ? translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat'].toUpperCase() : 'MM/DD/YYYY');  //it will always come from the BE in mm/dd/yyyy
  }
}

/**
 * Uses the moment.js library to format the date from user input
 * to written format, e.g. "1st January 2020"
 */
function formatDateToWritten(date) {
  const dateMoment = moment(date, translationMap['frontend.ui.mm_dd_yyyy.moment_dateformat']);
  let momentLanguage; //override to support java's backwards-compatible old language codes
  let language = m_screening.m_locale.split('_')[0];
  let country = m_screening.m_locale.split('_')[1];
  if (language == 'iw') {
    momentLanguage = 'he';
  } else if (language == 'ji') {
    momentLanguage = 'yi';
  } else if (language == 'in') {
    momentLanguage = 'id';
  } else {
    momentLanguage = language;
  }

  dateMoment.locale(momentLanguage + '-' + country);
  return dateMoment.format("LL");
}

$("#guest-help-button").click(function () {
  $("#guest-help-modal").modal("toggle");
  let tid = sessionStorage.getItem("TID");
  $("#token-id-value").val(tid);
  $("#test-text").val("hello");
});

$("#guest-help-button").click(function () {
  $("#guest-help-modal").modal("toggle");
  let tid = sessionStorage.getItem("TID");
  $("#token-id-value").text(tid);
});

$("#no").click(function () {
  clearSession();
});

$("#yes").click(function () {
  activeSessionNavigate();
});

function showDebugContent(idToShow) {
  let cardHeights = 0;

  // workout where to position the card so that they don't overlap (in their default positions)
  $("#debugArea")
    .find(".card")
    .each(function () {
      let ele = $(this);
      let cardIsHidden = ele.is(":hidden");
      let cardHeight = ele.outerHeight();
      if (!cardIsHidden) {
        cardHeights += cardHeight;
      }
    });

  let cardHeader = $(`#${idToShow}`).find(".card-body");
  let cardBody = $(`#${idToShow}`).find(".card-body");

  // Open the card body when showing the debug window
  if ($(`#${idToShow}`).is(":hidden")) {
    cardHeader.find(".toggleCollapse").removeClass("fa-rotate-180");
    cardBody.collapse("show");
    $(`#${idToShow}`).fadeIn();
    $(`#${idToShow}`).css({ left: "auto", top: cardHeights + "px" });
    cardForeground($(`#${idToShow}`));
  } else {
    $(`#${idToShow}`).fadeOut();
  }
}

function collapseDebugContent(thisButton) {
  thisButton.closest(".card").hide();
}

function populateDebugConsole(jsonPayload) {
  $("#debugArea #studyInfo #studyInfoContent, #debugArea #questionHistoryTable tbody").html(""); //reset console content
  const m_study = jsonPayload.m_screening.m_study;
  let phoneNumOrCampaign;

  if (m_screening['m_phoneNumber']) {
    phoneNumOrCampaign = 'm_phoneNumber';
  }
  else {
    phoneNumOrCampaign = 'm_studyCampaign';
  }

  const m_studyCampaign = jsonPayload.m_screening[phoneNumOrCampaign] ? jsonPayload.m_screening[phoneNumOrCampaign] : jsonPayload.m_screening.m_studyCampaign;

  let studyStatusSetText = "";
  let protocolStatusSetText = "";
  let latitude = '';
  let longitude = '';

  if (jsonPayload.m_screening.m_qualifiedStudiesStatusSet) {
    for (let studyStatusSet in jsonPayload.m_screening.m_qualifiedStudiesStatusSet) {
      const studySetObj = jsonPayload.m_screening.m_qualifiedStudiesStatusSet[studyStatusSet];

      for (let sset in studySetObj) {
        const ssObj = studySetObj[sset];

        studyStatusSetText += `&nbsp;&nbsp;<strong>Status Set ID: ${ssObj.statusSetId}</strong><br>`;
        studyStatusSetText += `&nbsp;&nbsp;<strong>Status Set Member ID: ${ssObj.statusSetMemberId}</strong><br>`;
        studyStatusSetText += `&nbsp;&nbsp;<strong>Status Set Question ID: ${ssObj.qstnrElementId}</strong><br>`;
      }
    }
  }

  if (jsonPayload.m_screening.m_qualifiedProtocolsStatusSet) {
    for (let protocolStatusSet in jsonPayload.m_screening.m_qualifiedProtocolsStatusSet) {
      const protocolStatusSetObj = jsonPayload.m_screening.m_qualifiedProtocolsStatusSet[protocolStatusSet];

      for (let pset in protocolStatusSetObj) {
        const psObj = protocolStatusSetObj[pset];

        protocolStatusSetText += `&nbsp;&nbsp;<strong>Status Set ID: ${psObj.statusSetId}</strong><br>`;
        protocolStatusSetText += `&nbsp;&nbsp;<strong>Status Set Member ID: ${psObj.statusSetMemberId}</strong><br>`;
        protocolStatusSetText += `&nbsp;&nbsp;<strong>Status Set Question ID: ${psObj.qstnrElementId}</strong><br>`;
        protocolStatusSetText += `&nbsp;&nbsp;<strong>Status Set Protocol ID: ${psObj.protocolId}</strong><br>`;
      }
    }
  }

  if (m_screening.m_geocode && m_screening.m_geocode.m_dLatitude) {
    latitude = m_screening.m_geocode.m_dLatitude;
  }

  if (m_screening.m_geocode && m_screening.m_geocode.m_dLongitude) {
    longitude = m_screening.m_geocode.m_dLongitude;
  }

  let outReachProjCode = "";
  if(m_screening.m_state.sessionId != null){
    outReachProjCode = `<strong>Target Project Code (Outreach Project Code):</strong> ${jsonPayload.m_screening.m_state.m_SoutreachProjectCode} (${jsonPayload.m_screening.m_state.m_lOutreachProjectCode})<br/>`;
  }

  let studyInfoContent = `
      <strong>${m_study.m_sProjectCode}</strong> -
      ${m_study.m_sSponsorShortName} (${m_study.m_lStudyId})<br/>
      ${outReachProjCode}
      <strong>SuperScreener:</strong> ${m_study.m_bSuperScreener}<br/>
      <strong>R.V.:</strong> ${m_study.m_bHoldingBin}

      <div class="extra-info">
        <div><strong>Locale:</strong> ${jsonPayload.m_screening.m_locale}</div>
        <div><strong>Locales:</strong> ${m_study.m_locales}</div>
        <div><strong>ThrottingInd:</strong> ${m_study.b_throttlingInd}</div>
        <div><strong>AutoAssignInd:</strong> ${m_study.b_autoAssignSite}</div>
        <div><strong>GlobalInd:</strong> ${m_study.m_bGlobal}</div>
      </div>

      <h3>Campaign Info:</h3>`

  // only add if there is a value
  if (m_studyCampaign.m_sNumber) {
    studyInfoContent += `<div><strong>Phone Number:</strong> ${m_studyCampaign.m_sNumber}</div>`
  }

  studyInfoContent += `
      <div><strong>Outreach Campaign ID:</strong> ${m_studyCampaign.m_sOutreachCampaignId}</div>
      <div><strong>DisplayInd:</strong> ${m_studyCampaign.m_bDisplay}</div>
      <div><strong>R.V.:</strong> ${m_studyCampaign.m_bHoldingBin}</div>
      <div><strong>Test#:</strong> ${m_studyCampaign.m_bTestCampaign}</div>

      <div class="extra-info">
        <h6>Extra Phone Info:</h6>
        <div><strong>RefCompanyCd:</strong> ${m_studyCampaign.m_sUserCompanyCode}</div>
        <div><strong>OutreachChannel:</strong> ${m_studyCampaign.m_sOutreachChannel}</div>
        <div><strong>OutreachSubChannel:</strong> ${m_studyCampaign.m_sOutreachSubChannel}</div>
        <div><strong>OutreachSource:</strong> ${m_studyCampaign.m_sOutreachSource}</div>
        <div><strong>SourceFlowName:</strong> ${m_studyCampaign.m_sReferralServiceCode}</div>
        <div><strong>SourceCd:</strong> ${m_studyCampaign.m_lCombinedMarketingSourceId}</div>
        <div><strong>UserCompCD:</strong> ${m_studyCampaign.m_sUserCompanyCode}</div>
        <div><strong>CompTypeCD:</strong> ${m_studyCampaign.m_sCompanyTypeCode}</div>
      </div>

      <div class="extra-info">
        <div><strong>Brand:</strong> ${m_studyCampaign.m_sBrandDesc} (${m_studyCampaign.m_lBrandId})</div>
        <div><strong>PhoneCat:</strong> ${m_studyCampaign.m_sPhoneGroupCategory}</div>
        <div><strong>isAB?:</strong> ${m_studyCampaign.b_abswitched}</div>
        <div><strong>Flow:</strong> ${m_studyCampaign.m_alternateQuestionFlowDesc} (${m_studyCampaign.m_alternateQuestionFlow})</div>
        <div><strong>Design(not used):</strong> ${m_studyCampaign.m_sTheme} (${m_studyCampaign.m_lDesignGroupId})</div>
        <div><strong>PII Capture:</strong> ${m_studyCampaign.m_sPiiCaptureCd}</div>`

  studyInfoContent += `
        <div><strong>DefaultCCPhone:</strong> ${m_studyCampaign.m_sDefaultCallCenterPhoneNumber}</div>
      </div>

      <h3>Theme Info:</h3>
      <p>
        <strong>b_abswitched:</strong>  ${m_screening.m_studyCampaign.b_abswitched} <br>
        <strong>m_lAbSwitchDetailId:</strong>  ${m_screening.m_studyCampaign.m_Theme ? m_screening.m_studyCampaign.m_Theme.m_lAbSwitchDetailId : "N/A"} <br>
        <strong>m_lAbSwitchGroupId:</strong>  ${m_screening.m_studyCampaign.m_Theme ? m_screening.m_studyCampaign.m_Theme.m_lAbSwitchGroupId : "N/A"} <br>
        <strong>m_lDesignGroupId:</strong>  ${m_screening.m_studyCampaign.m_Theme ? m_screening.m_studyCampaign.m_Theme.m_lDesignGroupId : "N/A"} <br>
        <strong>m_sAbSwitchGroupName:</strong>  ${m_screening.m_studyCampaign.m_Theme ? m_screening.m_studyCampaign.m_Theme.m_sAbSwitchGroupName : "N/A"} <br>
        <strong>m_sInternalTheme:</strong>  ${m_screening.m_studyCampaign.m_Theme ? m_screening.m_studyCampaign.m_Theme.m_sInternalTheme : "N/A"}
        <strong>m_sTheme:</strong>  ${m_screening.m_studyCampaign.m_Theme ? m_screening.m_studyCampaign.m_Theme.m_sTheme : "N/A"}
      </p>

      <h3>ProtoInfo:</h3>
      <div><strong>Active:</strong></br>${m_screening.active_protos}</div>
      <div><strong>Elig:</strong></br>${m_screening.elig_protos}</div>
      <div><strong>Qual:</strong></br>${m_screening.qual_protos}</div>

      <h3>Other:</h3>
      <div><strong>Patient LatLong:</strong> ${latitude}, ${longitude}</div>
      <div><strong>URL Params:</strong> ${m_screening.arr_urlParams}</div>
      <div><strong>GID:</strong> ${m_screening.m_sGid}</div>
      <div><strong>Single Site Rule:</strong> ${m_screening.b_singleSiteRuleApplies}</div>
      <div><strong>Indications:</strong> ${m_screening.m_indications ? JSON.stringify(m_screening.m_indications) : ""}</div>
      <div><strong>Indications Match:</strong> ${m_screening.m_indicationsMatch ? m_screening.m_indicationsMatch : ""}</div>
      <div><strong>Registration Unique ID (Flare):</strong> ${m_screening.m_state.m_sUniqueId ? m_screening.m_state.m_sUniqueId : ""}</div>
      <div><strong>Study Status Set:</strong><br> ${studyStatusSetText}</div>
      <div><strong>Protocol Status Set:</strong><br> ${protocolStatusSetText}</div>

      <h3>ENV</h3>
      <div><strong>ENV:</strong> ${ENV}</div>

      <h3>SERVER</h3>
      <div><strong>SERVER:</strong> ${m_screening.server}</div>
      <div><strong>HOST NAME:</strong> ${m_screening.hostName}</div>
      <div><strong>DB Server:</strong> ${m_screening.dataBaseServer}</div>`;

  $("#debugArea #studyInfo #studyInfoContent").html(studyInfoContent);

  if(jsonPayload.m_screening.m_answers.m_historyOfMaplist.length > 0){
    jsonPayload.m_screening.m_answers.m_historyOfMaplist.forEach((value , index) =>{
      buildDebugConsole(jsonPayload , Object.keys(value) , index);
  })
}

buildDebugConsole(jsonPayload , null , null) ;

  $("#collapsedContent1").html(`
      <div><strong>pid:</strong> ${m_screening.m_state.m_lPatientId}</div>
      <div><strong>cid:</strong> ${m_screening.m_state.m_lCallId}</div>
    `);

  $("#collapsedContent2").html(`
      <div><strong>sz:</strong> ${window.outerWidth}</div>
      <div><strong>dispo:</strong> ${m_screening.m_state.m_iDispoCode}${m_screening.m_state.m_sStatus}</div>
    `);

    if(m_screening.m_state.sessionId !== null){
      $("#collapsedContent3").html(`
      <div><strong>sessid:</strong> ${m_screening.m_state.sessionId}</div>
    `);
    }else{
      $("#collapsedContent3").html("");
    }

  $("#debugArea").fadeIn();
}

$("#recover-progress-no").click(function () {
  getFirstQuestion();
});

$("#recover-progress-yes").click(function () {
  fetchPayload(sessionStorage["TID"], sessionStorage["callId"]);
});

function restartSessionAndQuestionnaire() {
  let urlToLoad = window.location.origin;

  // get previous screener phone number if exists
  if (sessionStorage.getItem('previousUrl')) {
    urlToLoad = window.location.origin + '/welcome.do' + sessionStorage.getItem('previousUrl');
  }

  sessionStorage.clear();
  window.location = urlToLoad;
}

function scrollToElement(element) {
  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
    setTimeout(() => {
      scrollTo($(element).offset().top - 100, 500);
    }, 700);
  } else {
    scrollTo($(element).offset().top - 100, 500);
  }
}

function hideFixedButtonBar(hide) {
  const bar = $("#fixed-bottom-bar");
  if (hide) {
    bar.addClass("d-none"); //if we hide it by adding this class, it does not interfere with the CSS that shows/hides this bar if mobile/desktop
    $("#footer").addClass("footer-nofixedbutton");
  } else {
    bar.removeClass("d-none");
    $("#footer").removeClass("footer-nofixedbutton");
  }
}

function displayContactNumber(payloaddata) {
  let payload = payloaddata["m_screening"]["m_studyCampaign"]
  const display = payload["m_bDisplay"]
  let number = payload["m_sNumber"]
  if(ssmsDisplay == null)
    ssmsDisplay = display
  let studyPayload = payloaddata.m_screening.m_study
  let isHideCallInPhoneNumber = studyPayload['m_bIsHideCallInPhoneNumber']
  if(ssmsHidePhoneNumber == null)
    ssmsHidePhoneNumber = isHideCallInPhoneNumber
  const isssms = payloaddata.m_screening.m_state.sessionId != null
  if(isssms && ssmsDisplay){
    if(!ssmsPhoneNumber)
      ssmsPhoneNumber = number
    else if(ssmsPhoneNumber)
      number = ssmsPhoneNumber
  }

  if(isssms)
    isHideCallInPhoneNumber = ssmsHidePhoneNumber

  let defaultNumber = ''
  if (payload["m_sDefaultCallCenterPhoneNumber"]) {
    defaultNumber = payload["m_sDefaultCallCenterPhoneNumber"]
    ssmsDefaultPhoneNumber = defaultNumber
  }

  if (!isHideCallInPhoneNumber) {
    // Display m_sNumber if m_bDisplay === 'Y', otherwise show
    if ((display && number && !isssms) ||  (isssms && ssmsPhoneNumber)) {
      formatPhoneNumber(number)
    } else {
      if(isssms)
        defaultNumber = ssmsDefaultPhoneNumber
      formatPhoneNumber(defaultNumber)
    }
  }
}

function formatPhoneNumber(number) {
  let phoneNumber = number;

  if(!number)
    return

  // only format the phone number if 10 digits and (either en_US or locale contains _CA)
  if (number.length === 10 && (m_screening.m_locale === "en_US" || m_screening.m_locale.includes('_CA'))) {

    //Only format for US/_CA screener
    try {
      phoneNumber = "(" + number.slice(0, 3) + ") " + number.slice(3, 6) + "-" + number.slice(6);
    } catch (err) {
      console.log(err)
      // phone number must be wrong length
    }
  }

  const country = m_screening.m_locale.split('_')[1]
  if (country !== 'US' && country !== 'CA' && country !== 'GB') {
    number = '+' + number;
  }

  $("#contact-phone-number a")
    .attr("href", `tel:${number}`)
    .html(phoneNumber);

  const phoneNumberSplit = phoneNumber.split(")")

  if (phoneNumberSplit.length === 2) {
    $('.phone-number-dark').html(phoneNumberSplit[0] + ")")
    $('.phone-number-light').html(phoneNumberSplit[1])
  } else {
    $('.phone-number-dark').html(phoneNumberSplit[0])
  }

  if (number) {
    $("#contact-phone-number").removeClass("d-none")
  }

}

function makeThisQuestionAutosubmit(answerTypes, sessionId) {
  if(sessionId)
      return;
  //First if there are any other answer types different to info and radio
  const answerTypesRequireSelection = ["INFO", "RADIO"];
  let radioQuestionsCount = 0;
  for (let i = 0; i < answerTypes.length; i++) {
    if (!answerTypesRequireSelection.includes(answerTypes[i])) {
      return;
    } else {
      if (answerTypes[i] == "RADIO") {
        radioQuestionsCount++;
      }
    }
  }
  //If we have only 1 radio answer, it can autosubmit
  if (radioQuestionsCount === 1) {
    $("#submit").hide();
    hideFixedButtonBar(true);
    $(".single-choice-answers-container .answer-option-container").addClass("autosubmit");
  }
}

function bottomBarIphoneXFix() {
  if (md.mobile() === "iPhone") {
    let aspect = window.screen.width / window.screen.height;
    if (aspect.toFixed(3) === "0.462") {
      $("#fixed-bottom-bar").addClass("fixed-bottom-bar-iphonex");
    } else {
      $("#fixed-bottom-bar").removeClass("fixed-bottom-bar-iphonex");
    }
  }
}

function autofillCityAndStateByPostcode(postcode, basisPrefix = "") {
  const geocoder = new google.maps.Geocoder();
  const country = $(`#questions [data-question-basis='${basisPrefix}COUNTRY'] select`).val();

  if (country === 'PL') {
    return;
  }
  // only call this if US and 5 characters
  if ($('.question-answers-container[data-question-basis="ZIP"] input').length && curLocale === "en_US" && $('.question-answers-container[data-question-basis="ZIP"] input').val().length < 5) {
    return;
  }

  geocoder.geocode({
    address: postcode,
    componentRestrictions: {
      country: country
    }
  }, function (results, status) {
    if (status === "OK" && results.length > 0) {
      let city = "",
        state = "",
        stateLong = "";

      for (let i = 0; i < results[0].address_components.length; i++) {
        const types = results[0].address_components[i].types.join(",");
        if (types === "locality,political" || types === "administrative_area_level_3,political" || types === "postal_town" || (country == 'BR' && types === "administrative_area_level_2,political")) {
          city = results[0].address_components[i].long_name;
        }
        if (types === "administrative_area_level_1,political") {
          state = results[0].address_components[i].short_name;
          stateLong = results[0].address_components[i].long_name;
        }
      }

      //Find elements for city and state
      const cityAnswerElements = $(`#questions [data-question-basis='${basisPrefix}CITY']`);
      const stateAnswerElements = $(`#questions [data-question-basis='${basisPrefix}STATE']`);

      if (cityAnswerElements.length === 1 && city.length > 0) {
        const input = cityAnswerElements.find("input");
        if (input.length === 1) {
          input.val(city);
          input.blur();
        }
      } else if (cityAnswerElements.length === 1 && stateAnswerElements.length === 0 && state) {
        //We dont have a 'city' value to use but have an input. No state input but we have a state value. Use state (edge case)
        const input = cityAnswerElements.find("input");
        if (input.length === 1) {
          input.val(state);
          input.blur();
        }
      }

      if (stateAnswerElements.length === 1) {
        const dropdown = stateAnswerElements.find(`select`); //state select exists
        const freeType = stateAnswerElements.find(`input`); //state free type input exists
        const optionExists = stateAnswerElements.find(`select option[value='${state}']`).length === 1 ? true : false; //state option exists. we want to check this before trying to set its value
        if (dropdown.length === 1 && optionExists) {
          dropdown.val(state);
          dropdown.blur();
        } else if (freeType.length === 1) {
          //state input still exists but it is free type
          freeType.val(stateLong);
          freeType.blur();
        }
      }
      //Now call the following to enable the Next button if necessary
      checkIfAllRequiredQuestionsAreAnswered();
    }
  });
}

function populateDateField(el) {
  if ($(el).val() && $(el).val().length === 8 && formatDateToWritten($(el).val()) !== "Invalid date") {
    $(`#dateTextFor-${el.name}`).text(formatDateToWritten($(el).val()));
    $(`#dateTextFor-${el.name}`).show();
  } else {
    $(`#dateTextFor-${el.name}`).hide();
  }
}

function genericModal({
  title = "",
  body = "",
  yesText = "",
  yesFn = () => { },
  noText = "",
  noFn = () => { },
  ...rest
}) {
  const $modal = $("#generic-modal");
  const display = title ? "show" : "hide";
  const removeClicks = () => {
    $modal.find("#modal-no").off("click", noFn);
    $modal.find("#modal-yes").off("click", yesFn);
  };

  $modal
    .find(".modal-title")
    .html(title)
    .parent()
  [display]();
  $modal.find(".modal-body").html(body);

  $modal
    .find("#modal-yes")
    .html(yesText)
    .on("click", yesFn)
    .on("click", removeClicks);
  $modal
    .find("#modal-no")
    .html(noText)
    .on("click", noFn)
    .on("click", removeClicks);
  return $modal.modal({ ...rest });
}

userTimeout = new AutoTimeout(function () {
  let countdownSeconds = 30; //display alert for 30 seconds
  const $modal = genericModal({
    title: "Continue?",
    body: `You have been inactive for ${Math.round(
      this.delay / 60 / 1000
    )} minutes, do you want to continue?<br /><br />Your session will timeout in <span class='counter'></span> seconds.`,
    yesText: "Yes",
    yesFn: () => {
      clearTimeout(this.autoInactive);
      this.start();
    },
    noText: "No",
    noFn: () => {
      clearInterval(this.autoInactive);
      startNewSession();
    },
    backdrop: "static"
  });
  const $counter = $modal.find(".counter");

  let remaining = countdownSeconds;
  const endSession = () => {
    $counter.text(remaining);
    if (remaining-- < 1) {
      $modal.modal("hide");
      startNewSession();
    } else {
      this.autoInactive = setTimeout(endSession, 1000);
    }
  };
  endSession();
}, 30 * 60 * 1000); //end session in 30 minutes

{
  const reset = () => {
    if (currentPage > 1) userTimeout.restart();
  };

  $(".main-page-container")
    .on("click", () => reset())
    .on("keydown", "input", () => reset());
}


function detectDevice() {
  var b = navigator.userAgent.toLowerCase(),
    a = function (a) {
      if (a) {
        b = (a + "").toLowerCase();
      }
      return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(b) ? "tablet" : /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(b) ? "phone" : "desktop";
    };
  return {
    device: a(),
    detect: a,
    isMobile: "desktop" !== a() ? !0 : !1,
    userAgent: b
  };
}

// included thios here because the unsubscribe.js file isnt included int he main screener and its code runs on dopc ready which we dont want here
// unsubscribes everything for this email regardless of call id - used when finishing screener
function unsubscribeEmailAddress() {
  let email;

  if (sessionStorage.getItem('userEmail')) {
    email = sessionStorage.getItem('userEmail');
  }
  else {
    // loop answers for email
    const ansKeys = Object.keys(m_screening.m_answers.m_map);

    for (const k of ansKeys) {
      const a = m_screening.m_answers.m_map[k];

      if (a.m_answerbasis == 'EMAIL') {
        sessionStorage.setItem('userEmail', a.m_sRawAnswer);
        email = sessionStorage.getItem('userEmail');
        break;
      }
    }
  }

  if (email) {
    $.ajax({
      url: apiUrl + `/email/unsubscribeEmailAddress`,
      method: 'POST',
      data: { email },
      success: (response) => {
        console.log(response)
      },
      error: (err) => {
        $('#screener-container').html(`<p>Something went wrong... <br>We couldn't complete this request, please contact our team at 1-(855)-622-8748.</p>`);
      }
    });
  }
}

// send the project code the use has qualified to the db
function sendQualifiedStudyToAPI() {
  let payload = { call_id: m_screening.m_state.m_lCallId, project_code: null };

  // get project code
  if (m_screening && m_screening.m_state && m_screening.m_state.m_pickedSynexusSiteDetails && m_screening.m_state.m_pickedSynexusSiteDetails.synexusProjectCode) {
    payload.project_code = m_screening.m_state.m_pickedSynexusSiteDetails.synexusProjectCode;

    $.ajax({
      url: apiUrl + '/setQualifiedStudy',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`
      },
      method: "POST",
      data: payload
    });
  }
}

function translateFacilityGroupIDtoName(facilityGroupId) {
  if (facilityGroupId === 1)
    return 'SYNEXUS';
  else if (facilityGroupId === 81)
    return 'INDEPENDENT';
  else if (facilityGroupId === 101)
    return 'PSUEDO_SITE';
  else if (facilityGroupId === 21)
    return 'ACADEMIC_SITE';
  else if (facilityGroupId === 41)
    return 'CLOSED_PERMANENTLY';
  else if (facilityGroupId === 99)
    return 'VIRTUAL_SITE';
  else if (facilityGroupId === 61)
    return 'VA_MEDICAL';
  else
    return 'OTHER';
}

function buildDebugConsole(jsonPayload , projectCode , position){
  let jsonPayloadObj = null;
  let projectCodeIterate = "";
  let isBoolean = false;
  if(projectCode === null){
    projectCodeIterate = jsonPayload.m_screening.m_study.m_sProjectCode;
    if(jsonPayload.m_screening.m_answers.m_historyOfMaplist.length > 1 && projectCodeIterate ===
      jsonPayload.m_screening.m_state.s_projectCodeForCntrl){
        jsonPayloadObj = jsonPayload.m_screening.m_answers.m_flowCopy
        isBoolean = true;
      }else{
        jsonPayloadObj = jsonPayload.m_screening.m_answers.m_map;
      }
  }else{
    jsonPayloadObj = jsonPayload.m_screening.m_answers.m_historyOfMaplist[position][projectCode];
    projectCodeIterate = projectCode;
  }


  for (let ans in jsonPayloadObj) {
    if(isBoolean)
    {
      ans = jsonPayloadObj[ans];
    }
    let ansObj;
    if(projectCode === null){
      ansObj = jsonPayload.m_screening.m_answers.m_map[ans];
    }else{
      ansObj = jsonPayload.m_screening.m_answers.m_historyOfMaplist[position][projectCode][ans]
    }
    const answerText = ansObj.m_sRawAnswer
      ? ansObj.m_sRawAnswer
      : ansObj.m_sChoices
        ? JSON.stringify(ansObj.m_sChoices)
        : "null";

    let formulaValue = "";
    if (ansObj.m_formulaValue) {
      if (ansObj.m_formulaValue.m_dValue) {
        formulaValue = `(formula = ${ansObj.m_formulaValue.m_dValue})`;
      } else {
        formulaValue = JSON.stringify(ansObj.m_formulaValue);
      }
    }


    const answerQuestionText = ansObj.m_sText ? ansObj.m_sText : "";
    let ghostQuestion = false;

    if (!debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate]) {
      ghostQuestion = true; //answer must be a ghost question
      debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate] = { ghost: true, text: answerQuestionText };
    }

    let displayBackLink = jsonPayload.m_screening.m_answers.m_flow.includes(ansObj.m_sQuestionUniqueCode); //Is unique code of this question in m_screening.m_answers.m_flow

    //get any m_protocolsDq data for this question
    let protoSyntax = "";
    if (ansObj.m_state.m_protocolsDq.length > 0) {
      //check that there is some data in the array first
      for (let proto of ansObj.m_state.m_protocolsDq) {
        protoSyntax += `<div class="proto">${proto.m_sProtocolNumber}</div>`; //we only want protocol number coming out on the frontend
      }
    }

    let backLink = "";

    if (debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate]["pageNum"] && projectCode === null) {
      backLink = `goToQuestion(${debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate]["pageNum"]})`;
    } else {
      displayBackLink = false;
    }

    $("#debugArea #questionHistoryTable tbody").prepend(`
        <tr class="${debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate]["ghost"] ? "ghostQuestion" : ""}">
          <td>
            ${ansObj.m_sModuleName ? `${ansObj.m_sModuleName} (${ansObj.m_iStudyModuleId})` : ``}
          </td>
          <td>
          <a class="backToQuestionCta ${!displayBackLink ? "d-none" : ""}" onclick="${backLink}">${ansObj.m_sQuestionUniqueCode
          }(${projectCodeIterate})</a>
                <span class="${displayBackLink ? "d-none" : ""}">${ansObj.m_sQuestionUniqueCode}
                (${ projectCodeIterate})</span>
              </td>
          <td>${ansObj.m_answerType}</td>
          <td>${debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate]["text"]
        ? escapeHtml(debugQuestionContent[ansObj.m_sQuestionUniqueCode+"/"+projectCodeIterate]["text"])
        : ""
      }</td>
          <td>${answerText !== "[]" ? answerText : ""} ${formulaValue}</td>
          <td><div class="protoContainer ${!protoSyntax ? "d-none" : ""}">${protoSyntax}</div></td>
        </tr>
    `);
  }
}
